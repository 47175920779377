import {z} from 'zod'

export const LOGIN_FORM_MODEL = {
    email: {
        name: 'email',
        label: 'login:form:email',
        placeholder: 'login:form:email',
        defaultValue: '',
        helpText: ''
    },
    password: {
        name: 'password',
        label: 'login:form:password',
        placeholder: 'login:form:password',
        defaultValue: '',
        helpText: ''
    }
} as const

export const LoginFormSchema = z.object({
    email: z
        .string()
        .min(1, {message: 'errors:email'})
        .email({
            message: 'errors:email_valid'
        })
        .optional(),
    password: z.string().min(6, {message: 'errors:password'})
})

export type LoginValidationSchema = z.infer<typeof LoginFormSchema>
