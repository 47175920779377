import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const StyledShootListHeader = styled(Flexbox)<{$size: 'sm' | 'md'}>(
    ({theme: {typography, palette, spacing}, $size}) => css`
        height: ${$size == 'sm' ? 64 : 80}px;
        padding: 0 ${spacing * 6}px;
        border-bottom: 1px solid ${palette.neutral['200']};
        & h2 {
            ${$size == 'sm' ? typography.textMd : typography.textXl};
            font-weight: 500;
            color: ${palette.neutral['900']};
        }

        & p {
            ${typography.textSm};
        }
    `
)
