import {useTranslation} from 'react-i18next'
import {Image} from '@/features/photoset/types'
import {formatMetadataValue} from '@/features/photoset/utils'
import {
    StyledGalleryMetadata,
    StyledGalleryMetadataLabel,
    StyledGalleryMetadataValue,
    StyledUnavailableMetadata
} from '@/features/photoset/components/gallery-photoset-modal/gallery-metadata/style'

export const GalleryMetadata = ({imageMetadata}: {imageMetadata: Image['metadata']}) => {
    const {t} = useTranslation()

    if (!imageMetadata) {
        return <StyledUnavailableMetadata>{t('errors:metadata_unavailable')}</StyledUnavailableMetadata>
    }

    return imageMetadata.map((metaElement, index) => (
        <StyledGalleryMetadata gap={4} key={index}>
            <StyledGalleryMetadataLabel>{t(`photoset:metadata:${metaElement.type}`)}</StyledGalleryMetadataLabel>

            <StyledGalleryMetadataValue>
                {formatMetadataValue(metaElement.type, metaElement.value)}
            </StyledGalleryMetadataValue>
        </StyledGalleryMetadata>
    ))
}
