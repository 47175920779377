import {FC} from 'react'
import {Image} from '@/features/photoset/types'
import {ImageCard} from '@/components/commons/image-card/ImageCard'
import {SpaceImagesContainer} from '@/features/photoset/components/space-images-atoms/SpaceImagesAtoms'
import {EditingQaFlagsSelector} from '@/features/photoset/components/editing-qa-flags-selector/EditingQaFlagsSelector'
import {ExpandImageButton} from '@/components/commons/expand-image-button/ExpandImageButton'
import {usePhotosetStore} from '@/features/photoset/store/store'

interface EditingQaFlagsSpaceImagesProps {
    images: Omit<Image, 'space'>[]
    isQaView: boolean
    spaceId: number
    spaceIndex: number
    onExpandImage: (spaceIndex: number, imageIndex: number) => void
}

export const EditingQaFlagsSpaceImages: FC<EditingQaFlagsSpaceImagesProps> = ({
    images,
    spaceId,
    isQaView,
    spaceIndex,
    onExpandImage
}) => {
    const selectedImages = usePhotosetStore(state => state.selectedImages)
    const selectImages = usePhotosetStore(state => state.selectImages)
    const unselectImages = usePhotosetStore(state => state.unselectImages)

    return (
        <SpaceImagesContainer>
            {images.map((image, index) => {
                const isSelected = selectedImages.some(selectedImageId => selectedImageId == image.id)
                return (
                    <ImageCard
                        key={image.id}
                        image={image}
                        thumbnail={image.thumbnails?.xs}
                        hideImageInfo={false}
                        hideCheckbox
                        isSelected={isSelected}
                        onSelect={
                            isQaView ? () => (isSelected ? unselectImages([image.id]) : selectImages([image.id])) : null
                        }
                        endSlot={
                            <>
                                <ExpandImageButton onClick={() => onExpandImage(spaceIndex, index)} />
                                <EditingQaFlagsSelector image={image} photosetSpaceId={spaceId} isQaView={isQaView} />
                            </>
                        }
                    />
                )
            })}
        </SpaceImagesContainer>
    )
}
