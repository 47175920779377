import styled, {css} from 'styled-components'
import {ModalBody} from '@/components/ui/modal-atoms/ModalAtoms'

export const StyledConfirmActionModalBody = styled(ModalBody)(
    ({theme: {spacing, typography, palette}}) => css`
        color: ${palette.neutral['900']};

        & h4 {
            ${typography.textXl};
            font-weight: 500;
        }

        & p {
            ${typography.textSm};
            margin-top: ${spacing * 2}px;
        }
    `
)
