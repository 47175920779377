import styled, {css} from 'styled-components'

export const StyledGalleryModalHeadingGroup = styled.hgroup(
    ({theme: {typography, palette}}) => css`
        & h1 {
            ${typography.textLg};
            color: ${palette.neutral[900]};
            font-weight: 500;
        }

        & h2 {
            ${typography.textSm};
            color: ${palette.neutral[700]};
        }

        & p {
            ${typography.textMd};
            color: ${palette.neutral[900]};
            font-weight: 400;
        }
    `
)
