import {
    Flag,
    Image,
    Metadata,
    PhotosetImagesSearchParams,
    PhotosetSpace,
    PhotosetSpaceFlag,
    PhotosetSpacesChecks,
    Space
} from '@/features/photoset/types.ts'
import {ObjectEntries, formatLocaleDate, isWindows, raise} from '@utilities/helpers.ts'
import {PhotosetStore} from '@/features/photoset/store/store.ts'
import {Dispatch, SetStateAction} from 'react'
import {HotkeysEvent} from 'react-hotkeys-hook/dist/types'
import {QUERY_KEYS, queryClient} from '@/queryClient'
import {ChipProps} from '@/components/commons/chip/Chip'
import {QueryKey} from '@tanstack/react-query'

export const spaceNameToLabel = {
    bathroom: 'Bathrooms',
    bedroom_1: 'Bedroom 1',
    bedroom_2: 'Bedroom 2',
    bedroom_3: 'Bedroom 3',
    bedroom_4: 'Bedroom 4',
    bedroom_5: 'Bedroom 5',
    bedroom_6: 'Bedroom 6',
    bedroom_7: 'Bedroom 7',
    bedroom_8: 'Bedroom 8',
    bedroom_9: 'Bedroom 9',
    bedroom_10: 'Bedroom 10',
    common_spaces: 'Common spaces',
    dining_room: 'Dining room',
    exterior: 'Exterior',
    kitchen: 'Kitchen',
    living_room: 'Living room',
    office_study: 'Office/Study',
    other: 'Other',
    outdoor_space: 'Outdoor space',
    pool_host_tub: 'Pool host tab'
} as const satisfies Record<Space['key_name'], string>

export const spaceNameToShortcut = {
    bathroom: 'alt+1',
    bedroom_1: 'shift+1',
    bedroom_2: 'shift+2',
    bedroom_3: 'shift+3',
    bedroom_4: 'shift+4',
    bedroom_5: 'shift+5',
    bedroom_6: 'shift+6',
    bedroom_7: 'shift+7',
    bedroom_8: 'shift+8',
    bedroom_9: 'shift+9',
    bedroom_10: 'shift+0',
    common_spaces: 'mod+7',
    dining_room: 'mod+3',
    exterior: 'mod+4',
    kitchen: 'mod+1',
    living_room: 'mod+2',
    office_study: 'mod+8',
    other: 'mod+9',
    outdoor_space: 'mod+6',
    pool_host_tub: 'mod+5'
} as const satisfies Record<Space['key_name'], `${'mod' | 'alt' | 'shift'}+${number}`>

export const shortcutToLabelTuple = (shortcut: string) =>
    shortcut
        .replace('mod', isWindows ? 'CTRL' : '⌘')
        .replace('alt', isWindows ? 'ALT' : '⌥')
        .replace('shift', '⇧')
        .split('+')

export const orderByToLabel = {
    shoot_at: 'photoset:order_by_options:shoot_at',
    flags_count: 'photoset:order_by_options:flags_count'
} as const satisfies Record<PhotosetImagesSearchParams['order_by'], string>

export const orderDirectionToLabel = {
    asc: 'photoset:order_direction_options:asc',
    desc: 'photoset:order_direction_options:desc'
} as const satisfies Record<PhotosetImagesSearchParams['order_direction'], string>

export const formatMetadataValue = (metadataType: Metadata['type'], metadataValue: Metadata['value']) => {
    switch (metadataType) {
        case 'file_type': {
            return metadataValue.toUpperCase()
        }
        case 'aperture': {
            return `F/${metadataValue}`
        }
        case 'shoot_at': {
            return formatLocaleDate(metadataValue, 'LL')
        }
        default: {
            return metadataValue
        }
    }
}

export const generateToastAllocationId = (
    type: 'allocation' | 'deallocation',
    photosetSpaceId: number,
    imageIds: number[]
) => `${type}-${photosetSpaceId}-${imageIds.join(', ')}`

export const hotKeysEventToSpace = (hotKeysEvent: HotkeysEvent, photosetSpaces: PhotosetSpace[]) => {
    if (!hotKeysEvent.keys || hotKeysEvent.keys.length == 0) {
        throw new Error('hotKeysEvent.keys does not exists or is empty')
    }

    const keys = hotKeysEvent.keys.join('')
    const shortcut = hotKeysEvent.mod
        ? `mod+${keys}`
        : hotKeysEvent.alt
          ? `alt+${keys}`
          : hotKeysEvent.shift
            ? `shift+${keys}`
            : raise('Unsupported shortcut')

    const [spaceName] =
        ObjectEntries(spaceNameToShortcut).find(([, value]) => value == shortcut) ?? raise('No matched shortcut')
    return (
        photosetSpaces.find(photosetSpace => photosetSpace.space.key_name == spaceName)?.id ?? raise('No matched space')
    )
}

export const handleImagesRangeSelect = ({
    array,
    image,
    index,
    isSelected,
    isShiftPressed,
    lastSelectedImageId,
    selectImages,
    setLastSelectedImageId,
    unselectImages
}: {
    array: Image[]
    image: Image
    index: number
    isSelected: boolean
    isShiftPressed: boolean
    lastSelectedImageId: number | null
    selectImages: PhotosetStore['selectImages']
    setLastSelectedImageId: Dispatch<SetStateAction<number | null>>
    unselectImages: PhotosetStore['unselectImages']
}) => {
    const lastSelectedImageIndex = array.findIndex(arrayItem => arrayItem.id == lastSelectedImageId)

    if (isShiftPressed && lastSelectedImageIndex != null && lastSelectedImageIndex >= 0) {
        const imagesToToggle =
            index > lastSelectedImageIndex
                ? array.slice(lastSelectedImageIndex + 1, index + 1)
                : array.slice(index, lastSelectedImageIndex + 1)

        imagesToToggle.forEach(image => {
            isSelected ? unselectImages([image.id]) : selectImages([image.id])
        })
    } else {
        isSelected ? unselectImages([image.id]) : selectImages([image.id])
    }

    setLastSelectedImageId(image.id)
}

export const overrideImageFlagsToAdd = (
    images: PhotosetSpace['images'],
    flaggedImagesIds: Image['id'][],
    newFlag: Flag
) =>
    images.map(image =>
        Object.assign(
            {},
            flaggedImagesIds.includes(image.id)
                ? {
                      ...image,
                      flags: image.flags?.some(flag => flag.id == newFlag.id)
                          ? image.flags
                          : image.flags?.concat(newFlag) ?? null,
                      image_flags: image.image_flags.concat({
                          flag_id: newFlag.id,
                          image_id: image.id,
                          is_flag_approved: true,
                          is_qa_added: false
                      })
                  }
                : image
        )
    ) ?? []

export const overrideImageFlagsToRemove = (
    images: PhotosetSpace['images'],
    flaggedImagesIds: Image['id'][],
    newFlag: Flag
) =>
    images.map(image =>
        Object.assign(
            {},
            flaggedImagesIds.includes(image.id)
                ? {...image, flags: image.flags?.filter(flag => flag.id != newFlag.id) ?? null}
                : image
        )
    ) ?? []

export const overridePhotosetSpaceFlagToAdd = (
    photosetSpaces: PhotosetSpace[],
    photosetSpaceId: PhotosetSpace['id'],
    newFlag: PhotosetSpaceFlag
) =>
    photosetSpaces.map(photosetSpace =>
        Object.assign(
            {},
            photosetSpace.id == photosetSpaceId
                ? {
                      ...photosetSpace,
                      flag_key_name: newFlag
                  }
                : photosetSpace
        )
    ) ?? []

// Used for photoset space validation in the curation step
export const validatePhotosetSpaces = (
    photosetSpaces: PhotosetSpace[],
    currentChecks: Record<PhotosetSpace['configuration_space_id'], PhotosetSpacesChecks>
) => {
    const spacesChecks: Record<PhotosetSpace['configuration_space_id'], PhotosetSpacesChecks> = {}

    photosetSpaces.forEach((_, index) => {
        const currentSpace = photosetSpaces[index]
        const unusableSpace = currentSpace.images.length == 0
        const isSufficientCoverage = currentSpace.flag_key_name == 'sufficient_coverage'
        const editorMinimumImages = isSufficientCoverage ? 1 : currentSpace.configuration_space.editor_minimum_images

        spacesChecks[index] = {
            ...(currentChecks?.[index] && currentChecks[index]),
            unusableSpace: unusableSpace,
            selectedCounter: currentSpace.images.filter(image => image.is_final_select).length,
            spaceFlagRequired: unusableSpace && !currentSpace.flag_key_name,
            minimumSelection: currentSpace.images.length > 0 ? editorMinimumImages : 0,
            minimumRaws: currentSpace.images.length > 0 ? editorMinimumImages : 0,
            missingFlags: !isSufficientCoverage
                ? currentSpace.images.length > 0
                    ? currentSpace.images.some(image => image.flags?.length == 0 && !image.is_final_select)
                    : false
                : false,
            isSufficientCoverage
        }
    })

    return spacesChecks
}

// Retrieve photoset spaces
export const retrievePhotosetSpacesFromSelectedImages = (
    images: Map<Image['id'], Image>,
    selectedImages: Image['id'][]
) => {
    const photosetSpaceIds = new Set<PhotosetSpace['id']>()

    selectedImages.forEach(imageId => {
        const image = images.get(imageId)
        if (image?.photoset_space_id) {
            photosetSpaceIds.add(image.photoset_space_id)
        }
    })

    return Array.from(photosetSpaceIds)
}

/**
 * Optimistically update the cached data of the get-images query
 * @param operation - The operation to perform ('add' or 'remove')
 * @param updatedFlag - The updated flag value
 * @param imageIdsToUpdate - List of images that will be updated
 * @returns An array of previous images before the update
 */
export const updateCachedImagesFlag = async ({
    operation,
    updatedFlag,
    imageIdsToUpdate
}: {
    operation: 'add' | 'remove'
    updatedFlag: Flag
    imageIdsToUpdate: Image['id'][]
}): Promise<Image[]> => {
    const queryKey = [QUERY_KEYS.IMAGES]

    // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
    await queryClient.cancelQueries({queryKey})

    // Snapshot previous values
    const previousImages = queryClient.getQueryData<Image[]>(queryKey)

    // Update cached data
    queryClient.setQueriesData({queryKey}, (currentImages: PhotosetSpace['images'] | undefined) => {
        if (!currentImages) return currentImages

        // Need to create a deep clone othwerwise the objects within this return
        // would still references to the same objects as those in previousImages
        return operation == 'remove'
            ? overrideImageFlagsToRemove(currentImages, imageIdsToUpdate, updatedFlag)
            : overrideImageFlagsToAdd(currentImages, imageIdsToUpdate, updatedFlag)
    })

    return previousImages ?? []
}

/**
 * Optimistically update the cached data of the photoset-spaces query
 * @param operation - The operation to perform ('add' or 'remove')
 * @param photosetSpaceId - The ID of the photoset space to update
 * @param updatedFlag - The updated flag value
 * @param imageIdsToUpdate - List of images that will be updated
 * @param queryKey - React Query key to invalidate
 * @returns An array of previous photoset spaces before the update
 */
export const updateCachedSpaceImagesFlag = async ({
    operation,
    photosetSpaceIds,
    updatedFlag,
    imageIdsToUpdate,
    queryKey = [QUERY_KEYS.PHOTOSET_SPACES]
}: {
    operation: 'add' | 'remove'
    updatedFlag: Flag
    imageIdsToUpdate: Image['id'][]
    photosetSpaceIds: PhotosetSpace['id'][]
    queryKey?: QueryKey
}): Promise<PhotosetSpace[]> => {
    // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
    await queryClient.cancelQueries({queryKey})

    // Snapshot previous values
    const previousSpaceImages = queryClient.getQueryData<PhotosetSpace[]>(queryKey)

    // Update cached data
    queryClient.setQueriesData({queryKey}, (currentPhotosetSpaces: PhotosetSpace[] | undefined) => {
        if (!currentPhotosetSpaces) return currentPhotosetSpaces

        // Create a Map to avoid duplicates
        const photosetSpaceMap = new Map<PhotosetSpace['id'], PhotosetSpace>()

        // Deep clone the currentPhotosetSpaces and initialize the Map
        currentPhotosetSpaces.forEach(photoset => {
            photosetSpaceMap.set(photoset.id, {...photoset, images: [...photoset.images]})
        })

        // Update the images for the matching photoset spaces
        photosetSpaceIds.forEach(photosetSpaceId => {
            const photoset = photosetSpaceMap.get(photosetSpaceId)
            if (photoset) {
                photoset.images =
                    operation === 'remove'
                        ? overrideImageFlagsToRemove(photoset.images, imageIdsToUpdate, updatedFlag)
                        : overrideImageFlagsToAdd(photoset.images, imageIdsToUpdate, updatedFlag)
            }
        })

        // Convert the Map values back to an array
        return Array.from(photosetSpaceMap.values())
    })

    return previousSpaceImages ?? []
}

/**
 * Optimistically update the QA fields on Photoset spaces images during QA selection
 * @param photosetSpaces
 * @param imageId
 * @param photosetSpaceId
 * @param approvals
 */

export const updateImageSelection = (
    photosetSpaces: PhotosetSpace[],
    imageId: Image['id'],
    photosetSpaceId: PhotosetSpace['id'],
    approvals: {
        curation_approved?: boolean
        need_manual_retouching?: boolean
        vetting_qa_approved?: boolean
        needs_to_review?: boolean
    }
) =>
    photosetSpaces.map(space =>
        Object.assign(
            {},
            space.id == photosetSpaceId
                ? {
                      ...space,
                      images: space.images.map(image =>
                          image.id === imageId
                              ? {
                                    ...image,
                                    ...approvals
                                }
                              : image
                      )
                  }
                : space
        )
    ) ?? []

/**
 * Override the image flags status in the editing qa flags step on removing
 * If the flag was previously added it will be show in red else it will be removed from the array
 */
export const overrideImageFlagsToEditingRemove = ({
    images,
    flaggedImagesIds,
    inputFlag
}: {
    images: PhotosetSpace['images']
    flaggedImagesIds: Image['id'][]
    inputFlag: Flag
}) =>
    images.map(image => {
        const isPreviouslyReviewed =
            !!image.image_flags && image.image_flags.find(image_flag => image_flag.flag_id == inputFlag.id)?.is_qa_added

        if (isPreviouslyReviewed) {
            return Object.assign(
                {},
                flaggedImagesIds.includes(image.id)
                    ? {
                          ...image,
                          flags: image.flags?.filter(flag => flag.id != inputFlag.id) ?? null,
                          image_flags: image.image_flags.filter(image_flag => image_flag.flag_id != inputFlag.id)
                      }
                    : image
            )
        }

        return Object.assign(
            {},
            flaggedImagesIds.includes(image.id)
                ? {
                      ...image,
                      flags: image.flags,
                      image_flags: image.image_flags.map(image_flag =>
                          image_flag.flag_id == inputFlag.id
                              ? {
                                    ...image_flag,
                                    is_flag_approved: false,
                                    is_qa_added: false
                                }
                              : image_flag
                      )
                  }
                : image
        )
    })

/**
 * Override the image flags status in the editing qa flags step on adding
 * If the flag was previously added it will be show as neutral else it will be show in green
 */
export const overrideImageFlagsToEditingAdd = ({
    images,
    flaggedImagesIds,
    inputFlag
}: {
    images: PhotosetSpace['images']
    flaggedImagesIds: Image['id'][]
    inputFlag: Flag
}) =>
    images.map(image => {
        const imageFlag = image.image_flags && image.image_flags.find(image_flag => image_flag.flag_id == inputFlag.id)
        const isPreviouslyReviewed =
            !!imageFlag && imageFlag.is_qa_added == false && imageFlag.is_flag_approved == false

        // Refresh the chip from danger (red) to neutral color
        if (isPreviouslyReviewed) {
            return Object.assign(
                {},
                flaggedImagesIds.includes(image.id)
                    ? {
                          ...image,
                          image_flags: image.image_flags.map(image_flag =>
                              image_flag.flag_id == inputFlag.id
                                  ? {
                                        ...image_flag,
                                        is_flag_approved: true,
                                        is_qa_added: false
                                    }
                                  : image_flag
                          )
                      }
                    : image
            )
        }

        // If the user tries to add flags in bulk mode, we need to escape to prevent adding duplicate neutral flags
        if (image.flags.some(flag => flag.id == inputFlag.id)) return image

        // Add success chip (green)
        return Object.assign(
            {},
            flaggedImagesIds.includes(image.id)
                ? {
                      ...image,
                      flags: image.flags.concat(inputFlag) ?? null,
                      image_flags: image.image_flags.concat({
                          flag_id: inputFlag.id,
                          image_id: image.id,
                          is_flag_approved: true,
                          is_qa_added: true
                      })
                  }
                : image
        )
    })

/**
 * Optimistically update the cached data of the photoset-spaces query
 * @param operation - The operation to perform ('add' or 'remove')
 * @param photosetSpaceId - The ID of the photoset space to update
 * @param updatedFlag - The updated flag value
 * @param imageIdsToUpdate - List of images that will be updated
 * @returns An array of previous photoset spaces before the update
 */
export const updateCachedEditingQaFlags = async ({
    operation,
    photosetSpaceIds,
    updatedFlag,
    imageIdsToUpdate
}: {
    operation: 'add' | 'remove'
    updatedFlag: Flag
    imageIdsToUpdate: Image['id'][]
    photosetSpaceIds: PhotosetSpace['id'][]
}): Promise<PhotosetSpace[]> => {
    const queryKey = [QUERY_KEYS.PHOTOSET_SPACES]

    // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
    await queryClient.cancelQueries({queryKey})

    // Snapshot previous values
    const previousSpaceImages = queryClient.getQueryData<PhotosetSpace[]>(queryKey)

    // Update cached data
    queryClient.setQueriesData({queryKey}, (currentPhotosetSpaces: PhotosetSpace[] | undefined) => {
        if (!currentPhotosetSpaces || !photosetSpaceIds) return currentPhotosetSpaces

        // Create a Map to avoid duplicates
        const photosetSpaceMap = new Map<PhotosetSpace['id'], PhotosetSpace>()

        // Deep clone the currentPhotosetSpaces and initialize the Map
        currentPhotosetSpaces.forEach(photoset => {
            photosetSpaceMap.set(photoset.id, {...photoset, images: [...photoset.images]})
        })

        // Update the images for the matching photoset spaces
        photosetSpaceIds.forEach(photosetSpaceId => {
            const photoset = photosetSpaceMap.get(photosetSpaceId)
            if (photoset) {
                photoset.images =
                    operation == 'remove'
                        ? overrideImageFlagsToEditingRemove({
                              images: photoset.images,
                              flaggedImagesIds: imageIdsToUpdate,
                              inputFlag: updatedFlag
                          })
                        : overrideImageFlagsToEditingAdd({
                              images: photoset.images,
                              flaggedImagesIds: imageIdsToUpdate,
                              inputFlag: updatedFlag
                          })
            }
        })

        // Convert the Map values back to an array
        return Array.from(photosetSpaceMap.values())
    })

    return previousSpaceImages ?? []
}

/**
 * Get the flag color by checking two parameters:
 * - is_qa_added: if is true, it means that the flag was added in the editing-qa step
 * - is_flag_approved: this param handles the color of the chip
 * */
export const getFlagVariant = (flagId: Flag['id'], image_flags: Image['image_flags']): ChipProps['variant'] | null => {
    const imageFlag = image_flags?.find(image_flag => image_flag.flag_id == flagId)

    if (!imageFlag) return null

    const {is_qa_added, is_flag_approved} = imageFlag

    if (is_flag_approved) {
        return is_qa_added ? 'success' : 'neutral'
    }

    return 'danger'
}

export const qaStepsFlow = [
    'editing-qa-selection',
    'editing-qa-flags',
    'review-completed',
    'vetting-qa',
    'retouching-qa',
    'retouching-qa-completed'
]
