import {PhotosetFinal} from '@/features/photoset/components/photoset-final/PhotosetFinal'
import {Trans, useTranslation} from 'react-i18next'

export const PhotosetRetouchingQaCompletedStep = () => {
    const {t} = useTranslation()

    return (
        <PhotosetFinal
            title={<Trans i18nKey={t('photoset:retouching_qa_completed:title')} components={{br: <br />}} />}
            subtitle={t('photoset:retouching_qa_completed:subtitle')}
            status="success"
        />
    )
}
