import {GalleryModal} from '@/components/commons/gallery-modal/GalleryModal'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import styled, {css} from 'styled-components'

export const StyledGalleryModal = styled(GalleryModal)(
    ({theme: {palette}}) => css`
        background-color: ${palette.neutral.white};
    `
)

export const StyledGalleryCurationFlags = styled(Flexbox)(
    ({theme: {typography, palette, spacing}}) => css`
        width: 100%;
        & h3 {
            ${typography.textLg};
            font-weight: 500;
            color: ${palette.neutral['900']};
            padding: ${spacing * 5}px ${spacing * 6}px;
        }
    `
)

export const StyledGalleryCurationFlagsTitle = styled(Flexbox)`
    width: 100%;
`

export const StyledGalleryCurationFlagsContent = styled(Flexbox)(
    ({theme: {spacing}}) => css`
        padding: 0 ${spacing * 6}px ${spacing * 7.5}px ${spacing * 6}px;
        flex-wrap: wrap;
        width: 100%;
    `
)
