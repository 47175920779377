import toast, {Toast, ToastBar as RHTToastBar} from 'react-hot-toast'
import {Button} from '@components/ui/button/Button.tsx'
import {XCloseIcon} from '@components/ui/icon/Icon.tsx'
import {toastBarStyle} from '@components/ui/toast-bar/style.ts'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const ToastBar = ({t}: {t: Toast}) => {
    return (
        <RHTToastBar toast={t} style={toastBarStyle}>
            {({icon, message}) => (
                <>
                    <Flexbox justify="center" align="center" gap={1}>
                        {icon}
                        {message}
                    </Flexbox>
                    {t.type !== 'loading' && (
                        <Button variant="ghost" size="xs" iconOnly onClick={() => toast.dismiss(t.id)}>
                            <XCloseIcon />
                        </Button>
                    )}
                </>
            )}
        </RHTToastBar>
    )
}

ToastBar.displayName = 'Toast'
