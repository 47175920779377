import {PhotosetFinal} from '@/features/photoset/components/photoset-final/PhotosetFinal'
import {qaStepIdToStepSlug} from '@/features/photoset/hooks/useRedirectQaPhotoset'
import {useGetPhotoset} from '@/features/photoset/services/useGetPhotoset'
import {Photoset, PhotosetURLParams} from '@/features/photoset/types'
import {useMemo} from 'react'
import {Trans, useTranslation} from 'react-i18next'
import {useOutletContext} from 'react-router-dom'
import {StyledPhotosetUnavailable2LaLink} from './style'

const stepKeyToLabel = {
    5: 'photoset:editingQaSelection:title',
    6: 'photoset:editingQaFlags:title',
    14: 'photoset:vetting_qa:title',
    11: 'photoset:retouching_qa:title'
} as const satisfies Partial<Record<NonNullable<Photoset['qa_step_id']>, string>>

export const PhotosetUnavailable2La = () => {
    const {t} = useTranslation()
    const {urlParams} = useOutletContext<{urlParams: PhotosetURLParams}>()
    const {data: photoset, isFetching} = useGetPhotoset(urlParams, {enabled: false})

    const currentQaStep = useMemo(
        () =>
            photoset?.qa_step_id
                ? {
                      slug: qaStepIdToStepSlug?.[photoset.qa_step_id as keyof typeof qaStepIdToStepSlug],
                      label: stepKeyToLabel?.[photoset.qa_step_id as keyof typeof stepKeyToLabel]
                  }
                : null,
        [photoset?.qa_step_id, urlParams]
    )

    return (
        <PhotosetFinal
            status="error"
            title={t('photoset:unavailable_2la:title')}
            isLoading={isFetching}
            subtitle={
                currentQaStep && photoset?.id ? (
                    <Trans
                        i18nKey="photoset:unavailable_2la:subtitle"
                        components={{
                            a: (
                                <StyledPhotosetUnavailable2LaLink
                                    to={`/photoset/${photoset.id}/2la/${currentQaStep.slug}`}
                                />
                            )
                        }}
                        values={{label: t(currentQaStep.label)}}
                    />
                ) : (
                    t('commons:unknown')
                )
            }
        />
    )
}
