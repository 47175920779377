import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import styled, {css} from 'styled-components'

export const StyledImageBadge = styled(Flexbox)(
    ({theme: {palette, typography}}) => css`
        flex-shrink: 0;

        & svg {
            fill: ${palette.neutral.white};
        }
        & > span {
            width: 18px;
            height: 18px;
            border-radius: 16px;
            align-items: center;
            display: flex;
            justify-content: center;
            background: ${palette.neutral['700']};
        }
        & > p {
            ${typography.textXs};
            font-weight: 500;
            color: ${palette.neutral.black};
        }
    `
)
