import {StyledIconContainer} from '@components/ui/icon-container/style.ts'
import {FC, ReactNode} from 'react'

interface IconContainerProps {
    children: ReactNode
    size?: 'md' | 'lg'
    type?: 'neutral' | 'danger' | 'success' | 'warning' | 'blue'
}
export const IconContainer: FC<IconContainerProps> = ({children, size = 'md', type = 'neutral'}) => {
    return (
        <StyledIconContainer size={size} type={type}>
            {children}
        </StyledIconContainer>
    )
}

IconContainer.displayName = 'IconContainer'
