import {useEffect, useMemo} from 'react'
import {Photoset} from '@/features/photoset/types'
import {generatePath, useLocation, useNavigate} from 'react-router-dom'
import {routes} from '@/utilities/constants/routes'

// Map of step IDs to corresponding step slugs. This ensures a constant mapping between photoset steps and their string representation.
const stepIdToStepSlug = {
    1: 'rejected',
    2: 'initial-qa',
    3: 'allocation',
    4: 'curation',
    8: 'ai-vetting',
    9: 'ai-manual-retouching',
    10: 'ai-vetting-completed',
    13: 'needs-review',
    15: 'ai-manual-retouching-upload',
    16: 'retouching-revision-upload',
    17: 'retouching-revision',
    18: 'retouching-revision-approved'
} as const satisfies Partial<Record<Photoset['step_id'], string>>

// Custom hook that redirects the user to the appropriate page based on the photoset's current step
export const useRedirectEditingPhotoset = ({
    photosetId,
    stepId
}: {
    photosetId?: Photoset['id']
    stepId?: Photoset['step_id']
}) => {
    const navigate = useNavigate()
    const location = useLocation()
    const currentStepSlug = stepId ? stepIdToStepSlug[stepId as keyof typeof stepIdToStepSlug] ?? null : null
    const currentUrlStep = location.pathname.split('/').at(-1)

    // Mapping each step slug to its corresponding route in the application
    const stepKeyToRoute = useMemo(
        () => ({
            rejected: routes.PHOTOSET_REJECTED.path,
            'initial-qa': routes.PHOTOSET_INITIAL_QA.path,
            allocation: routes.PHOTOSET_ALLOCATION.path,
            curation: routes.PHOTOSET_CURATION.path,
            'ai-vetting': routes.PHOTOSET_AI_VETTING.path,
            'ai-manual-retouching-upload': routes.PHOTOSET_AI_MANUAL_RETOUCHING_UPLOAD.path,
            'ai-manual-retouching': routes.PHOTOSET_AI_MANUAL_RETOUCHING.path,
            'ai-vetting-completed': routes.PHOTOSET_AI_VETTING_COMPLETED.path,
            'needs-review': routes.PHOTOSET_NEEDS_REVIEW.path,
            'retouching-revision-upload': routes.PHOTOSET_RETOUCHING_REVISION_UPLOAD.path,
            'retouching-revision': routes.PHOTOSET_RETOUCHING_REVISION.path,
            'retouching-revision-approved': routes.PHOTOSET_RETOUCHING_REVISION_APPROVED.path
        }),
        []
    )

    useEffect(() => {
        if (!photosetId || !currentStepSlug || !currentUrlStep) return
        navigate(generatePath(stepKeyToRoute[currentStepSlug], {id: photosetId}), {replace: true})
    }, [photosetId, currentStepSlug])
}
