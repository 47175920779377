import React from 'react'
import {Button} from '@components/ui/button/Button.tsx'
import {Container} from '@components/ui/container/Container.tsx'
import {StyledLoginBox, StyledLoginBoxForm, StyledLoginWrapper} from '@pages/login/style.ts'
import {useTranslation} from 'react-i18next'
import {Helmet} from 'react-helmet'
import {SubmitHandler, useForm} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import {LOGIN_FORM_MODEL, LoginFormSchema, LoginValidationSchema} from '@pages/login/LoginFormModel.ts'
import {InputText} from '@components/commons/input-text/InputText.tsx'
import {AirbnbIcon} from '@components/ui/icon/Icon.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {useLogin} from '@/features/auth/services/queries/useLogin.ts'
import {useAuthStore} from '@/features/auth/store/store.ts'
import {ButtonLink} from '@components/ui/button-link/ButtonLink.tsx'
import {routes} from '@utilities/constants/routes.tsx'
import {errorHandler} from '@utilities/helpers.ts'
import {Spinner} from '@components/ui/spinner/Spinner.tsx'

export const Login: React.FC = () => {
    const {t} = useTranslation()
    const setAuthData = useAuthStore(state => state.setAuthData)
    const {
        register,
        handleSubmit,
        formState: {errors, touchedFields}
    } = useForm<LoginValidationSchema>({
        mode: 'onBlur',
        resolver: zodResolver(LoginFormSchema)
    })

    //login mutation
    const {mutate: login, isPending} = useLogin({
        onError: e => errorHandler(e),
        onSuccess: data => {
            setAuthData(data)
        }
    })

    const onSubmit: SubmitHandler<LoginValidationSchema> = data => {
        login(data)
    }

    return (
        <Container>
            <Helmet title={t('login:page_title_meta')} />
            <StyledLoginWrapper align={'center'} justify={'center'}>
                <StyledLoginBox direction={'column'} align={'stretch'}>
                    <Flexbox justify={'center'}>
                        <AirbnbIcon size={40} fill={'#E31B54'} />
                    </Flexbox>
                    <h4>{t('login:title')}</h4>
                    <p>{t('login:subtitle')}</p>
                    <StyledLoginBoxForm onSubmit={handleSubmit(onSubmit)}>
                        <Flexbox direction={'column'} gap={4}>
                            <InputText
                                label={t(LOGIN_FORM_MODEL.email.label)}
                                type={'email'}
                                placeholder={t(LOGIN_FORM_MODEL.email.placeholder)}
                                errorMessage={t(errors.email?.message || '')}
                                {...register(t(LOGIN_FORM_MODEL.email.name))}
                            />
                            <InputText
                                label={t(LOGIN_FORM_MODEL.password.label)}
                                type={'password'}
                                visibilityToggle
                                touched={touchedFields.password}
                                errorMessage={t(errors.password?.message || '')}
                                placeholder={t(LOGIN_FORM_MODEL.password.placeholder)}
                                {...register(t(LOGIN_FORM_MODEL.password.name))}
                                helpText={t(LOGIN_FORM_MODEL.password.helpText)}
                            />
                        </Flexbox>

                        <Flexbox direction={'column'} align={'end'}>
                            <ButtonLink to={routes.RESET_PASSWORD.path} variant={'tertiary'} size={'sm'}>
                                {t('login:secondary_cta')}
                            </ButtonLink>
                        </Flexbox>
                        <Flexbox direction={'column'} gap={2} align={'stretch'}>
                            <Button variant={'primary'} size={'md'} type={'submit'} disabled={isPending}>
                                {t('login:primary_cta')}
                                {isPending && <Spinner size={16} />}
                            </Button>
                        </Flexbox>
                    </StyledLoginBoxForm>
                </StyledLoginBox>
            </StyledLoginWrapper>
        </Container>
    )
}

Login.displayName = 'Login'
