import {useMutation, UseMutationOptions} from '@tanstack/react-query'
import {httpAllocateImages, HttpAllocateImagesOptions} from '@/features/photoset/services/photoset.http.ts'
import {MUTATION_KEYS} from '@/queryClient.ts'
import {AxiosError} from 'axios'

export const useReallocateImages = (
    options?: Omit<UseMutationOptions<unknown, AxiosError, HttpAllocateImagesOptions>, 'mutationFn' | 'mutationKey'>
) =>
    useMutation({
        ...options,
        mutationKey: [MUTATION_KEYS.ALLOCATE_IMAGES],
        mutationFn: httpAllocateImages
    })
