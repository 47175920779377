import styled, {css} from 'styled-components'
import {Container} from '@components/ui/container/Container.tsx'

export const StyledPageFooter = styled.footer(
    ({theme: {palette}}) => css`
        height: 74px;
        background: ${palette.neutral.white};
        border-top: 1px solid ${palette.neutral['200']};
    `
)

export const StyledPageFooterBody = styled(Container)(
    ({theme: {palette, typography}}) => css`
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        align-items: center;
        ${typography.textSm};
        color: ${palette.neutral['700']};

        & > *:first-child {
            justify-self: flex-start;
        }
        & > *:nth-child(2) {
            justify-self: center;
        }
        & > *:last-child {
            justify-self: flex-end;
        }
    `
)
