import {ImageCard} from '@/components/commons/image-card/ImageCard'
import {SpaceImagesContainer} from '../space-images-atoms/SpaceImagesAtoms'
import {PhotosetSpace} from '@/features/photoset/types'
import {Image01Icon, Stars01Icon} from '@/components/ui/icon/Icon'
import {Chip} from '@/components/commons/chip/Chip'
import {useTranslation} from 'react-i18next'

export const AiManualRetouchingSpaceImages = ({
    images,
    isLoadingManualRetouchingSpaces
}: {
    images: PhotosetSpace['images']
    isLoadingManualRetouchingSpaces: boolean
}) => {
    const {t} = useTranslation()

    return (
        <SpaceImagesContainer $gap={4}>
            {images.map(image => {
                const thumbnails = image.retouched_thumbnails?.xs || image.ai_retouched_thumbnails?.xs

                return (
                    <ImageCard
                        key={image.id}
                        image={image}
                        isSelected={image.need_manual_retouching}
                        hideCheckbox={true}
                        isLoading={false}
                        loadingText={t('commons:generating_thumbnails')}
                        thumbnail={thumbnails}
                        imageNameIcon={
                            !image.is_manually_retouched ? <Stars01Icon size={20} /> : <Image01Icon size={20} />
                        }
                        showBadge={!!image.retouched_thumbnails?.xs}
                        badgeType="selection"
                        endSlot={
                            !isLoadingManualRetouchingSpaces && (
                                <>
                                    {image.is_manually_retouched && (
                                        <Chip
                                            label={t(`commons:manually_retouched`)}
                                            isActive={true}
                                            variant={'info'}
                                        />
                                    )}

                                    {image.need_manual_retouching && !image.is_manually_retouched && (
                                        <Chip
                                            label={t(`photoset:ai_manual_retouching:not_retouched`)}
                                            isActive={true}
                                            variant={'danger'}
                                        />
                                    )}
                                </>
                            )
                        }
                    />
                )
            })}
        </SpaceImagesContainer>
    )
}
