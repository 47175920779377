import styled, {css} from 'styled-components'

export const StyledImagesList = styled.div(
    ({theme: {mediaQueries}}) => css`
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        grid-auto-rows: max-content;
        height: 100%;
        overflow-y: auto;

        ${mediaQueries.xl} {
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }

        ${mediaQueries.l} {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
    `
)
