import styled from 'styled-components'

export const StyledPrivateLayout = styled.div`
    height: 100%;
    overflow: hidden;
`

export const StyledMain = styled.div`
    overflow-y: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
`
