import {useMutation, UseMutationOptions} from '@tanstack/react-query'
import {httpUpdateStepToAiVetting} from '@/features/photoset/services/photoset.http.ts'
import {MUTATION_KEYS} from '@/queryClient.ts'
import {AxiosError} from 'axios'

export const useUpdateStepToAiVetting = (
    options?: Omit<UseMutationOptions<unknown, AxiosError, number>, 'mutationFn' | 'mutationKey'>
) =>
    useMutation({
        ...options,
        mutationKey: [MUTATION_KEYS.UPDATE_STEP_AI_VETTING],
        mutationFn: httpUpdateStepToAiVetting
    })
