import {ReactNode} from 'react'
import {StyledImagesList} from './style'

interface ImagesListProps {
    children: ReactNode
}

export const ImagesList = ({children}: ImagesListProps) => <StyledImagesList>{children}</StyledImagesList>

ImagesList.displayName = 'ImagesList'
