import {useTranslation} from 'react-i18next'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {orderByToLabel, orderDirectionToLabel} from '@/features/photoset/utils'
import {PhotosetImagesSearchParams} from '@/features/photoset/types'
import {
    StyledImagesSortControls,
    StyledSelectPermanentLabel,
    StyledSelectTriggerButton
} from '@/features/photoset/components/images-sort-controls/style'
import {CheckIcon, ChevronDownIcon} from '@/components/ui/icon/Icon'
import {useTheme} from 'styled-components'
import {useParsedSearchParams} from '@/hooks/useParsedSearchParams'
import {ObjectKeys} from '@/utilities/helpers'
import {Select, SelectProvider} from '@ariakit/react'
import {SelectPopover, SelectItem} from '@/components/ui/select-atoms/SelectAtoms'
import {FC} from 'react'

export const ImagesSortControls: FC<{isReadonlyOrderBy?: boolean}> = ({isReadonlyOrderBy = false}) => {
    const {t} = useTranslation()
    const {searchParams, setSearchParams} = useParsedSearchParams([], PhotosetImagesSearchParams)
    const theme = useTheme()

    return (
        <StyledImagesSortControls>
            <SelectProvider
                value={searchParams.order_by}
                setValue={(value: PhotosetImagesSearchParams['order_by']) => setSearchParams({order_by: value})}
            >
                {isReadonlyOrderBy ? (
                    <StyledSelectTriggerButton
                        size="sm"
                        variant="ghost"
                        $minWidth={135}
                        $isPointer={!isReadonlyOrderBy}
                    >
                        <Flexbox gap={2}>
                            <StyledSelectPermanentLabel>{t('commons:order_by')}</StyledSelectPermanentLabel>
                            {t(`photoset:order_by_options:${searchParams.order_by}`)}
                        </Flexbox>
                    </StyledSelectTriggerButton>
                ) : (
                    <Select
                        render={
                            <StyledSelectTriggerButton
                                size="sm"
                                variant="ghost"
                                $minWidth={135}
                                $isPointer={!isReadonlyOrderBy}
                            >
                                <Flexbox gap={2}>
                                    <StyledSelectPermanentLabel>{t('commons:order_by')}</StyledSelectPermanentLabel>
                                    {t(`photoset:order_by_options:${searchParams.order_by}`)}
                                </Flexbox>
                                <ChevronDownIcon fill={theme.palette.neutral['500']} />
                            </StyledSelectTriggerButton>
                        }
                    />
                )}

                <SelectPopover gutter={4} sameWidth>
                    {ObjectKeys(orderByToLabel).map(option => (
                        <SelectItem key={option} value={option}>
                            {t(orderByToLabel[option])}

                            {option == searchParams.order_by && <CheckIcon size={20} />}
                        </SelectItem>
                    ))}
                </SelectPopover>
            </SelectProvider>

            <SelectProvider
                value={searchParams.order_direction}
                setValue={(value: PhotosetImagesSearchParams['order_direction']) =>
                    setSearchParams({order_direction: value})
                }
            >
                <Select
                    render={
                        <StyledSelectTriggerButton size="sm" variant="ghost" $minWidth={135}>
                            {t(`photoset:order_direction_options:${searchParams.order_direction}`)}
                            <ChevronDownIcon fill={theme.palette.neutral['500']} />
                        </StyledSelectTriggerButton>
                    }
                />

                <SelectPopover gutter={4} sameWidth>
                    {ObjectKeys(orderDirectionToLabel).map(option => (
                        <SelectItem key={option} value={option}>
                            <span>{t(orderDirectionToLabel[option])}</span>

                            {option == searchParams.order_direction && <CheckIcon size={20} />}
                        </SelectItem>
                    ))}
                </SelectPopover>
            </SelectProvider>
        </StyledImagesSortControls>
    )
}

ImagesSortControls.displayName = 'ImagesSortControls'
