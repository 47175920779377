import {CheckIcon, RefreshCcw01Icon} from '@/components/ui/icon/Icon'
import {useTranslation} from 'react-i18next'
import {StyledImageBadge} from './style'
import {ReactElement} from 'react'

export type BadgeType = 'selection' | 'to_revise' | 'manually_retouched'

const badgeTypeToIconDescription = {
    manually_retouched: {
        icon: <CheckIcon size={12} />,
        label: 'commons:manually_retouched'
    },
    selection: {
        icon: <CheckIcon size={12} />,
        label: 'commons:selected'
    },
    to_revise: {
        icon: <RefreshCcw01Icon size={12} />,
        label: 'photoset:needs_review:to_revise'
    }
} as const satisfies Record<BadgeType, {icon: ReactElement; label: string}>

export const ImageBadge = ({type}: {type: BadgeType}) => {
    const {t} = useTranslation()
    return (
        <StyledImageBadge gap={1} align="center" justify="space-between">
            <span>{badgeTypeToIconDescription[type].icon}</span>
            <p>{t(badgeTypeToIconDescription[type].label)}</p>
        </StyledImageBadge>
    )
}
