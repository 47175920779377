import {Button} from '@/components/ui/button/Button'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {XCloseIcon} from '@/components/ui/icon/Icon'
import {Image} from '@/features/photoset/types'
import {FC, ReactNode} from 'react'
import {useTranslation} from 'react-i18next'
import {StyledGalleryModalHeadingGroup} from '@/components/commons/gallery-modal-header/style'

interface GalleryModalHeaderProps {
    currentImage: Omit<Image, 'space'>
    onClose: () => void
    kickerTitle?: string
    iconTitle?: ReactNode
    subtitle?: string
    imagesCount?: number
    endSlot?: ReactNode
}

export const GalleryModalHeader: FC<GalleryModalHeaderProps> = ({
    currentImage,
    onClose,
    kickerTitle,
    iconTitle,
    subtitle,
    imagesCount,
    endSlot
}) => {
    const {t} = useTranslation()

    return (
        <Flexbox direction="column" gap={4} align="stretch">
            <Button iconOnly variant="ghost" size="sm" onClick={onClose}>
                <XCloseIcon />
            </Button>
            <Flexbox align="center" justify="space-between" gap={1}>
                {currentImage && (
                    <StyledGalleryModalHeadingGroup>
                        {kickerTitle && <p>{kickerTitle}</p>}
                        <Flexbox gap={1} align="center">
                            {iconTitle}
                            <h1>{currentImage.name}</h1>
                        </Flexbox>
                        {subtitle && <h2>{subtitle}</h2>}
                        {imagesCount && <h4>{t('commons:x_images', {count: imagesCount})}</h4>}
                    </StyledGalleryModalHeadingGroup>
                )}

                {endSlot}
            </Flexbox>
        </Flexbox>
    )
}
