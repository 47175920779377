import {Image} from '@/features/photoset/types'
import {StyledGalleryNeedsReviewFlags, StyledGalleryNeedsReviewModalSidebarContent} from './style'
import {useTranslation} from 'react-i18next'
import {Chip} from '@/components/commons/chip/Chip'

export const GalleryNeedsReviewSidebar = ({currentImage}: {currentImage: Omit<Image, 'space'>}) => {
    const {t} = useTranslation()
    const flags = currentImage.flags.filter(flag => flag.type == 'visual_retouching')

    return (
        <StyledGalleryNeedsReviewModalSidebarContent direction="column" gap={4}>
            <h4>{t('commons:flag_other')}</h4>
            <StyledGalleryNeedsReviewFlags gap={1.5}>
                {flags.length > 0 ? (
                    flags
                        .filter(flag => flag.type == 'visual_retouching')
                        .map(flag => (
                            <Chip
                                key={flag.id}
                                label={t(`photoset:flags:${flag.key_name}:label`)}
                                isActive
                                variant="info"
                            />
                        ))
                ) : (
                    <p>{t('photoset:needs_review:no_flags_fond')}</p>
                )}
            </StyledGalleryNeedsReviewFlags>
        </StyledGalleryNeedsReviewModalSidebarContent>
    )
}
