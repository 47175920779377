import {Dispatch, FC, SetStateAction, useMemo} from 'react'
import {GalleryModalHeader} from '@/components/commons/gallery-modal-header/GalleryModalHeader'
import {GalleryModalThumbnails} from '@/components/commons/gallery-modal-thumbnails/GalleryModalThumbnails'
import {GalleryModalSidebar} from '@/components/commons/gallery-modal-sidebar/GalleryModalSidebar'
import {useTranslation} from 'react-i18next'
import {Image, PhotosetSpace, Space, StepKey} from '@/features/photoset/types'
import {useGalleryEditingQaFlagsShortcuts} from '@/features/photoset/hooks/useGalleryEditingQaFlagsShortcuts'
import {spaceNameToLabel} from '@/features/photoset/utils'
import {GalleryModal} from '@/components/commons/gallery-modal/GalleryModal'
import {GalleryMetadata} from '@/features/photoset/components/gallery-photoset-modal/gallery-metadata/GalleryMetadata'
import {GalleryFlags} from '@/features/photoset/components/gallery-photoset-modal/gallery-flags/GalleryFlags'
import {FlagsPopover} from '@/features/photoset/components/flags-popover/FlagsPopover'
import {Chip} from '@/components/commons/chip/Chip'
import {PlusIcon} from '@/components/ui/icon/Icon'

type GalleryEditingQaFlagsModalProps = {
    selectableImages: Omit<Image, 'space'>[]
    currentDetailImageIndex: number
    setCurrentDetailImageIndex: Dispatch<SetStateAction<number | null>>
    onClose: () => void
    currentSpaceId: PhotosetSpace['id']
    currentSpaceName: Space['key_name']
    isQaView: boolean
}

export const GalleryEditingQaFlagsModal: FC<GalleryEditingQaFlagsModalProps> = ({
    selectableImages,
    currentDetailImageIndex,
    setCurrentDetailImageIndex,
    onClose,
    currentSpaceName,
    currentSpaceId,
    isQaView
}) => {
    const {t} = useTranslation()

    const currentImage = useMemo(
        () => selectableImages[currentDetailImageIndex],
        [currentDetailImageIndex, selectableImages]
    )

    useGalleryEditingQaFlagsShortcuts({
        selectableImages,
        currentDetailImageIndex,
        setCurrentDetailImageIndex,
        onClose
    })

    return (
        <GalleryModal
            currentImage={currentImage}
            thumbnails={currentImage.thumbnails}
            onClose={onClose}
            headerSlot={
                <GalleryModalHeader
                    kickerTitle={spaceNameToLabel[currentSpaceName]}
                    currentImage={currentImage}
                    onClose={onClose}
                />
            }
            thumbnailsSlot={
                <GalleryModalThumbnails
                    currentImage={currentImage}
                    thumbnails={'thumbnails'}
                    images={selectableImages}
                    onSelect={(_, index) => {
                        setCurrentDetailImageIndex(index)
                    }}
                />
            }
            sidebarSlot={
                <GalleryModalSidebar
                    title={t('photoset:editingQaFlags:sidebar_title')}
                    content={
                        <>
                            <GalleryMetadata imageMetadata={currentImage.metadata} />
                            <GalleryFlags
                                image={currentImage}
                                stepId={StepKey['editing-qa-flags']}
                                photosetSpaceIds={[currentSpaceId]}
                                isQaView={isQaView}
                                endSlot={
                                    isQaView && (
                                        <FlagsPopover
                                            stepId={StepKey['editing-qa-flags']}
                                            selectedImagesIds={[currentImage.id]}
                                            photosetSpaceIds={[currentSpaceId]}
                                            disableScrollBelow
                                            trigger={
                                                <Chip
                                                    label={t(`commons:flag`, {count: 1})}
                                                    isActive={false}
                                                    variant="info"
                                                    endSlot={<PlusIcon />}
                                                />
                                            }
                                        />
                                    )
                                }
                            />
                        </>
                    }
                />
            }
        />
    )
}
