import {useMutation, UseMutationOptions} from '@tanstack/react-query'
import {httpDeallocateImages, HttpDeallocateImagesOptions} from '@/features/photoset/services/photoset.http.ts'
import {MUTATION_KEYS} from '@/queryClient.ts'
import {AxiosError} from 'axios'

export const useDeallocateImages = (
    options?: Omit<UseMutationOptions<unknown, AxiosError, HttpDeallocateImagesOptions>, 'mutationFn' | 'mutationKey'>
) => {
    return useMutation({
        ...options,
        mutationKey: [MUTATION_KEYS.DEALLOCATE_IMAGES],
        mutationFn: httpDeallocateImages
    })
}
