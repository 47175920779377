import {Button} from '@/components/ui/button/Button'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {Content} from '@radix-ui/react-popover'
import styled, {CSSProperties, css} from 'styled-components'

export const StyledFlagsPopoverTrigger = styled.div<{$width?: CSSProperties['width']}>(
    ({$width = 'fit-content'}) => css`
        width: ${$width};
        cursor: pointer;
    `
)

export const StyledFlagsPopoverContent = styled(Content)<{$width?: CSSProperties['width']}>(
    ({theme: {palette, spacing, shadows, zIndex}, $width: width = '200px'}) => css`
        width: ${width};
        background-color: ${palette.neutral.white};
        border: 1px solid ${palette.neutral['200']};
        border-radius: 8px;
        padding: ${spacing}px 0;
        box-shadow: ${shadows.lg};
        z-index: ${zIndex.modal};
    `
)

export const StyledEmptyFlagsResult = styled(Flexbox)(
    ({theme: {spacing, typography, palette}}) => css`
        padding: ${spacing * 8}px 0;
        & h3 {
            ${typography.textLg};
            font-weight: 600;
            color: ${palette.neutral['900']};
            text-align: center;
        }
    `
)

export const StyledSearchFlag = styled(Flexbox)(
    ({theme: {spacing}}) => css`
        padding: ${spacing * 3}px ${spacing * 3.5}px;
    `
)

export const StyledInputSearchFlag = styled.input(
    ({theme: {typography}}) => css`
        ${typography.textMd};
        width: 100%;
    `
)

export const StyledPopoverFlagsList = styled.div`
    height: 280px;
`

export const StyledFlagsPopoverGroupLabel = styled.span(
    ({theme: {palette, spacing, typography}}) => css`
        ${typography.textXs};
        display: block;
        font-weight: 500;
        color: ${palette.neutral['500']};
        padding: ${spacing}px ${spacing * 3}px;
    `
)

export const StyledFlagsPopoverGroup = styled.div(
    ({theme: {spacing}}) => css`
        padding: ${spacing}px;
    `
)

export const StyledFlagsPopoverItem = styled(Flexbox)(
    ({theme: {palette, spacing, typography}}) => css`
        ${typography.textSm};
        font-weight: 500;
        color: ${palette.neutral['700']};
        padding: ${spacing * 2}px ${spacing * 3}px;
        transition: ease-in-out 150ms;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    `
)

export const StyledMultipleFlagsPopoverTrigger = styled(Button)(
    ({theme: {palette}}) => css`
        color: ${palette.blue[700]};

        &[data-state='open'] {
            box-shadow: 0 0 0 4px ${palette.primary[200]};
        }
    `
)
