import {HTMLAttributes} from 'react'
import {
    StyledPhotosetStepBodyContainer,
    StyledPhotosetStepCardsContainer,
    StyledPhotosetStepGridContainer,
    StyledPhotosetStepWrapper
} from './style'
import {ContainerProps} from '@/components/ui/container/Container'

export const PhotosetStepWrapper = (props: HTMLAttributes<HTMLDivElement>) => <StyledPhotosetStepWrapper {...props} />

export const PhotosetStepBodyContainer = (props: ContainerProps & {$disabled?: boolean}) => (
    <StyledPhotosetStepBodyContainer {...props} />
)

export const PhotosetStepGridContainer = (props: ContainerProps) => <StyledPhotosetStepGridContainer {...props} />

export const PhotosetStepCardsContainer = (props: ContainerProps & {$disabled?: boolean}) => (
    <StyledPhotosetStepCardsContainer {...props} />
)
