import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import styled, {css} from 'styled-components'

export const StyledPageHeaderContainer = styled.div`
    ${({theme: {spacing, palette}}) => css`
        display: grid;
        align-items: start;
        grid-template-columns: 1fr auto;
        padding: ${spacing * 6}px 0;
        border-bottom: 1px solid ${palette.neutral['200']};
        background: ${palette.neutral.white};
        z-index: 1;
    `}
`

export const StyledPageHeaderText = styled(Flexbox)`
    ${({theme: {palette, typography, mediaQueries}}) => css`
        align-items: center;

        & h1 {
            color: ${palette.neutral['900']};
            ${typography.textLg};
            font-weight: 700;
        }

        & p {
            color: ${palette.neutral['600']};
            ${typography.textMd}
        }

        ${mediaQueries.xl} {
            flex-direction: column;
            align-items: flex-start;
        }
    `}
`
