import styled, {css} from 'styled-components'

export const StyledModalHeader = styled.div(
    ({theme: {spacing, palette, typography}}) => css`
        display: grid;
        text-align: center;
        grid-template-columns: repeat(3, 1fr);
        align-items: center;
        padding: ${spacing * 2.5}px ${spacing * 2}px;
        font-weight: 700;
        color: ${palette.neutral[900]};

        & h3 {
            ${typography.textSm};
            white-space: nowrap;
        }
    `
)

export const StyledModalBody = styled.div(
    ({theme: {spacing}}) => css`
        padding: ${spacing * 6}px;
        height: 100%;
        overflow: auto;
        display: flex;
        flex-direction: column;
        gap: ${spacing * 6}px;
    `
)

export const StyledModalFooter = styled.div(
    ({theme: {spacing, palette}}) => css`
        padding: ${spacing * 4}px ${spacing * 6}px;
        border-top: 1px solid ${palette.neutral['200']};
        display: flex;
        justify-content: space-between;
    `
)

export const StyledModalTitle = styled.h3(
    ({theme: {typography}}) => css`
        ${typography.textXl};
        font-weight: 500;
        & b {
            font-weight: 700;
        }
    `
)

export const StyledModalParagraph = styled.p(
    ({theme: {typography}}) => css`
        ${typography.textSm};
    `
)
