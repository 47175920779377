import {PageFooter} from '@/components/commons/page-footer/PageFooter'
import {PageHero} from '@/components/commons/page-hero/PageHero'
import {Button} from '@/components/ui/button/Button'
import {ArrowLeftIcon, CheckIcon, Edit03Icon, MessageTextSquare01Icon} from '@/components/ui/icon/Icon'
import {PhotosetSpaceElement} from '@/features/photoset/components/photoset-space-element/PhotosetSpaceElement'
import {
    PhotosetStepBodyContainer,
    PhotosetStepWrapper
} from '@/features/photoset/components/photoset-step-atoms/PhotosetStepAtoms'
import {RetouchingQaSpaceImages} from '@/features/photoset/components/retouching-qa-space-images/RetouchingQaSpaceImages'
import {GET_PHOTOSET_IMAGES_LIST_RESPONSE_TYPE} from '@/features/photoset/services/useGetPhotosetImagesList'
import {PhotosetSpace, PhotosetURLParams, StepKey} from '@/features/photoset/types'
import {useTranslation} from 'react-i18next'
import {useOutletContext} from 'react-router-dom'
import {StyledRetouchingQaCommentButton, StyledRetouchingQaCommentPreview} from './style'
import {useEffect, useMemo, useState} from 'react'
import {RetouchingQaCommentModal} from '@/features/photoset/components/retouching-qa-comment-modal/RetouchingQaCommentModal'
import {retrievePhotosetSpacesFromSelectedImages, spaceNameToLabel} from '@/features/photoset/utils'
import {useGetPhotosetSpaces} from '@/features/photoset/services/useGetPhotosetSpaces'
import {RetouchingQaConfirmationModal} from '@/features/photoset/components/retouching-qa-confirmation-modal/RetouchingQaConfirmationModal'
import {useGetFlags} from '@/features/photoset/services/useGetFlags'
import {errorHandler} from '@/utilities/helpers'
import {QUERY_KEYS, queryClient} from '@/queryClient'
import {RetouchingQaCommentConfirmModal} from '@/features/photoset/components/retouching-qa-comment-confirm-modal/RetouchingQaCommentConfirmModal'
import {GalleryRetouchingQaModal} from '@/features/photoset/components/gallery-photoset-modal/gallery-retouching-qa-modal/GalleryRetouchingQaModal'
import {useExpandPhotosetImage} from '@/features/photoset/hooks/useExpandPhotosetImage'
import {usePhotosetStore} from '@/features/photoset/store/store'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {ImagesCounter} from '@/features/photoset/components/images-counter/ImagesCounter'
import {FlagsPopover} from '@/features/photoset/components/flags-popover/FlagsPopover'
import {MultipleFlagsPopoverTrigger} from '@/features/photoset/components/flags-popover/MultipleFlagsPopoverTrigger'
import {useUpdateStepToVettingQa} from '@/features/photoset/services/useUpdateStepToVettingQa'
import {StepHeroControls} from '@/features/photoset/components/step-hero-controls/StepHeroControls'
import {useCurrentPhotosetSubRoute} from '@/features/photoset/hooks/useCurrentPhotosetSubRoute'

type SelectedComment = {
    photosetSpaceId: PhotosetSpace['id']
    spaceName: string
    comment?: string
}

export const PhotosetRetouchingQaStep = () => {
    const {t} = useTranslation()
    const {urlParams} = useOutletContext<{urlParams: PhotosetURLParams}>()
    const [selectedComment, setSelectedComment] = useState<SelectedComment | null>(null)
    const unselectAll = usePhotosetStore(state => state.unselectAll)
    const selectedImages = usePhotosetStore(state => state.selectedImages)
    const images = usePhotosetStore(state => state.images)
    const currentPhotosetSubRoute = useCurrentPhotosetSubRoute()

    //statuses
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)

    // queries
    const {
        data: photosetSpaces,
        isFetching: isFetchingPhotosetSpaces,
        isError: isErrorPhotosetSpaces
    } = useGetPhotosetSpaces({
        urlParams: {
            photosetId: urlParams.id
        },
        params: {
            response_type: GET_PHOTOSET_IMAGES_LIST_RESPONSE_TYPE,
            is_final_select: true
        }
    })

    // mutations
    const {mutate: updateStepToVettingQa, isPending: isPendingUpdateStepToVettingQa} = useUpdateStepToVettingQa({
        onError: errorHandler,
        onSuccess: () => {
            void queryClient.invalidateQueries({queryKey: [QUERY_KEYS.PHOTOSET]})
            void queryClient.invalidateQueries({queryKey: [QUERY_KEYS.PHOTOSET_SPACES]})
        }
    })

    const initPhotosetSpaces = usePhotosetStore(state => state.initPhotosetSpaces)
    const flagsQuery = useGetFlags(
        {
            params: {
                types: ['visual_retouching'],
                response_type: 'extended'
            }
        },
        {staleTime: Infinity}
    )

    // values
    const allFlagsKeyName = useMemo(() => flagsQuery.data?.map(flag => flag.key_name), [flagsQuery.data])

    const hasFlags = useMemo(() => {
        const extractFlagKeysFromImages = (photosetSpace: PhotosetSpace) => {
            return photosetSpace.images.flatMap(image => image.flags.map(flag => flag.key_name))
        }

        const allFlagKeys = photosetSpaces?.flatMap(extractFlagKeysFromImages)

        return allFlagKeys?.some(flagKeyName => allFlagsKeyName?.includes(flagKeyName))
    }, [photosetSpaces, allFlagsKeyName])

    const isQaView = useMemo(() => currentPhotosetSubRoute == 'qa', [currentPhotosetSubRoute])

    // Handle gallery modal view
    const {
        currentDetailImageIndex,
        setCurrentDetailImageIndex,
        currentDetailSpaceIndex,
        currentDetailSpace,
        onExpandImage,
        onReduceImage
    } = useExpandPhotosetImage()

    //init Photoset Spaces
    useEffect(() => {
        if (photosetSpaces) {
            initPhotosetSpaces(photosetSpaces)
        }
    }, [photosetSpaces])

    // Retrieve selected photoset spaces from selected images
    const selectedPhotosetSpaces = useMemo(() => {
        return retrievePhotosetSpacesFromSelectedImages(images, selectedImages)
    }, [photosetSpaces, images, selectedImages])

    return (
        <>
            <PageHero
                title={t('photoset:retouching_qa:title')}
                subtitle={t('photoset:retouching_qa:subtitle')}
                endSlot={
                    selectedImages.length >= 1 ? (
                        isQaView && (
                            <Flexbox align="center" gap={2}>
                                <ImagesCounter count={selectedImages.length} />

                                <FlagsPopover
                                    stepId={StepKey['retouching-qa']}
                                    types={['visual_retouching']}
                                    selectedImagesIds={selectedImages}
                                    photosetSpaceIds={selectedPhotosetSpaces}
                                    trigger={<MultipleFlagsPopoverTrigger />}
                                />

                                <Button size="sm" variant="tertiary" onClick={() => unselectAll()}>
                                    {t('commons:unselect_all')}
                                </Button>
                            </Flexbox>
                        )
                    ) : (
                        <StepHeroControls hideSortControls />
                    )
                }
            />

            {showConfirmationModal && !hasFlags && (
                <RetouchingQaConfirmationModal
                    photosetId={urlParams.id}
                    onClose={() => setShowConfirmationModal(false)}
                />
            )}

            {showConfirmationModal && hasFlags && (
                <RetouchingQaCommentConfirmModal
                    photosetId={urlParams.id}
                    onClose={() => setShowConfirmationModal(false)}
                />
            )}

            <PhotosetStepWrapper>
                <PhotosetStepBodyContainer fullWidth>
                    {isFetchingPhotosetSpaces &&
                        [0, 1, 2].map(e => <PhotosetSpaceElement asSkeleton key={`${e}-id`} />)}
                    {!isFetchingPhotosetSpaces &&
                        !isErrorPhotosetSpaces &&
                        photosetSpaces?.map(
                            (photosetSpace, spaceIndex) =>
                                photosetSpace.images.length > 0 && (
                                    <PhotosetSpaceElement
                                        key={photosetSpace.space.id}
                                        spaceName={photosetSpace.space.key_name}
                                        spaceHeaderEndSlot={
                                            photosetSpace.comment ? (
                                                <StyledRetouchingQaCommentPreview gap={2} align="center">
                                                    {isQaView && (
                                                        <Button
                                                            onClick={() =>
                                                                setSelectedComment({
                                                                    photosetSpaceId: photosetSpace.id,
                                                                    spaceName:
                                                                        spaceNameToLabel[photosetSpace.space.key_name],
                                                                    comment: photosetSpace.comment ?? undefined
                                                                })
                                                            }
                                                            variant="secondary"
                                                            size="xs"
                                                            iconOnly
                                                        >
                                                            <Edit03Icon size={12} />
                                                        </Button>
                                                    )}
                                                    <p>{photosetSpace.comment}</p>
                                                </StyledRetouchingQaCommentPreview>
                                            ) : (
                                                isQaView && (
                                                    <StyledRetouchingQaCommentButton
                                                        size="sm"
                                                        variant="tertiary"
                                                        onClick={() =>
                                                            setSelectedComment({
                                                                photosetSpaceId: photosetSpace.id,
                                                                spaceName:
                                                                    spaceNameToLabel[photosetSpace.space.key_name]
                                                            })
                                                        }
                                                    >
                                                        <MessageTextSquare01Icon size={20} />
                                                        <span>{t('photoset:retouching_qa:comment_button')}</span>
                                                    </StyledRetouchingQaCommentButton>
                                                )
                                            )
                                        }
                                        endSlot={
                                            <RetouchingQaSpaceImages
                                                images={photosetSpace.images}
                                                photosetId={urlParams.id}
                                                photosetSpaceId={photosetSpace.id}
                                                allFlagsKeyName={allFlagsKeyName}
                                                onExpandImage={onExpandImage}
                                                spaceIndex={spaceIndex}
                                                isQaView={isQaView}
                                            />
                                        }
                                    />
                                )
                        )}
                </PhotosetStepBodyContainer>
            </PhotosetStepWrapper>

            {isQaView && (
                <>
                    <PageFooter
                        startSlot={
                            <Button
                                variant="tertiary"
                                disabled={isPendingUpdateStepToVettingQa}
                                onClick={() => updateStepToVettingQa(urlParams.id)}
                            >
                                <ArrowLeftIcon />
                                <span>{t('commons:back')}</span>
                            </Button>
                        }
                        centerSlot={<p>{t('photoset:retouching_qa:footer_step_label')}</p>}
                        endSlot={
                            <Button variant="primarySuccess" onClick={() => setShowConfirmationModal(true)}>
                                <span>{t('commons:done')}</span>
                                <CheckIcon />
                            </Button>
                        }
                    />
                    {selectedComment && (
                        <RetouchingQaCommentModal {...selectedComment} onClose={() => setSelectedComment(null)} />
                    )}
                </>
            )}

            {currentDetailImageIndex != null && currentDetailSpaceIndex != null && currentDetailSpace && (
                <GalleryRetouchingQaModal
                    onClose={onReduceImage}
                    currentDetailImageIndex={currentDetailImageIndex}
                    setCurrentDetailImageIndex={setCurrentDetailImageIndex}
                    currentDetailSpaceIndex={currentDetailSpaceIndex}
                    selectableImages={currentDetailSpace.images}
                    currentPhotosetSpaceId={currentDetailSpace.id}
                    currentSpaceName={currentDetailSpace.space.key_name}
                    isQaView={isQaView}
                />
            )}
        </>
    )
}
