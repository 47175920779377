import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {Divider} from '@components/ui/divider/Divider.tsx'
import {FC, ReactNode} from 'react'
import {Skeleton} from '@components/ui/skeleton/Skeleton.tsx'
import {Space} from '@/features/photoset/types.ts'
import {ImageCard} from '@components/commons/image-card/ImageCard.tsx'
import {spaceNameToLabel} from '@/features/photoset/utils.ts'
import {StyledPhotosetSpaceElementHeader} from './style'

interface PhotosetSpaceElementProps {
    asSkeleton?: undefined
    spaceName: Space['key_name']
    spaceHeaderEndSlot?: ReactNode
    endSlot: ReactNode
}

interface PhotosetSpaceElementSkeletonProps {
    asSkeleton: true
    spaceName?: undefined
    spaceHeaderEndSlot?: undefined
    endSlot?: undefined
}

export const PhotosetSpaceElement: FC<PhotosetSpaceElementProps | PhotosetSpaceElementSkeletonProps> = ({
    asSkeleton,
    spaceName,
    spaceHeaderEndSlot,
    endSlot
}) => {
    if (asSkeleton) {
        return (
            <Flexbox direction={'column'}>
                <StyledPhotosetSpaceElementHeader gap={1} direction={'column'}>
                    <Skeleton height={28} width={100} />
                </StyledPhotosetSpaceElementHeader>
                <Divider direction={'horizontal'} endSpacing={4} />
                <Flexbox gap={4}>
                    <ImageCard asSkeleton />
                    <ImageCard asSkeleton />
                    <ImageCard asSkeleton />
                    <ImageCard asSkeleton />
                </Flexbox>
            </Flexbox>
        )
    }

    return (
        <Flexbox direction={'column'}>
            <StyledPhotosetSpaceElementHeader gap={1} direction={'column'}>
                <h3>{spaceNameToLabel[spaceName]}</h3>
                {spaceHeaderEndSlot && <h4>{spaceHeaderEndSlot}</h4>}
            </StyledPhotosetSpaceElementHeader>

            <Divider direction={'horizontal'} />

            {endSlot}
        </Flexbox>
    )
}

PhotosetSpaceElement.displayName = 'PhotosetSpaceElement'
