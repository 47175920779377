import {useEffect, useMemo} from 'react'
import {Photoset} from '@/features/photoset/types'
import {generatePath, useLocation, useNavigate} from 'react-router-dom'
import {routes} from '@/utilities/constants/routes'

// Map of step IDs to corresponding qa step slugs. This ensures a constant mapping between photoset steps and their string representation.
export const qaStepIdToStepSlug = {
    5: 'editing-qa-selection',
    6: 'editing-qa-flags',
    7: 'review-completed',
    11: 'retouching-qa',
    12: 'retouching-qa-completed',
    14: 'vetting-qa'
} as const satisfies Partial<Record<NonNullable<Photoset['qa_step_id']>, string>>

// Custom hook that redirects the user to the appropriate page based on the photoset's current qa step
export const useRedirectQaPhotoset = ({
    photosetId,
    qaStepId
}: {
    photosetId?: Photoset['id']
    qaStepId?: Photoset['qa_step_id']
}) => {
    const navigate = useNavigate()
    const location = useLocation()
    const currentStepSlug = qaStepId ? qaStepIdToStepSlug[qaStepId as keyof typeof qaStepIdToStepSlug] ?? null : null

    const currentUrlStep = location.pathname.split('/').at(-1)

    // Mapping each qa step slug to its corresponding route in the application
    const stepKeyToRoute = useMemo(
        () => ({
            'unavailable-qa': routes.PHOTOSET_UNAVAILABLE_QA.path,
            'editing-qa-selection': routes.PHOTOSET_EDITING_QA_SELECTION.path,
            'editing-qa-flags': routes.PHOTOSET_EDITING_QA_FLAGS.path,
            'review-completed': routes.PHOTOSET_EDITING_QA_COMPLETED.path,
            'retouching-qa': routes.PHOTOSET_RETOUCHING_QA.path,
            'retouching-qa-completed': routes.PHOTOSET_RETOUCHING_QA_COMPLETED.path,
            'vetting-qa': routes.PHOTOSET_VETTING_QA.path
        }),
        []
    )

    useEffect(() => {
        if (photosetId) {
            if (currentStepSlug == null) {
                navigate(generatePath(stepKeyToRoute['unavailable-qa'], {id: photosetId}), {replace: true})
            } else {
                if (currentUrlStep) {
                    navigate(generatePath(stepKeyToRoute[currentStepSlug], {id: photosetId}), {replace: true})
                }
            }
        }
    }, [photosetId, currentStepSlug])
}
