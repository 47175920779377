import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {FC, ReactNode} from 'react'
import {StyledManualRetouchingCard, StyledManualRetouchingCardTitle} from './style'

interface ManualRetouchingCardProps {
    icon: ReactNode
    title: string
    subtitle: string
    paragraph: string
    endSlot: ReactNode
}

export const ManualRetouchingCard: FC<ManualRetouchingCardProps> = ({icon, title, subtitle, paragraph, endSlot}) => {
    return (
        <StyledManualRetouchingCard direction="column" gap={7} align="center">
            {icon}
            <Flexbox direction="column" align="center" gap={2}>
                <StyledManualRetouchingCardTitle>{title}</StyledManualRetouchingCardTitle>
                <Flexbox direction="column" gap={6} align="center">
                    <h4>{subtitle}</h4>
                    <p>{paragraph}</p>
                </Flexbox>
            </Flexbox>
            {endSlot}
        </StyledManualRetouchingCard>
    )
}
