import {useMutation, UseMutationOptions} from '@tanstack/react-query'
import {httpUpdateStepToCompleted} from '@/features/photoset/services/photoset.http.ts'
import {MUTATION_KEYS} from '@/queryClient.ts'
import {AxiosError} from 'axios'

export const useUpdateStepToCompleted = (
    options?: Omit<
        UseMutationOptions<
            unknown,
            AxiosError,
            {
                photosetId: number
                imagesIds: number[]
            }
        >,
        'mutationFn' | 'mutationKey'
    >
) =>
    useMutation({
        ...options,
        mutationKey: [MUTATION_KEYS.UPDATE_STEP_COMPLETED],
        mutationFn: variables => httpUpdateStepToCompleted(variables)
    })
