import {Image} from '@/features/photoset/types.ts'
import {useHotkeys} from 'react-hotkeys-hook'
import {Dispatch, SetStateAction} from 'react'

type UseGalleryRetouchingQaShortcuts = {
    currentImage: Omit<Image, 'space'>
    selectableImages: Omit<Image, 'space'>[]
    currentDetailImageIndex: number
    setCurrentDetailImageIndex: Dispatch<SetStateAction<number | null>>
    onClose: () => void
    onToggleSelection: () => void
    currentSpaceIndex: number | null
}

export const useGalleryRetouchingQaShortcuts = (args: UseGalleryRetouchingQaShortcuts) => {
    const {currentDetailImageIndex, setCurrentDetailImageIndex, selectableImages, onClose} = args

    useHotkeys(
        'ArrowLeft',
        () => {
            setCurrentDetailImageIndex(currentDetailImageIndex - 1)
        },
        {enabled: currentDetailImageIndex >= 1},
        [currentDetailImageIndex]
    )

    useHotkeys(
        'ArrowRight',
        () => {
            setCurrentDetailImageIndex(currentDetailImageIndex + 1)
        },
        {enabled: currentDetailImageIndex < selectableImages.length - 1},
        [currentDetailImageIndex]
    )

    useHotkeys('Esc', onClose)

    useHotkeys('Space', () => args.onToggleSelection())
}
