import {CheckCircleIcon} from '@/components/ui/icon/Icon'
import {
    StyledEditingQaCompletedStepContainer,
    StyledEditingQaCompletedStepCopyWrapper,
    StyledEditingQaCompletedStepParapraph,
    StyledEditingQaCompletedStepTitle
} from '@pages/photoset/photoset-editing-qa-completed-step/style.ts'
import {useTranslation} from 'react-i18next'
import {useTheme} from 'styled-components'

export const PhotosetEditingQaCompletedStep = () => {
    const {t} = useTranslation()
    const {palette} = useTheme()

    return (
        <StyledEditingQaCompletedStepContainer>
            <>
                <CheckCircleIcon fill={palette.success['600']} width={48} height={48} />
                <StyledEditingQaCompletedStepCopyWrapper direction="column" gap={2} align="center">
                    <StyledEditingQaCompletedStepTitle>
                        {t('photoset:editingQaCompleted:title')}
                    </StyledEditingQaCompletedStepTitle>
                    <StyledEditingQaCompletedStepParapraph>
                        {t('photoset:editingQaCompleted:subtitle')}
                    </StyledEditingQaCompletedStepParapraph>
                </StyledEditingQaCompletedStepCopyWrapper>
            </>
        </StyledEditingQaCompletedStepContainer>
    )
}

PhotosetEditingQaCompletedStep.displayName = 'PhotosetEditingQaCompletedStep'
