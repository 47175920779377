import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/queryClient.ts'
import {httpGetReasonsDecline, HttpGetReasonsDeclineResponse} from '@/features/photoset/services/photoset.http.ts'
import {parseAxiosPromise} from '@/axiosInstance.ts'
import {captureException} from '@sentry/react'

export const useGetReasonsDecline = (
    options?: Omit<UseQueryOptions<HttpGetReasonsDeclineResponse>, 'queryFn' | 'queryKey'>
) =>
    useQuery({
        ...options,
        queryKey: [QUERY_KEYS.REASONS_DECLINE],
        queryFn: () =>
            parseAxiosPromise({
                axiosPromise: httpGetReasonsDecline(),
                responseShape: HttpGetReasonsDeclineResponse,
                onZodError: captureException
            })
    })
