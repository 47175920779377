import {useTranslation} from 'react-i18next'
import {PhotosetSpace} from '@/features/photoset/types'
import {SpaceImagesContainer} from '../space-images-atoms/SpaceImagesAtoms'
import {ImageCard} from '@/components/commons/image-card/ImageCard'
import {Image01Icon, Stars01Icon} from '@/components/ui/icon/Icon'
import {Chip} from '@/components/commons/chip/Chip'
import {StyledNeedsReviewImageFlags} from './style'
import {ExpandImageButton} from '@/components/commons/expand-image-button/ExpandImageButton'

export const NeedsReviewSpaceImages = ({
    images,
    spaceIndex,
    onExpandImage
}: {
    images: PhotosetSpace['images']
    spaceIndex: number
    onExpandImage: (spaceIndex: number, imageIndex: number) => void
}) => {
    const {t} = useTranslation()

    return (
        <SpaceImagesContainer $gap={4}>
            {images.map((image, index) => {
                const thumbnails = image.retouched_thumbnails?.xs || image.ai_retouched_thumbnails?.xs

                return (
                    <ImageCard
                        key={image.id}
                        image={image}
                        isSelected={false}
                        hideCheckbox
                        thumbnail={thumbnails}
                        imageNameIcon={
                            !image.is_manually_retouched ? <Stars01Icon size={20} /> : <Image01Icon size={20} />
                        }
                        badgeType="to_revise"
                        showBadge={image.needs_to_review}
                        endSlot={
                            <>
                                <StyledNeedsReviewImageFlags gap={1.5}>
                                    {image.flags
                                        .filter(flag => flag.type == 'visual_retouching')
                                        .map(flag => (
                                            <Chip
                                                key={flag.id}
                                                label={t(`photoset:flags:${flag.key_name}:label`)}
                                                isActive={true}
                                                variant={'info'}
                                            />
                                        ))}
                                </StyledNeedsReviewImageFlags>
                                <ExpandImageButton onClick={() => onExpandImage(spaceIndex, index)} />
                            </>
                        }
                    />
                )
            })}
        </SpaceImagesContainer>
    )
}
