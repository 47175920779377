import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import styled, {css} from 'styled-components'

export const StyledSpacesSidebar = styled.div`
    width: 336px;
    display: grid;
    grid-template-rows: auto auto minmax(0, 1fr);
    grid-template-columns: minmax(0, 1fr);
`

export const StyledSpacesList = styled(Flexbox)(
    ({theme: {spacing}}) => css`
        height: 100%;
        overflow-y: auto;
        padding: ${spacing * 6}px ${spacing * 2}px ${spacing * 6}px ${spacing * 6}px;
    `
)
