import {StyledCurationSleElementHeader} from '@/features/photoset/components/curation-photoset-space/styel.ts'
import {
    StyledUnusableCurationSpaceAlert,
    StyledUnusableCurationSpaceBody
} from '@/features/photoset/components/unusable-curation-photoset-space/styel.ts'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {FC, useEffect} from 'react'
import {spaceNameToLabel} from '@/features/photoset/utils.ts'
import {useTranslation} from 'react-i18next'
import {Image, PhotosetSpace, PhotosetSpacesChecks, Space, SpaceConfiguration} from '@/features/photoset/types.ts'
import {Divider} from '@components/ui/divider/Divider.tsx'
import {Radio} from '@components/commons/radio/Radio.tsx'
import {errorHandler} from '@utilities/helpers.ts'
import {useAddPhotosetSpaceFlag} from '@/features/photoset/services/useAddPhotosetSpaceFlag.ts'
import {CurationPhotosetSpaceImages} from '@/features/photoset/components/curation-photoset-space-images/CurationPhotosetSpaceImages'

interface UnusableCurationPhotosetSpaceProps {
    type: 'noImages' | 'minimumNotMet'
    spaceName: Space['key_name']
    spaceId: PhotosetSpace['id']
    totalImages: number
    images: Omit<Image, 'space'>[]
    spaceConfigs: SpaceConfiguration
    spaceFlagValue: PhotosetSpace['flag_key_name']
    spaceChecks: PhotosetSpacesChecks
    curationTouched: boolean
    spaceIndex: number
    onExpandImage: (spaceIndex: number, imageIndex: number) => void
    cbSetHideAllUnselected: () => void
}

export const UnusableCurationPhotosetSpace: FC<UnusableCurationPhotosetSpaceProps> = ({
    type,
    spaceName,
    spaceId,
    totalImages,
    images,
    spaceConfigs,
    spaceChecks,
    spaceFlagValue,
    curationTouched,
    spaceIndex,
    cbSetHideAllUnselected,
    onExpandImage
}) => {
    const {t} = useTranslation()
    const {mutate: addPhotosetSpaceFlag} = useAddPhotosetSpaceFlag({onError: errorHandler})

    useEffect(() => {
        if (type == 'minimumNotMet' && !spaceFlagValue) {
            addPhotosetSpaceFlag({photosetSpaceId: spaceId, flag: 'cannot_reach_minimum'})
        }
    }, [])

    const unusableCurationSpaceBody = () => {
        switch (type) {
            case 'noImages':
                return (
                    <StyledUnusableCurationSpaceBody
                        direction={'column'}
                        gap={3}
                        align={'center'}
                        $isEmpty={totalImages == 0}
                    >
                        <h3>{t('photoset:curation:space:no_images_title')}</h3>
                        <p>{t('photoset:curation:space:no_images_desc')}</p>
                        {type == 'noImages' && (
                            <Flexbox gap={4}>
                                <Radio
                                    defaultChecked={spaceFlagValue == 'no_assets_delivered'}
                                    value={'no_assets_delivered'}
                                    onChange={() =>
                                        addPhotosetSpaceFlag({photosetSpaceId: spaceId, flag: 'no_assets_delivered'})
                                    }
                                    label={t('photoset:curation:space:no_assets_delivered')}
                                    name={`radio-${spaceName}`}
                                />
                                <Radio
                                    defaultChecked={spaceFlagValue == 'no_elements_applicable'}
                                    value={'no_elements_applicable'}
                                    onChange={() =>
                                        addPhotosetSpaceFlag({photosetSpaceId: spaceId, flag: 'no_elements_applicable'})
                                    }
                                    label={t('photoset:curation:space:no_applicable_element')}
                                    name={`radio-${spaceName}`}
                                />
                            </Flexbox>
                        )}
                    </StyledUnusableCurationSpaceBody>
                )
            case 'minimumNotMet':
                return (
                    <CurationPhotosetSpaceImages
                        images={images}
                        spaceId={spaceId}
                        spaceIndex={spaceIndex}
                        onExpandImage={onExpandImage}
                        cbSetHideAllUnselected={cbSetHideAllUnselected}
                    />
                )
            default:
                return (
                    <StyledUnusableCurationSpaceBody
                        direction={'column'}
                        gap={3}
                        align={'center'}
                        $isEmpty={totalImages == 0}
                    >
                        <h3>{t('photoset:curation:space:minimum_not_met_title')}</h3>
                        <p>
                            {t('photoset:curation:space:minimum_not_met_desc', {
                                imagesCount: totalImages,
                                minimumCount: spaceConfigs.raws_minimum_images
                            })}
                        </p>
                    </StyledUnusableCurationSpaceBody>
                )
        }
    }

    return (
        <Flexbox direction={'column'}>
            <StyledCurationSleElementHeader gap={1} direction={'column'}>
                <h4>{spaceNameToLabel[spaceName]}</h4>

                {totalImages == 0 && (
                    <Flexbox align={'center'}>
                        {type == 'noImages' && (
                            <StyledUnusableCurationSpaceAlert
                                $isError={curationTouched && spaceChecks.spaceFlagRequired}
                            >
                                <p>{t('photoset:curation:space:select_reason')}</p>
                            </StyledUnusableCurationSpaceAlert>
                        )}
                    </Flexbox>
                )}
            </StyledCurationSleElementHeader>
            <Divider direction={'horizontal'} />
            {unusableCurationSpaceBody()}
        </Flexbox>
    )
}

UnusableCurationPhotosetSpace.displayName = 'UnusableCurationPhotosetSpace'
