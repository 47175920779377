import {useMutation, useQueryClient} from '@tanstack/react-query'
import {httpRemoveImagesFlag} from '@/features/photoset/services/photoset.http.ts'
import {MUTATION_KEYS, QUERY_KEYS} from '@/queryClient.ts'
import {AxiosError} from 'axios'
import {ErrorResponseData} from '@/types/commons'
import {useOutletContext} from 'react-router-dom'
import {Flag, Image, Photoset, PhotosetSpace, PhotosetURLParams, StepKey} from '@/features/photoset/types'
import {
    updateCachedEditingQaFlags,
    updateCachedImagesFlag,
    updateCachedSpaceImagesFlag
} from '@/features/photoset/utils'
import {errorHandler} from '@/utilities/helpers'

export type RemoveImagesFlagData = {
    flag: Flag
    imageIds: Image['id'][]
}

type UseRemoveImagesFlagOptions = {
    onSuccess?: (variables: RemoveImagesFlagData) => void
    onError?: (error: AxiosError<ErrorResponseData>) => void
}

export const useRemoveImagesFlag = ({
    stepId,
    photosetSpaceIds,
    options
}: {
    stepId: Photoset['step_id']
    photosetSpaceIds?: PhotosetSpace['id'][]
    options?: UseRemoveImagesFlagOptions
}) => {
    const queryClient = useQueryClient()
    const {urlParams} = useOutletContext<{urlParams: PhotosetURLParams}>()

    return useMutation({
        ...options,
        mutationKey: [MUTATION_KEYS.REMOVE_IMAGES_FLAG],
        mutationFn: (data: RemoveImagesFlagData) =>
            httpRemoveImagesFlag({
                urlParams: {photosetId: urlParams.id, flagId: data.flag.id},
                params: {imageIds: data.imageIds}
            }),
        onMutate: async vars => {
            // Update get-images cache is the base behaviour
            const previousImages = await updateCachedImagesFlag({
                operation: 'remove',
                updatedFlag: vars.flag,
                imageIdsToUpdate: vars.imageIds
            })

            let previousSpaceImages: PhotosetSpace[] = []

            // Optimistic update (curation step and retouching qa step)
            if ((stepId == StepKey['curation'] || stepId == StepKey['retouching-qa']) && photosetSpaceIds) {
                previousSpaceImages = await updateCachedSpaceImagesFlag({
                    operation: 'remove',
                    photosetSpaceIds,
                    updatedFlag: vars.flag,
                    imageIdsToUpdate: vars.imageIds
                })
            }

            // Optimistic update (editing qa flags  step)
            if (stepId == StepKey['editing-qa-flags'] && photosetSpaceIds) {
                previousSpaceImages = await updateCachedEditingQaFlags({
                    operation: 'remove',
                    photosetSpaceIds,
                    updatedFlag: vars.flag,
                    imageIdsToUpdate: vars.imageIds
                })
            }

            return {previousImages, previousSpaceImages}
        },
        onError: (error: AxiosError<ErrorResponseData>, _, context) => {
            queryClient.setQueriesData({queryKey: [QUERY_KEYS.IMAGES]}, context?.previousImages)

            if (stepId == StepKey['curation'] || stepId == StepKey['retouching-qa']) {
                queryClient.setQueriesData({queryKey: [QUERY_KEYS.PHOTOSET_SPACES]}, context?.previousSpaceImages)
            }

            options?.onError?.(error)
            errorHandler(error)
        },
        onSuccess: (_, variables) => {
            options?.onSuccess?.(variables)
        }
    })
}
