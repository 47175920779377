import {palette} from '@/theme/palette'
import styled, {css, CSSProperties} from 'styled-components'

type IconContainerSize = 'md' | 'lg'
type IconContainerType = 'neutral' | 'danger' | 'success' | 'warning' | 'blue'

const iconContainerTypeToColors = {
    neutral: {
        background: 'transparent',
        borderColor: palette.light.neutral[600]
    },
    danger: {
        background: palette.light.danger[600],
        borderColor: palette.light.danger[200]
    },
    warning: {
        background: palette.light.warning[600],
        borderColor: palette.light.warning[200]
    },
    success: {
        background: palette.light.success[600],
        borderColor: palette.light.success[200]
    },
    blue: {
        background: palette.light.blue[600],
        borderColor: palette.light.blue[200]
    }
} as const satisfies Record<
    IconContainerType,
    {background: CSSProperties['background']; borderColor: CSSProperties['borderColor']}
>

export const StyledIconContainer = styled.div<{size: IconContainerSize; type: IconContainerType}>(
    ({size, type}) => css`
        flex: none;
        width: ${size == 'lg' ? '48px' : '40px'};
        height: ${size == 'lg' ? '48px' : '40px'};
        border-radius: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid ${iconContainerTypeToColors[type].borderColor};
        background: ${iconContainerTypeToColors[type].background};
        svg {
            width: ${size == 'lg' ? '24px' : '20px'};
        }
    `
)
