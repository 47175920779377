import {Button} from '@/components/ui/button/Button'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import styled, {css} from 'styled-components'

export const StyledGalleryRetouchingQaModalSidebarContent = styled(Flexbox)(
    ({theme: {spacing}}) => css`
        padding: ${spacing * 4}px;
    `
)

export const StyledGalleryRetouchingQaModalTrigger = styled(Button)(
    ({theme: {palette}}) => css`
        justify-content: space-between;
        cursor: pointer;
        box-shadow: none;
        font-weight: 400;
        color: ${palette.neutral[500]};
        width: 100%;

        & svg {
            transition: rotate 200ms ease-in-out;
        }

        &[data-state='open'] {
            box-shadow: 0 0 0 4px ${palette.primary[200]};

            & svg {
                transform: rotate(180deg);
            }
        }
    `
)

export const StyledGalleryRetouchingQaFlags = styled(Flexbox)`
    flex-wrap: wrap;
`
