import {Chip} from '@/components/commons/chip/Chip'
import {getFlagVariant} from '@/features/photoset/utils'
import toast from 'react-hot-toast'
import {useTranslation} from 'react-i18next'
import {useAddImagesFlag} from '@/features/photoset/services/useAddImagesFlag'
import {useRemoveImagesFlag} from '@/features/photoset/services/useRemoveImagesFlag'
import {Flag, Image, PhotosetSpace, StepKey} from '@/features/photoset/types'
import {FlipBackwardIcon, XCloseIcon} from '@/components/ui/icon/Icon'
import {FC} from 'react'

interface EditingQaChipProps {
    flag: Flag
    image: Omit<Image, 'space'>
    photosetSpaceIds: PhotosetSpace['id'][]
    label: string
    isQaView: boolean
}

export const EditingQaChip: FC<EditingQaChipProps> = ({flag, image, photosetSpaceIds, label, isQaView}) => {
    const {t} = useTranslation()

    const variant = getFlagVariant(flag.id, image.image_flags)

    const addImagesFlag = useAddImagesFlag({
        photosetSpaceIds,
        stepId: StepKey['editing-qa-flags'],
        options: {
            onError: () => toast.error(t('errors:default'))
        }
    })

    const removeImagesFlag = useRemoveImagesFlag({
        photosetSpaceIds,
        stepId: StepKey['editing-qa-flags'],
        options: {
            onError: () => toast.error(t('errors:default'))
        }
    })

    if (!variant) return

    return (
        <Chip
            key={flag.id}
            label={label}
            variant={variant}
            isActive={variant != 'neutral'}
            endSlot={
                isQaView && (
                    <button
                        tabIndex={-1}
                        disabled={addImagesFlag.isPending || removeImagesFlag.isPending}
                        onClick={() =>
                            variant == 'danger'
                                ? addImagesFlag.mutate({flag, imageIds: [image.id]})
                                : removeImagesFlag.mutate({flag, imageIds: [image.id]})
                        }
                    >
                        {variant == 'danger' ? <FlipBackwardIcon /> : <XCloseIcon />}
                    </button>
                )
            }
        />
    )
}
