import {FC} from 'react'
import {ListHeader} from '@/components/ui/list-header/ListHeader'
import {
    StyledMetadataFiltersContainer,
    StyledMetadataFiltersList,
    StyledMetadataFiltersRow,
    StyledShootListMetadataSidebar,
    StyledMetadataRowTitle,
    StyledMetadataImagesCount
} from '@/features/photoset/components/shoot-list-metadata-sidebar/style.ts'
import {useTranslation} from 'react-i18next'
import {Button} from '@/components/ui/button/Button'
import {AlertCircleIcon, XCloseIcon} from '@/components/ui/icon/Icon'
import {useGetImagesMetadata} from '@/features/photoset/services/useGetImagesMetadata'
import {ErrorBox} from '@/components/commons/error-box/ErrorBox'
import {ScrollArea} from '@components/commons/scroll-area/ScrollArea.tsx'
import {Scrollbar} from '@components/ui/scrollbar/Scrollbar.tsx'
import {Metadata, PhotosetImagesSearchParams, PhotosetURLParams} from '@/features/photoset/types'
import {ObjectKeys, groupBy} from '@/utilities/helpers'
import {useParsedSearchParams} from '@/hooks/useParsedSearchParams'
import {Skeleton} from '@/components/ui/skeleton/Skeleton'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {Chip} from '@/components/commons/chip/Chip'
import {formatMetadataValue} from '@/features/photoset/utils'
import {useOutletContext} from 'react-router-dom'

interface ShootListMetadataSidebarProps {
    hideSidebar: () => void
}

export const ShootListMetadataSidebar: FC<ShootListMetadataSidebarProps> = ({hideSidebar}) => {
    const {t} = useTranslation()
    const {urlParams} = useOutletContext<{urlParams: PhotosetURLParams}>()

    const metadataQuery = useGetImagesMetadata(
        {
            urlParams: {
                photosetId: urlParams.id
            }
        },
        {staleTime: Infinity}
    )

    const {searchParams, setSearchParams} = useParsedSearchParams([], PhotosetImagesSearchParams)

    const metadataDictionary = metadataQuery.data?.length ? groupBy(metadataQuery.data, ({type}) => type) : null

    const onFilterImages = (typeKey: Metadata['type'], typeValue: string) => {
        setSearchParams(
            searchParams.filter_by == typeKey && searchParams.filter_value == typeValue
                ? {
                      filter_by: null,
                      filter_value: null
                  }
                : {
                      filter_by: typeKey,
                      filter_value: typeValue
                  }
        )
    }

    return (
        <StyledShootListMetadataSidebar>
            <ListHeader
                size="sm"
                title={t('photoset:initial_qa:sidebar_title')}
                endSlot={
                    <Button variant="ghost" size="sm" onClick={hideSidebar} iconOnly>
                        <XCloseIcon />
                    </Button>
                }
            />

            {metadataQuery.isError ? (
                <ErrorBox icon={<AlertCircleIcon />} title={t('errors:default')} />
            ) : (
                <ScrollArea scrollbar={<Scrollbar />}>
                    <StyledMetadataFiltersContainer>
                        {metadataQuery.isPending
                            ? Array.from({length: 10}).map((_, index) => (
                                  <StyledMetadataFiltersRow gap={4} key={index}>
                                      <Skeleton height={40} width={110} />
                                      <Flexbox gap={2}>
                                          <Skeleton height={24} />
                                          <Skeleton height={24} />
                                      </Flexbox>
                                  </StyledMetadataFiltersRow>
                              ))
                            : metadataDictionary &&
                              ObjectKeys(metadataDictionary).map((typeKey, index) => (
                                  <StyledMetadataFiltersRow gap={4} key={index}>
                                      <StyledMetadataRowTitle>
                                          {t(`photoset:metadata:${typeKey}`)}
                                      </StyledMetadataRowTitle>

                                      <StyledMetadataFiltersList gap={2} align="stretch">
                                          {metadataDictionary[typeKey].map(item => (
                                              <Chip
                                                  key={item.value}
                                                  label={formatMetadataValue(item.type, item.value)}
                                                  variant="success"
                                                  onClick={() => onFilterImages(typeKey, item.value)}
                                                  isActive={
                                                      searchParams.filter_by == item.type &&
                                                      searchParams.filter_value == item.value
                                                  }
                                                  endSlot={
                                                      <StyledMetadataImagesCount
                                                          $isActive={
                                                              searchParams.filter_by == item.type &&
                                                              searchParams.filter_value == item.value
                                                          }
                                                      >
                                                          {item.count}
                                                      </StyledMetadataImagesCount>
                                                  }
                                              />
                                          ))}
                                      </StyledMetadataFiltersList>
                                  </StyledMetadataFiltersRow>
                              ))}
                    </StyledMetadataFiltersContainer>
                </ScrollArea>
            )}
        </StyledShootListMetadataSidebar>
    )
}
