import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import styled, {css} from 'styled-components'

export const StyledEditingQaSelectionImagesFrame = styled.div<{$isSelected: boolean}>(
    ({$isSelected, theme: {spacing, palette}}) => css`
        & > div {
            box-shadow: ${$isSelected ? `0 0 0 4px ${palette.neutral['700']}` : 'unset'};
            border-radius: 12px;
            padding: ${spacing * 2}px;
            justify-content: space-between;
            height: 100%;
            &:focus-within {
                background-color: unset;
                box-shadow: ${$isSelected ? `0 0 0 4px ${palette.neutral['700']}` : 'unset'};
            }
        }
    `
)

export const StyledFinalSelectedBadge = styled(Flexbox)(
    ({theme: {palette, typography}}) => css`
        & svg {
            fill: ${palette.neutral.white};
        }
        & > span {
            width: 18px;
            height: 18px;
            border-radius: 16px;
            align-items: center;
            display: flex;
            justify-content: center;
            background: ${palette.neutral['700']};
        }
        & > p {
            ${typography.textXs};
            font-weight: 500;
            color: ${palette.neutral.black};
        }
    `
)
