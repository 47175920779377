import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import styled, {css} from 'styled-components'

export const StyledGalleryModalThumbnails = styled(Flexbox)(
    ({theme: {spacing}}) => css`
        padding: ${spacing * 2}px ${spacing * 6}px ${spacing * 2}px 0;
        width: max-content;
    `
)

export const StyledGalleryModalThumbnail = styled.img<{$isSelected: boolean}>(
    ({theme: {palette, spacing}, $isSelected}) => css`
        background: ${palette.neutral.white};
        cursor: pointer;
        border-radius: 8px;
        aspect-ratio: 3/2;
        width: 140px;
        object-fit: contain;
        ${$isSelected
            ? css`
                  outline: ${palette.neutral[500]} solid 4px;
                  outline-offset: 4px;
                  box-shadow: 0 0 0 4px ${palette.neutral.white};

                  &:first-child {
                      margin-inline-start: ${spacing * 2}px;
                  }
                  &:last-child {
                      margin-inline-end: ${spacing * 2}px;
                  }
              `
            : css`
                  border: 1px solid ${palette.neutral.black}33;
              `}
    `
)

export const StyledGalleryPlaceholderThumb = styled(Flexbox)<{$isSelected: boolean}>(
    ({theme: {palette, spacing, typography}, $isSelected}) => css`
        ${typography.textXs};
        background: ${palette.neutral.white};
        cursor: pointer;
        border-radius: 8px;
        font-weight: 500;
        aspect-ratio: 3/2;
        width: 140px;
        ${$isSelected
            ? css`
                  outline: ${palette.neutral[500]} solid 4px;
                  outline-offset: 4px;
                  box-shadow: 0 0 0 4px ${palette.neutral.white};

                  &:first-child {
                      margin-inline-start: ${spacing * 2}px;
                  }
                  &:last-child {
                      margin-inline-end: ${spacing * 2}px;
                  }
              `
            : css`
                  border: 1px solid ${palette.neutral.black}33;
              `}
    `
)
