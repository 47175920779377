import {PageFooter} from '@/components/commons/page-footer/PageFooter'
import {PageHero} from '@/components/commons/page-hero/PageHero'
import {Button} from '@/components/ui/button/Button'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {ArrowLeftIcon, CheckIcon} from '@/components/ui/icon/Icon'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {ManualRetouchingErrorModal} from '@/features/photoset/components/manual-retouching-error-modal/ManualRetouchingErrorModal'
import {PhotosetGenerating} from '@/features/photoset/components/photoset-generating/PhotosetLoading'
import {PhotosetSpaceElement} from '@/features/photoset/components/photoset-space-element/PhotosetSpaceElement'
import {
    PhotosetStepBodyContainer,
    PhotosetStepWrapper
} from '@/features/photoset/components/photoset-step-atoms/PhotosetStepAtoms'
import {RetouchingRevisionSpaceImages} from '@/features/photoset/components/retouching-revision-space-images/RetouchingRevisionSpaceImages'
import {StepHeroControls} from '@/features/photoset/components/step-hero-controls/StepHeroControls'
import {SubHeaderBanner} from '@/features/photoset/components/subheader-banner/SubHeaderBanner'
import {useDeleteManualRetouchedImages} from '@/features/photoset/services/useDeleteManualRetouchedImages'
import {useGetNeedManualRetouchingSpaces} from '@/features/photoset/services/useGetNeedManualRetouchingSpaces'
import {useGetPhotoset} from '@/features/photoset/services/useGetPhotoset'
import {GET_PHOTOSET_IMAGES_LIST_RESPONSE_TYPE} from '@/features/photoset/services/useGetPhotosetImagesList'
import {useUpdateStepToAiVettingCompleted} from '@/features/photoset/services/useUpdateStepToAiVettingCompleted'
import {useUpdateStepToNeedsReview} from '@/features/photoset/services/useUpdateStepToNeedsReview'
import {PhotosetURLParams} from '@/features/photoset/types'
import {QUERY_KEYS, queryClient} from '@/queryClient'
import {errorHandler} from '@/utilities/helpers'
import {useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useOutletContext} from 'react-router-dom'

export const PhotosetRetouchingRevisionStep = () => {
    const {t} = useTranslation()
    const {urlParams} = useOutletContext<{urlParams: PhotosetURLParams}>()
    const [hideRetouchingRevisionErrorModal, setHideRetouchingRevisionErrorModal] = useState(false)
    const [isGenerationThumbsCompleted, setIsGenerationThumbsCompleted] = useState(false)

    // queries
    const photosetQuery = useGetPhotoset(
        {id: urlParams.id},
        {refetchInterval: () => (!isGenerationThumbsCompleted ? 10000 : false)}
    )
    const {
        data: photosetSpaces,
        isLoading: isLoadingManualRetouchingSpaces,
        isError: isErrorManualRetouchingSpaces,
        refetch: refetchPhotoset
    } = useGetNeedManualRetouchingSpaces(
        {
            urlParams: {
                photosetId: urlParams.id
            },
            params: {
                response_type: GET_PHOTOSET_IMAGES_LIST_RESPONSE_TYPE,
                is_final_select: true
            }
        },
        {enabled: false}
    )

    // mutations
    const {mutate: deleteManualRetouchedImages, isPending: isPendingDeleteManualRetouchedImages} =
        useDeleteManualRetouchedImages({
            photosetId: urlParams.id,
            options: {
                onError: errorHandler,
                onSuccess: () => {
                    void queryClient.invalidateQueries({queryKey: [QUERY_KEYS.PHOTOSET]})
                    void queryClient.invalidateQueries({queryKey: [QUERY_KEYS.MANUAL_RETOUCHING_SPACES]})
                }
            }
        })

    const {mutate: updateStepToAiVettingCompleted, isPending: isPendingUpdateToAiVettingCompleted} =
        useUpdateStepToAiVettingCompleted({
            onError: errorHandler,
            onSuccess: () => {
                void queryClient.invalidateQueries({queryKey: [QUERY_KEYS.PHOTOSET]})
                void queryClient.invalidateQueries({queryKey: [QUERY_KEYS.PHOTOSET_SPACES]})
            }
        })

    const {mutate: updateStepToNeedsReview, isPending: isPendingUpdateToNeedsReview} = useUpdateStepToNeedsReview({
        onError: errorHandler,
        onSuccess: () => {
            void queryClient.invalidateQueries({queryKey: [QUERY_KEYS.PHOTOSET]})
            void queryClient.invalidateQueries({queryKey: [QUERY_KEYS.PHOTOSET_SPACES]})
        }
    })

    // booleans
    const isNotRevisedImagesError = useMemo(
        () =>
            photosetSpaces && photosetQuery
                ? photosetSpaces.flatMap(photosetSpace =>
                      photosetSpace.images.filter(image => image.is_revised == false && image.needs_to_review == true)
                  ).length > 0
                : false,
        [photosetSpaces]
    )

    const isPending = useMemo(
        () =>
            isPendingUpdateToNeedsReview ||
            isPendingDeleteManualRetouchedImages ||
            isPendingUpdateToAiVettingCompleted ||
            isPendingUpdateToNeedsReview,
        [
            isPendingUpdateToNeedsReview,
            isPendingDeleteManualRetouchedImages,
            isPendingUpdateToAiVettingCompleted,
            isPendingUpdateToNeedsReview
        ]
    )

    useEffect(() => {
        if (photosetQuery?.data?.revised_thumbnail_generated_at) {
            refetchPhotoset()
            setIsGenerationThumbsCompleted(true)
        }
    }, [photosetQuery?.data?.revised_thumbnail_generated_at])

    if (!isGenerationThumbsCompleted) {
        return (
            <PhotosetGenerating
                title={t('commons:generating_thumbnails')}
                subtitle={t('commons:generating_waiting_page_description')}
            />
        )
    }

    return (
        <>
            <PageHero
                title={t('photoset:retouching_revision:title')}
                subtitle={t('photoset:retouching_revision:subtitle')}
                endSlot={<StepHeroControls hideSortControls />}
            />

            {isNotRevisedImagesError && !isLoadingManualRetouchingSpaces && (
                <SubHeaderBanner
                    type={'danger'}
                    title={t('photoset:retouching_revision:error_banner_title')}
                    subtitle={t('photoset:retouching_revision:error_banner_subtitle')}
                />
            )}

            {isNotRevisedImagesError &&
                !isLoadingManualRetouchingSpaces &&
                !photosetQuery.isLoading &&
                !hideRetouchingRevisionErrorModal && (
                    <ManualRetouchingErrorModal onClose={() => setHideRetouchingRevisionErrorModal(true)} />
                )}

            <PhotosetStepWrapper>
                <PhotosetStepBodyContainer fullWidth>
                    {isLoadingManualRetouchingSpaces &&
                        [0, 1, 2].map(e => <PhotosetSpaceElement asSkeleton key={`${e}-id`} />)}
                    {!isLoadingManualRetouchingSpaces &&
                        !isErrorManualRetouchingSpaces &&
                        photosetSpaces?.map(photosetSpace => {
                            const imagesRevisedLength = photosetSpace.images.filter(image => image.is_revised).length
                            const selectedImagesToReviseLength = photosetSpace.images.filter(
                                image => image.needs_to_review
                            ).length
                            return (
                                photosetSpace.images.length > 0 && (
                                    <PhotosetSpaceElement
                                        key={photosetSpace.space.id}
                                        spaceName={photosetSpace.space.key_name}
                                        spaceHeaderEndSlot={[
                                            t('commons:retouched_image', {
                                                count: imagesRevisedLength
                                            }),
                                            t('photoset:retouching_revision:selected_image', {
                                                count: selectedImagesToReviseLength
                                            })
                                        ].join(', ')}
                                        endSlot={
                                            <RetouchingRevisionSpaceImages
                                                images={photosetSpace.images}
                                                isLoadingManualRetouchingSpaces={isLoadingManualRetouchingSpaces}
                                            />
                                        }
                                    />
                                )
                            )
                        })}
                </PhotosetStepBodyContainer>
                <PageFooter
                    startSlot={
                        <Button
                            onClick={() => updateStepToNeedsReview(urlParams.id)}
                            variant="tertiary"
                            disabled={isPending}
                        >
                            <ArrowLeftIcon />
                            {t('photoset:needs_review:title')}
                        </Button>
                    }
                    endSlot={
                        <Flexbox gap={4}>
                            <Button
                                disabled={isPending || !isGenerationThumbsCompleted}
                                onClick={() => deleteManualRetouchedImages()}
                                variant="secondary"
                            >
                                {t('commons:delete_uploaded_images')}
                            </Button>
                            <Button
                                onClick={() => updateStepToAiVettingCompleted(urlParams.id)}
                                disabled={isNotRevisedImagesError || isPending || !isGenerationThumbsCompleted}
                                variant={isNotRevisedImagesError && !isPending ? 'primary' : 'primarySuccess'}
                            >
                                {isPendingUpdateToAiVettingCompleted && <Spinner size={20} />}
                                {t('commons:done')}
                                <CheckIcon />
                            </Button>
                        </Flexbox>
                    }
                />
            </PhotosetStepWrapper>
        </>
    )
}
