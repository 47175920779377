import {FC} from 'react'
import {Image, StepKey} from '@/features/photoset/types'
import {usePhotosetStore} from '@/features/photoset/store/store'
import {useTranslation} from 'react-i18next'
import {Button} from '@/components/ui/button/Button'
import {
    StyledAllHiddenImages,
    StyledCurationElementImagesContainer
} from '@/features/photoset/components/curation-photoset-space-images/style'
import {ImageCard} from '@/components/commons/image-card/ImageCard'
import {ExpandImageButton} from '@/components/commons/expand-image-button/ExpandImageButton'
import {ImageCardFlags} from '@/features/photoset/components/image-card-flags/ImageCardFlags'

interface CurationPhotosetSpaceImagesProps {
    images: Omit<Image, 'space'>[]
    spaceId: number
    spaceIndex: number
    onExpandImage: (spaceIndex: number, imageIndex: number) => void
    cbSetHideAllUnselected: () => void
}

export const CurationPhotosetSpaceImages: FC<CurationPhotosetSpaceImagesProps> = ({
    images,
    spaceId,
    spaceIndex,
    onExpandImage,
    cbSetHideAllUnselected
}) => {
    const {t} = useTranslation()
    const photosetSpaceImageSelectionToggle = usePhotosetStore(state => state.photosetSpaceImageSelectionToggle)

    return images.length == 0 ? (
        <StyledAllHiddenImages direction={'column'} gap={3} align={'center'}>
            <h3>{t('photoset:curation:space:no_images_title')}</h3>
            <p>{t('photoset:curation:space:hidden_images_des')}</p>
            <Button variant="secondary" onClick={() => cbSetHideAllUnselected()}>
                {t('photoset:curation:space:show_unselected')}
            </Button>
        </StyledAllHiddenImages>
    ) : (
        <StyledCurationElementImagesContainer>
            {images.map((image, index) => {
                return (
                    <ImageCard
                        image={image}
                        key={image.id}
                        thumbnail={image.thumbnails?.xs}
                        isSelected={image.is_final_select}
                        onSelect={() => photosetSpaceImageSelectionToggle(spaceIndex, image.id)}
                        endSlot={
                            <>
                                <ExpandImageButton onClick={() => onExpandImage(spaceIndex, index)} />
                                <ImageCardFlags
                                    image={image}
                                    photosetSpaceIds={[spaceId]}
                                    stepId={StepKey['curation']}
                                />
                            </>
                        }
                    />
                )
            })}
        </StyledCurationElementImagesContainer>
    )
}
