import axios from '@/axiosInstance.ts'
import rawAxios, {AxiosProgressEvent} from 'axios'
import {z} from 'zod'
import {
    Image,
    PhotosetImagesSearchParams,
    Metadata,
    Flag,
    PhotosetSpace,
    FlagsSearchParams,
    DeclineReason,
    Photoset,
    PhotosetSpaceFlag
} from '@/features/photoset/types.ts'
import {ResponseTypeParam} from '@/types.ts'

/*Get photoset*/
export const HttpGetPhotosetOptions = z.object({
    urlParams: z.object({
        id: z.coerce.number().int()
    }),
    params: ResponseTypeParam
})
export type HttpGetPhotosetOptions = z.infer<typeof HttpGetPhotosetOptions>

export const httpGetPhotoset = ({urlParams, params}: HttpGetPhotosetOptions) =>
    axios.get(`/luminate/photosets/${urlParams.id}`, {params})

/*Get photoset images*/
export const HttpGetPhotosetImagesOptions = z.object({
    urlParams: z.object({
        photosetId: Photoset.shape['id']
    }),
    params: PhotosetImagesSearchParams.and(ResponseTypeParam)
})
export type HttpGetPhotosetImagesOptions = z.infer<typeof HttpGetPhotosetImagesOptions>
export const HttpGetPhotosetImagesResponse = z.array(Image)
export type HttpGetPhotosetImagesResponse = z.infer<typeof HttpGetPhotosetImagesResponse>
export const httpGetPhotosetImages = ({urlParams, params}: HttpGetPhotosetImagesOptions) =>
    axios.get(`/luminate/photosets/${urlParams.photosetId}/get-images`, {params})

/*Get images metadata*/
export const HttpGetImagesMetadataOptions = z.object({
    urlParams: z.object({
        photosetId: Photoset.shape['id']
    })
})
export type HttpGetImagesMetadataOptions = z.infer<typeof HttpGetImagesMetadataOptions>

export const HttpGetImagesMetadataResponse = z.array(Metadata)
export type HttpGetImagesMetadataResponse = z.infer<typeof HttpGetImagesMetadataResponse>

export const httpGetImagesMetadata = ({urlParams}: HttpGetImagesMetadataOptions) =>
    axios.get(`/luminate/photosets/${urlParams.photosetId}/metadata-groups`)

/* Get flags list */
export const HttpGetFlagsOptions = z.object({
    params: FlagsSearchParams.and(ResponseTypeParam)
})
export type HttpGetFlagsOptions = z.infer<typeof HttpGetFlagsOptions>

export const HttpGetFlagsResponse = z.array(Flag)
export type HttpGetFlagsResponse = z.infer<typeof HttpGetFlagsResponse>
export const httpGetFlags = ({params}: HttpGetFlagsOptions) => axios.get(`/luminate/flags`, {params})

/* Get reasons decline */
export const HttpGetReasonsDeclineResponse = z.array(DeclineReason)
export type HttpGetReasonsDeclineResponse = z.infer<typeof HttpGetReasonsDeclineResponse>
export const httpGetReasonsDecline = () => axios.get(`/luminate/reasons-decline`)

/* Reject photoset */
export const HttpRejectPhotosetParams = z.object({
    urlParams: z.object({
        photosetId: Photoset.shape['id']
    }),
    params: z.object({
        reasonId: z.number()
    })
})
export type HttpRejectPhotosetParams = z.infer<typeof HttpRejectPhotosetParams>

export type HttpRejectPhotosetPayload = {
    photosetId: number
    reasonId: number
}

export const httpRejectPhotoset = ({urlParams, params}: HttpRejectPhotosetParams) =>
    axios.patch(`/luminate/photosets/${urlParams.photosetId}/reject`, {
        reason_decline_id: params.reasonId
    })

/* Update steps */
export const httpUpdateStepToInitialQA = (photosetId: number) =>
    axios.post(`/luminate/photosets/${photosetId}/update-step-initial-qa`)

export const httpUpdateStepToAllocation = (photosetId: number) =>
    axios.post(`/luminate/photosets/${photosetId}/update-step-allocation`)

export const httpUpdateStepToCuration = (photosetId: number) =>
    axios.post(`/luminate/photosets/${photosetId}/update-step-curation`)

export const httpUpdateStepToCompleted = ({photosetId, imagesIds}: {photosetId: number; imagesIds: number[]}) =>
    axios.post(`/luminate/photosets/${photosetId}/update-step-completed`, {
        image_ids: imagesIds
    })

export const httpUpdateStepToQaReviewFlags = (photosetId: number) =>
    axios.post(`/luminate/photosets/${photosetId}/update-step-flag-review`)

export const httpUpdateStepToQaReviewCompleted = (photosetId: number) =>
    axios.post(`/luminate/photosets/${photosetId}/update-step-review-completed`)

export const httpUpdateStepToAiManualRetouchingUpload = (photosetId: number) =>
    axios.post(`/luminate/photosets/${photosetId}/update-step-manually-retouched-upload`)

export const httpUpdateStepToAiManualRetouching = (photosetId: number) =>
    axios.post(`/luminate/photosets/${photosetId}/update-step-manual-retouching`)

export const httpUpdateStepToAiVetting = (photosetId: number) =>
    axios.post(`/luminate/photosets/${photosetId}/update-step-ai-vetting`)

export const httpUpdateStepToVettingQa = (photosetId: number) =>
    axios.post(`/luminate/photosets/${photosetId}/update-step-vetting-qa`)

export const httpUpdateStepToAiVettingCompleted = (photosetId: number) =>
    axios.post(`/luminate/photosets/${photosetId}/update-step-ai-vetting-completed`)

export const httpUpdateStepToRetouchingQa = (photosetId: number) =>
    axios.post(`/luminate/photosets/${photosetId}/update-step-retouching-qa`)

export const httpUpdateStepToRetouchingQaCompleted = (photosetId: number) =>
    axios.post(`/luminate/photosets/${photosetId}/update-step-retouching-qa-completed`)

export const httpUpdateStepToNeedsReview = (photosetId: number) =>
    axios.post(`/luminate/photosets/${photosetId}/update-step-needs-review`)

export const httpUpdateStepToRetouchingRevisionUpload = (photosetId: number) =>
    axios.post(`/luminate/photosets/${photosetId}/update-step-retouching-revision-upload`)

export const httpUpdateStepToRetouchingRevision = (photosetId: number) =>
    axios.post(`/luminate/photosets/${photosetId}/update-step-retouching-revision`)

export const httpUpdateStepToRetouchingApproved = (photosetId: number) =>
    axios.post(`/luminate/photosets/${photosetId}/update-step-retouching-approved`)

/* Add flag to images */
export const HttpAddImagesFlagOptions = z.object({
    urlParams: z.object({
        photosetId: Photoset.shape['id'],
        flagId: Flag.shape['id']
    }),
    payload: z.object({
        imageIds: z.array(Image.shape['id'])
    })
})
export type HttpAddImagesFlagOptions = z.infer<typeof HttpAddImagesFlagOptions>

export const httpAddImagesFlag = ({urlParams, payload}: HttpAddImagesFlagOptions) =>
    axios.patch(`/luminate/photosets/${urlParams.photosetId}/images/flags/${urlParams.flagId}`, {
        image_ids: payload.imageIds
    })

/* Remove flag to images */
export const HttpRemoveImagesFlag = z.object({
    urlParams: z.object({
        photosetId: Photoset.shape['id'],
        flagId: Flag.shape['id']
    }),
    params: z.object({
        imageIds: z.array(Image.shape['id'])
    })
})
export type HttpRemoveImagesFlag = z.infer<typeof HttpRemoveImagesFlag>

export const httpRemoveImagesFlag = ({urlParams, params}: HttpRemoveImagesFlag) =>
    axios.delete(`/luminate/photosets/${urlParams.photosetId}/images/flags/${urlParams.flagId}`, {
        params: {
            image_ids: params.imageIds
        }
    })

/* Get spaces */
export const HttpGetPhotosetSpacesOptions = z.object({
    urlParams: z.object({
        photosetId: z.number()
    }),
    params: PhotosetImagesSearchParams.omit({order_by: true, order_direction: true})
        .and(z.object({is_final_select: z.boolean().optional()}))
        .and(ResponseTypeParam)
})

export type HttpGetPhotosetSpacesOptions = z.infer<typeof HttpGetPhotosetSpacesOptions>

export const HttpGetPhotosetSpacesResponse = z.array(PhotosetSpace)
export type HttpGetPhotosetSpacesResponse = z.infer<typeof HttpGetPhotosetSpacesResponse>

export const httpGetPhotosetSpaces = (params: HttpGetPhotosetSpacesOptions) =>
    axios.get(`/luminate/photosets/${params.urlParams.photosetId}/spaces`, params)

export const HttpAllocateImagesOptions = z.object({
    urlParams: z.object({
        photosetId: z.number(),
        photosetSpaceId: z.number()
    }),
    payload: z.number().array()
})
export type HttpAllocateImagesOptions = z.infer<typeof HttpAllocateImagesOptions>

export const httpAllocateImages = ({urlParams, payload}: HttpAllocateImagesOptions) =>
    axios.patch(`/luminate/photosets/${urlParams.photosetId}/images/spaces/${urlParams.photosetSpaceId}`, {
        image_ids: payload
    })

export const HttpDeallocateImagesOptions = z.object({
    urlParams: z.object({
        photosetId: z.number(),
        photosetSpaceId: PhotosetSpace.shape['id']
    }),
    payload: z.number().array()
})
export type HttpDeallocateImagesOptions = z.infer<typeof HttpDeallocateImagesOptions>

export const httpDeallocateImages = ({urlParams, payload}: HttpDeallocateImagesOptions) =>
    axios.patch(`/luminate/photosets/${urlParams.photosetId}/images/spaces`, {
        image_ids: payload
    })

// Todo: this is a duplicate of httpGetPhotosetSpaces - please update code in the curation step
//Get images grouped after step 2 ( allocation ) completed
export const HttpGetPhotosetCurationImagesOptions = z.object({
    urlParams: z.object({
        photosetId: z.number()
    }),
    params: ResponseTypeParam
})
export type HttpGetPhotosetCurationImagesOptions = z.infer<typeof HttpGetPhotosetCurationImagesOptions>

export const HttpGetPhotosetCurationImagesResponse = z.array(PhotosetSpace)
export type HttpGetPhotosetCurationImagesResponse = z.infer<typeof HttpGetPhotosetCurationImagesResponse>

export const httpGetPhotosetSpacesConfig = (params: HttpGetPhotosetCurationImagesOptions) =>
    axios.get(`/luminate/photosets/${params.urlParams.photosetId}/spaces`, {...params})

/* Add flag to photoset space */
export const HttpAddPhotosetSpaceFlag = z.object({
    urlParams: z.object({
        photosetSpaceId: PhotosetSpace.shape['configuration_space_id']
    }),
    payload: z.object({
        flag: PhotosetSpaceFlag
    })
})
export type HttpAddPhotosetSpaceFlag = z.infer<typeof HttpAddPhotosetSpaceFlag>
export const httpAddPhotosetSpaceFlag = ({urlParams, payload}: HttpAddPhotosetSpaceFlag) =>
    axios.patch(`/luminate/photoset-spaces/${urlParams.photosetSpaceId}`, {
        flag_key_name: payload.flag
    })

/* download retouched log */
export const httpLogRetouchedZipDownload = (photosetId: number) =>
    axios.patch(`/luminate/photosets/${photosetId}/retouched-downloaded`)

/**
 * httpGetNeedsReviewSpaces
 */
export const HttpImageSelectionApprovalParams = z.object({
    urlParams: z.object({
        photosetId: Photoset.shape['id'],
        imageId: Image.shape['id']
    }),
    payload: z.object({
        curation_approved: z.boolean().optional(),
        need_manual_retouching: z.boolean().optional(),
        vetting_qa_approved: z.boolean().optional(),
        needs_to_review: z.boolean().optional()
    })
})
export type HttpImageSelectionApprovalParams = z.infer<typeof HttpImageSelectionApprovalParams>

export const httpImageSelectionApproval = ({urlParams, payload}: HttpImageSelectionApprovalParams) =>
    axios.patch(
        `/luminate/photosets/${urlParams.photosetId}/images/${urlParams.imageId}?response_type=extended`,
        payload
    )

/**
 * httpGetNeedManualRetouchingSpaces
 * Get manually retouched spaces with "manual_retouching" flag on the images
 */
export const HttpGetNeedManualRetouchingSpacesResponse = z.array(PhotosetSpace)
export type HttpGetNeedManualRetouchingSpacesResponse = z.infer<typeof HttpGetNeedManualRetouchingSpacesResponse>

export const httpGetNeedManualRetouchingSpaces = (params: HttpGetPhotosetSpacesOptions) =>
    axios.get(`/luminate/photosets/${params.urlParams.photosetId}/spaces/need-manual-retouching`, {...params})

/**
 * httpDeleteManualRetouchedImages
 * Delete all the manual retouched images uploaded on a specific photoset
 */
export const HttpDeleteManualRetouchedImagesOptions = z.object({
    urlParams: z.object({
        photosetId: z.number()
    })
})
export type HttpDeleteManualRetouchedImagesOptions = z.infer<typeof HttpDeleteManualRetouchedImagesOptions>

export const httpDeleteManualRetouchedImages = ({urlParams}: HttpDeleteManualRetouchedImagesOptions) =>
    axios.delete(`/luminate/photosets/${urlParams.photosetId}/images/uploaded`)

/**
 * httpBulkUploadPhotos
 * Bulk Upload photos to get presigned url
 */
export type HttpBulkUploadPhotosOptions = {
    urlParams: {photosetId: number}
    payload: {
        medias: string[]
    }
}

export const httpBulkUploadPhotos = ({urlParams, payload}: HttpBulkUploadPhotosOptions) =>
    axios.post(`/luminate/photosets/${urlParams.photosetId}/images/bulk/upload`, payload)

/**
 * httpUploadPhoto
 * Confirm and upload the photo after get the presigned url
 */
export type HttpUploadPhotoOptions = {
    onUploadProgress: (progressEvent: AxiosProgressEvent) => void | undefined
    payload: {
        presignedUrl: string
        file: File
    }
}

export const httpUploadPhoto = ({onUploadProgress, payload}: HttpUploadPhotoOptions) =>
    rawAxios.put(payload.presignedUrl, payload.file, {
        onUploadProgress: progressEvent => {
            if (!onUploadProgress) return
            onUploadProgress(progressEvent)
        }
    })

/**
 * httpUploadPhoto
 * Confirm and upload the photo after get the presigned url
 */
export type HttpUploadPhotoConfirm = {
    urlParams: {photosetId: number; imageId: number}
}

export const httpUploadPhotoConfirm = ({urlParams}: HttpUploadPhotoConfirm) =>
    axios.patch(`/luminate/photosets/${urlParams.photosetId}/images/${urlParams.imageId}/upload-completed`)

/**
 * httpAddPhotosetSpaceComment
 * Add photoset space comment
 */

export const HttpAddPhotosetSpaceCommentOptions = z.object({
    urlParams: z.object({
        photosetSpaceId: z.number()
    }),
    payload: z.object({
        comment: z.string()
    })
})
export type HttpAddPhotosetSpaceCommentOptions = z.infer<typeof HttpAddPhotosetSpaceCommentOptions>

export const httpAddPhotosetSpaceComment = ({urlParams, payload}: HttpAddPhotosetSpaceCommentOptions) =>
    axios.patch(`/luminate/photoset-spaces/${urlParams.photosetSpaceId}`, payload)

/**
 * httpAddPhotosetComment
 * Add photoset comment
 */

export const HttpAddPhotosetCommentOptions = z.object({
    urlParams: z.object({
        photosetId: z.number()
    }),
    payload: z.object({
        comment: z.string()
    })
})
export type HttpAddPhotosetCommentOptions = z.infer<typeof HttpAddPhotosetCommentOptions>

export const httpAddPhotosetComment = ({urlParams, payload}: HttpAddPhotosetCommentOptions) =>
    axios.patch(`/luminate/photosets/${urlParams.photosetId}`, payload)
