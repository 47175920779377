import {z} from 'zod'

export const SET_PASSWORD_FORM_MODEL = {
    password: {
        name: 'password',
        label: 'setPassword:form:password',
        placeholder: 'setPassword:form:password',
        defaultValue: '',
        helpText: ''
    },
    confirm_password: {
        name: 'confirm_password',
        label: 'setPassword:form:confirm_password',
        placeholder: 'setPassword:form:confirm_password',
        defaultValue: '',
        helpText: ''
    }
} as const

export const SetPasswordFormSchema = z
    .object({
        password: z.string().min(8, {message: 'errors:password'}),
        confirm_password: z.string().min(1, {message: 'errors:password'})
    })
    .refine(data => data.password === data.confirm_password, {
        path: ['confirm_password'],
        message: 'errors:password_match'
    })

export type SetPasswordValidationSchema = z.infer<typeof SetPasswordFormSchema>
