import {Image} from '@/features/photoset/types.ts'
import {useHotkeys} from 'react-hotkeys-hook'
import {Dispatch, SetStateAction} from 'react'
import {usePhotosetStore} from '@/features/photoset/store/store'

type useGalleryCurationShortcuts = {
    currentImage: Omit<Image, 'space'>
    selectableImages: Omit<Image, 'space'>[]
    currentDetailImageIndex: number
    setCurrentDetailImageIndex: Dispatch<SetStateAction<number | null>>
    onClose: () => void
    currentSpaceIndex: number | null
}

export const useGalleryCurationShortcuts = (args: useGalleryCurationShortcuts) => {
    const {currentDetailImageIndex, setCurrentDetailImageIndex, selectableImages, currentImage, onClose} = args
    const toggleSelectImage = usePhotosetStore(state => state.toggleSelectImage)
    const photosetSpaceImageSelectionToggle = usePhotosetStore(state => state.photosetSpaceImageSelectionToggle)

    useHotkeys(
        'ArrowLeft',
        () => {
            setCurrentDetailImageIndex(currentDetailImageIndex - 1)
        },
        {enabled: currentDetailImageIndex >= 1},
        [currentDetailImageIndex]
    )

    useHotkeys(
        'ArrowRight',
        () => {
            setCurrentDetailImageIndex(currentDetailImageIndex + 1)
        },
        {enabled: currentDetailImageIndex < selectableImages.length - 1},
        [currentDetailImageIndex]
    )

    useHotkeys('Esc', onClose)

    useHotkeys('Space', () =>
        args.currentSpaceIndex == null || args.currentSpaceIndex < 0
            ? toggleSelectImage(currentImage.id)
            : photosetSpaceImageSelectionToggle(args.currentSpaceIndex, currentImage.id)
    )
}
