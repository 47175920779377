import {Component, ReactNode} from 'react'
import {ErrorBox} from '@components/commons/error-box/ErrorBox.tsx'
import {StyleSheetManager, ThemeProvider} from 'styled-components'
import {getAppTheme, GlobalStyles} from '@/theme/index.ts'
import {Button} from '@components/ui/button/Button.tsx'
import i18next from 'i18next'
import {AlertCircleIcon} from '@components/ui/icon/Icon.tsx'

interface ErrorBoundaryProps {
    children: ReactNode
}

interface ErrorBoundaryState {
    hasError: boolean
}

export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props)
        this.state = {hasError: false}
    }

    static getDerivedStateFromError() {
        return {hasError: true}
    }

    render() {
        if (this.state.hasError) {
            return (
                <ThemeProvider theme={getAppTheme({name: 'light'})}>
                    <StyleSheetManager>
                        <>
                            <GlobalStyles />
                            <ErrorBox
                                cta={
                                    <Button variant="primary" onClick={() => window.location.reload()}>
                                        {i18next.t('commons:reload_the_page')}
                                    </Button>
                                }
                                icon={<AlertCircleIcon />}
                                title={i18next.t('errors:default')}
                            />
                        </>
                    </StyleSheetManager>
                </ThemeProvider>
            )
        }

        return this.props.children
    }
}
