import {Button} from '@/components/ui/button/Button'
import {Popover, PopoverDescription} from '@ariakit/react'
import styled, {css} from 'styled-components'

export const StyledPhotographerNotesPopover = styled(Popover)(
    ({theme: {palette, shadows, zIndex}}) => css`
        z-index: ${zIndex.modal};
        background: ${palette.neutral.white};
        border: 1px solid ${palette.neutral[200]};
        box-shadow: ${shadows.md};
        border-radius: 8px;
        width: 400px;
        height: 180px;
        white-space: pre-line;
        overflow: hidden;
    `
)

export const StyledPhotographerNotesButton = styled(Button)(
    ({theme: {palette, spacing}}) => css`
        padding: 0 ${spacing * 3}px;
        &[aria-expanded='true'] {
            border: 1px solid ${palette.primary[300]};
            box-shadow: 0 0 0 4px ${palette.primary[200]};
        }
    `
)

export const StyledPhotographerNotesPopoverDescription = styled(PopoverDescription)(
    ({theme: {palette, spacing}}) => css`
        color: ${palette.neutral[900]};
        padding: ${spacing * 4}px;
    `
)
