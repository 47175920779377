import {ImageCard} from '@/components/commons/image-card/ImageCard'
import {SpaceImagesContainer} from '../space-images-atoms/SpaceImagesAtoms'
import {PhotosetSpace} from '@/features/photoset/types'
import {Image01Icon, Stars01Icon} from '@/components/ui/icon/Icon'
import {Chip} from '@/components/commons/chip/Chip'
import {useTranslation} from 'react-i18next'

export const RetouchingRevisionSpaceImages = ({
    images,
    isLoadingManualRetouchingSpaces
}: {
    images: PhotosetSpace['images']
    isLoadingManualRetouchingSpaces: boolean
}) => {
    const {t} = useTranslation()

    return (
        <SpaceImagesContainer $gap={4}>
            {images.map(image => {
                const thumbnail =
                    image.revised_thumbnails?.xs || image.retouched_thumbnails?.xs || image.ai_retouched_thumbnails?.xs
                return (
                    <ImageCard
                        key={image.id}
                        image={image}
                        isSelected={false}
                        hideCheckbox={true}
                        isLoading={false}
                        loadingText={t('commons:generating_thumbnails')}
                        thumbnail={thumbnail}
                        imageNameIcon={
                            !image.is_manually_retouched ? <Stars01Icon size={20} /> : <Image01Icon size={20} />
                        }
                        badgeType="to_revise"
                        showBadge={!!(image.revised_thumbnails?.xs && image.needs_to_review)}
                        endSlot={
                            !isLoadingManualRetouchingSpaces && (
                                <>
                                    {image.is_revised && (
                                        <Chip
                                            label={t(`photoset:retouching_revision:retouching_revised`)}
                                            isActive={true}
                                            variant={'info'}
                                        />
                                    )}

                                    {image.needs_to_review && !image.is_revised && (
                                        <Chip
                                            label={t(`photoset:retouching_revision:not_revised`)}
                                            isActive={true}
                                            variant={'danger'}
                                        />
                                    )}
                                </>
                            )
                        }
                    />
                )
            })}
        </SpaceImagesContainer>
    )
}
