import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import styled, {css} from 'styled-components'

export const StyledGalleryFlags = styled(Flexbox)(
    ({theme: {typography, palette, spacing}}) => css`
        width: 100%;
        & h3 {
            ${typography.textLg};
            font-weight: 500;
            color: ${palette.neutral['900']};
            padding: ${spacing * 5}px ${spacing * 6}px;
        }
    `
)

export const StyledGalleryFlagsTitle = styled(Flexbox)`
    width: 100%;
`

export const StyledGalleryFlagsContent = styled(Flexbox)(
    ({theme: {spacing}}) => css`
        padding: 0 ${spacing * 6}px ${spacing * 7.5}px ${spacing * 6}px;
        flex-wrap: wrap;
        width: 100%;
    `
)
