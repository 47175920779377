import {CSSProperties} from 'react'
import {spacing} from '@/theme'

export const toastBarStyle = {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: spacing,
    padding: spacing * 2
} as const satisfies CSSProperties
