import {useMemo, useState} from 'react'
import {usePhotosetStore} from '@/features/photoset/store/store'

export const useExpandPhotosetImage = () => {
    const [currentDetailSpaceIndex, setCurrentDetailSpaceIndex] = useState<number | null>(null)
    const [currentDetailImageIndex, setCurrentDetailImageIndex] = useState<number | null>(null)
    const photosetSpaces = usePhotosetStore(state => state.photosetSpaces)

    const onExpandImage = (photosetSpaceIndex: number | null, imageIndex: number) => {
        setCurrentDetailImageIndex(imageIndex)
        setCurrentDetailSpaceIndex(photosetSpaceIndex)
    }

    const onReduceImage = () => {
        setCurrentDetailImageIndex(null)
    }

    const currentDetailSpace = useMemo(
        () => (currentDetailSpaceIndex !== null ? photosetSpaces[currentDetailSpaceIndex] : null),
        [photosetSpaces, currentDetailSpaceIndex]
    )

    return {
        currentDetailImageIndex,
        currentDetailSpaceIndex,
        currentDetailSpace,
        onExpandImage,
        onReduceImage,
        setCurrentDetailImageIndex,
        setCurrentDetailSpaceIndex
    }
}
