import {Link} from 'react-router-dom'
import styled, {css} from 'styled-components'

export const StyledPhotosetUnavailable2LaLink = styled(Link)(
    ({theme: {palette, spacing}}) => css`
        color: ${palette.neutral[900]};
        text-decoration: underline;
        cursor: pointer;
        text-underline-offset: ${spacing}px;
    `
)
