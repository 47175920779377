import {ConfirmActionModal} from '@/components/commons/confirm-action-modal/ConfirmActionModal'
import {QUERY_KEYS, queryClient} from '@/queryClient'
import {errorHandler} from '@/utilities/helpers'
import {useTranslation} from 'react-i18next'
import {Photoset} from '@/features/photoset/types'
import {useUpdateStepToRetouchingQa} from '@/features/photoset/services/useUpdateStepToRetouchingQa'

export const VettingQaConfirmationModal = ({
    photosetId,
    onClose
}: {
    photosetId: Photoset['id']
    onClose: () => void
}) => {
    const {t} = useTranslation()

    const updateStepToAiManualRetouchingMutation = useUpdateStepToRetouchingQa({
        onError: errorHandler,
        onSuccess: () => {
            void queryClient.invalidateQueries({queryKey: [QUERY_KEYS.PHOTOSET]})
            void queryClient.invalidateQueries({queryKey: [QUERY_KEYS.PHOTOSET_SPACES]})
        }
    })

    return (
        <ConfirmActionModal
            isLoading={updateStepToAiManualRetouchingMutation.isPending}
            onClose={onClose}
            onConfirm={() => updateStepToAiManualRetouchingMutation.mutate(photosetId)}
            headerTitle=""
            title={t('photoset:vetting_qa:confirmation_modal_title')}
            paragraph={t('photoset:vetting_qa:confirmation_modal_paragraph')}
        />
    )
}
