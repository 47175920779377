import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import styled, {css} from 'styled-components'

export const StyledShootListMetadataSidebar = styled.div`
    width: 400px;
    display: grid;
    grid-template-rows: auto minmax(0, 1fr);
    grid-template-columns: minmax(0, 1fr);
    overflow: hidden;
`

export const StyledMetadataFiltersContainer = styled.div(
    ({theme: {spacing}}) => css`
        display: grid;
        grid-template-columns: minmax(0, 1fr);
        padding: ${spacing * 6}px ${spacing * 3}px;
    `
)

export const StyledMetadataFiltersRow = styled(Flexbox)(
    ({theme: {spacing, typography, palette}}) => css`
        ${typography.textXs};
        padding: ${spacing * 2}px ${spacing * 3}px;
        border-bottom: 1px solid ${palette.neutral['200']};

        &:last-child {
            border: none;
        }
    `
)

export const StyledMetadataRowTitle = styled.div`
    min-width: 110px;
`

export const StyledMetadataFiltersList = styled(Flexbox)`
    flex-wrap: wrap;
    overflow: hidden;
`

export const StyledMetadataImagesCount = styled.div<{$isActive: boolean}>(
    ({theme: {palette, spacing}, $isActive: isActive}) => css`
        padding: 0 ${spacing}px;
        border-radius: ${spacing * 0.5}px;
        background-color: ${isActive ? palette.success[100] : palette.neutral[100]};
    `
)
