import {useTranslation} from 'react-i18next'
import {PageHero} from '@components/commons/page-hero/PageHero.tsx'
import {PageFooter} from '@components/commons/page-footer/PageFooter.tsx'
import {useOutletContext} from 'react-router-dom'
import {PhotosetURLParams} from '@/features/photoset/types.ts'
import {useEffect, useMemo, useState} from 'react'
import {usePhotosetStore} from '@/features/photoset/store/store.ts'
import {Button} from '@components/ui/button/Button.tsx'
import {CheckIcon} from '@components/ui/icon/Icon.tsx'
import {PhotosetSpaceElement} from '@/features/photoset/components/photoset-space-element/PhotosetSpaceElement'
import {
    PhotosetStepBodyContainer,
    PhotosetStepWrapper
} from '@/features/photoset/components/photoset-step-atoms/PhotosetStepAtoms'
import {AiVettingSelectionSpaceImages} from '@/features/photoset/components/ai-vetting-selection-space-images/AiVettingSelectionSpaceImages'
import {GalleryAiVettingModal} from '@/features/photoset/components/gallery-photoset-modal/gallery-ai-vetting-modal/GalleryAiVettingModal'
import {useExpandPhotosetImage} from '@/features/photoset/hooks/useExpandPhotosetImage'
import {AiVettingConfirmModal} from '@/features/photoset/components/ai-vetting-confirm-modal/AiVettingConfirmModal'
import {AiVettingConfirmManualRetouchingModal} from '@/features/photoset/components/ai-vetting-confirm-manual-retouching-modal/AiVettingConfirmManualRetouchingModal'
import {useGetPhotosetSpaces} from '@/features/photoset/services/useGetPhotosetSpaces'
import {GET_PHOTOSET_IMAGES_LIST_RESPONSE_TYPE} from '@/features/photoset/services/useGetPhotosetImagesList'
import {useMutationState} from '@tanstack/react-query'
import {MUTATION_KEYS} from '@/queryClient'
import {useGetPhotoset} from '@/features/photoset/services/useGetPhotoset'
import {PhotosetGenerating} from '@/features/photoset/components/photoset-generating/PhotosetLoading'
import {StepHeroControls} from '@/features/photoset/components/step-hero-controls/StepHeroControls'

export const PhotosetAiVettingStep = () => {
    const {t} = useTranslation()
    const {urlParams} = useOutletContext<{urlParams: PhotosetURLParams}>()
    const {data: photoset} = useGetPhotoset({id: urlParams.id}, {enabled: false})
    const {
        data: spaces,
        isFetching,
        isError
    } = useGetPhotosetSpaces({
        urlParams: {
            photosetId: urlParams.id
        },
        params: {
            is_final_select: true,
            response_type: GET_PHOTOSET_IMAGES_LIST_RESPONSE_TYPE
        }
    })

    //photoset store
    const photosetSpaces = usePhotosetStore(state => state.photosetSpaces)
    const initPhotosetSpaces = usePhotosetStore(state => state.initPhotosetSpaces)

    //statuses
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)

    // Init photoset store
    useEffect(() => {
        if (spaces) {
            initPhotosetSpaces(spaces)
        }
    }, [spaces])

    // Handle gallery modal view
    const {
        currentDetailImageIndex,
        setCurrentDetailImageIndex,
        currentDetailSpaceIndex,
        currentDetailSpace,
        onExpandImage,
        onReduceImage
    } = useExpandPhotosetImage()

    // Get selected image
    const needManualRetouchingImages = useMemo(() => {
        return photosetSpaces.flatMap(space => space.images.filter(image => image.need_manual_retouching))
    }, [photosetSpaces])

    // Retrive update status
    const updateStepToAiVettingCompletedStatus = useMutationState({
        filters: {mutationKey: [MUTATION_KEYS.UPDATE_STEP_AI_VETTING_COMPLETED]},
        select: mutation => mutation.state.status
    })

    const updateStepToManualRetouchingStatus = useMutationState({
        filters: {mutationKey: [MUTATION_KEYS.UPDATE_STEP_AI_MANUAL_RETOUCHING]},
        select: mutation => mutation.state.status
    })

    const isUpdateStepPending = useMemo(
        () =>
            updateStepToAiVettingCompletedStatus.includes('pending') ||
            updateStepToManualRetouchingStatus.includes('pending'),
        [updateStepToAiVettingCompletedStatus, updateStepToManualRetouchingStatus]
    )

    if (!photoset?.ai_retouched_thumbnail_generated_at && !isFetching) {
        return (
            <PhotosetGenerating
                title={t('photoset:imagen_ai:in_progress:title')}
                subtitle={t('photoset:imagen_ai:in_progress:paragraph')}
            />
        )
    }

    return (
        <>
            <PageHero
                title={t('photoset:ai_vetting:title')}
                subtitle={t('photoset:ai_vetting:subtitle')}
                endSlot={<StepHeroControls hideSortControls />}
            />

            {showConfirmationModal &&
                (needManualRetouchingImages.length > 0 ? (
                    <AiVettingConfirmManualRetouchingModal
                        photosetId={urlParams.id}
                        needManualRetouchingImages={needManualRetouchingImages}
                        onClose={() => setShowConfirmationModal(false)}
                    />
                ) : (
                    <AiVettingConfirmModal photosetId={urlParams.id} onClose={() => setShowConfirmationModal(false)} />
                ))}

            <PhotosetStepWrapper>
                <PhotosetStepBodyContainer fullWidth $disabled={isUpdateStepPending}>
                    {isFetching && [0, 1, 2].map(e => <PhotosetSpaceElement asSkeleton key={`${e}-id`} />)}
                    {!isFetching &&
                        !isError &&
                        photosetSpaces?.map(
                            (photosetSpace, spaceIndex) =>
                                photosetSpace.images.length > 0 && (
                                    <PhotosetSpaceElement
                                        key={photosetSpace.id}
                                        spaceName={photosetSpace.space.key_name}
                                        endSlot={
                                            <AiVettingSelectionSpaceImages
                                                images={photosetSpace.images}
                                                photosetId={urlParams.id}
                                                photosetSpaceId={photosetSpace.id}
                                                spaceIndex={spaceIndex}
                                                onExpandImage={onExpandImage}
                                            />
                                        }
                                    />
                                )
                        )}
                </PhotosetStepBodyContainer>

                <PageFooter
                    endSlot={
                        needManualRetouchingImages.length > 0 ? (
                            <Button onClick={() => setShowConfirmationModal(!showConfirmationModal)} variant="primary">
                                {t('photoset:ai_vetting:manual_retouching_cta')}
                            </Button>
                        ) : (
                            <Button
                                disabled={isUpdateStepPending}
                                onClick={() => setShowConfirmationModal(!showConfirmationModal)}
                                variant="primarySuccess"
                            >
                                {t('commons:done')}
                                <CheckIcon />
                            </Button>
                        )
                    }
                />
            </PhotosetStepWrapper>

            {currentDetailImageIndex != null && currentDetailSpaceIndex != null && currentDetailSpace && (
                <GalleryAiVettingModal
                    onClose={onReduceImage}
                    currentDetailImageIndex={currentDetailImageIndex}
                    setCurrentDetailImageIndex={setCurrentDetailImageIndex}
                    currentDetailSpaceIndex={currentDetailSpaceIndex}
                    selectableImages={currentDetailSpace.images}
                    currentSpaceId={currentDetailSpace.id}
                    currentSpaceName={currentDetailSpace.space.key_name}
                />
            )}
        </>
    )
}

PhotosetAiVettingStep.displayName = 'PhotosetAiVettingStep'
