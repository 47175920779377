import styled, {css} from 'styled-components'
import {ImageCard} from '@components/commons/image-card/ImageCard.tsx'
import {Button} from '@/components/ui/button/Button'

export const StyledImagesPool = styled.div`
    display: grid;
    grid-template-rows: auto minmax(0, 1fr);
    grid-template-columns: minmax(0, 1fr);
`

export const StyledImagesSelectedLabel = styled.h4(
    ({theme: {typography, palette}}) => css`
        ${typography.textSm};
        color: ${palette.neutral['700']};
    `
)

export const StyledImageCard = styled(ImageCard)<{$isDragging?: boolean}>(
    ({theme: {palette, shadows, spacing}, $isDragging}) => css`
        position: relative;
        &:not(:hover) {
            & .show-on-hover {
                display: none;
            }
        }

        ${$isDragging &&
        css`
            cursor: grabbing;
            padding: ${spacing * 4}px;
            width: 200px;
            background: ${palette.neutral.white}99;
            backdrop-filter: blur(4px);
            border: 1px solid ${palette.neutral.white}99;
            border-radius: 12px;
            box-shadow: ${shadows.xl};
        `}
    `
)

export const StyledCounterBadge = styled.div(
    ({theme: {palette}}) => css`
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(50%, -50%);
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background-color: ${palette.blue['600']};
        color: ${palette.neutral.white};
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
    `
)

export const StyledOpenImageButton = styled(Button)(
    ({theme: {spacing}}) => css`
        position: absolute;
        top: ${spacing * 8}px;
        right: ${spacing * 8}px;
    `
)

export const StyledDragIcon = styled.button(
    ({theme: {spacing, palette}}) => css`
        position: absolute;
        top: ${spacing * 8}px;
        left: ${spacing * 8}px;
        cursor: grab;
        color: ${palette.neutral[300]};
    `
)
