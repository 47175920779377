import React from 'react'
import {Button} from '@components/ui/button/Button.tsx'
import {Container} from '@components/ui/container/Container.tsx'
import {StyledSetPasswordBox, StyledSetPasswordBoxForm, StyledSetPasswordWrapper} from '@pages/set-password/style.ts'
import {useTranslation} from 'react-i18next'
import {Helmet} from 'react-helmet'
import {SubmitHandler, useForm} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import {
    SET_PASSWORD_FORM_MODEL,
    SetPasswordFormSchema,
    SetPasswordValidationSchema
} from '@pages/set-password/SetPasswordFormModel.ts'
import {InputText} from '@components/commons/input-text/InputText.tsx'
import {AirbnbIcon} from '@components/ui/icon/Icon.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import toast from 'react-hot-toast'

import {useNavigate, useParams} from 'react-router-dom'
import {errorHandler} from '@utilities/helpers.ts'
import {routes} from '@utilities/constants/routes.tsx'
import {Spinner} from '@components/ui/spinner/Spinner.tsx'
import {useSetPassword} from '@/features/auth/services/queries/useSetPassword.ts'

export const SetPassword: React.FC = () => {
    const {t} = useTranslation()
    const {token} = useParams()
    const navigate = useNavigate()
    const {
        register,
        handleSubmit,
        formState: {errors, touchedFields}
    } = useForm<SetPasswordValidationSchema>({
        mode: 'onBlur',
        resolver: zodResolver(SetPasswordFormSchema)
    })

    //reset password mutation
    const {mutate: resetPassword, isPending} = useSetPassword({
        onError: e => errorHandler(e), //toast.error(t(e.message)),
        onSuccess: () => {
            navigate(routes.LOGIN.path)
            toast.success(t('setPassword:success_message'))
        }
    })

    const onSubmit: SubmitHandler<SetPasswordValidationSchema> = data => {
        resetPassword({password: data.password, token: token ?? ''})
    }

    return (
        <Container>
            <Helmet title={t('setPassword:page_title_meta')} />
            <StyledSetPasswordWrapper align={'center'} justify={'center'}>
                <StyledSetPasswordBox direction={'column'} align={'stretch'}>
                    <Flexbox justify={'center'}>
                        <AirbnbIcon size={40} fill={'#E31B54'} />
                    </Flexbox>
                    <h4>{t('setPassword:title')}</h4>
                    <p>{t('setPassword:subtitle')}</p>
                    <StyledSetPasswordBoxForm onSubmit={handleSubmit(onSubmit)}>
                        <Flexbox direction={'column'} gap={4}>
                            <InputText
                                autoComplete={'new-password'}
                                label={t(SET_PASSWORD_FORM_MODEL.password.label)}
                                type={'password'}
                                visibilityToggle
                                touched={touchedFields.password}
                                errorMessage={t(errors.password?.message || '')}
                                placeholder={t(SET_PASSWORD_FORM_MODEL.password.placeholder)}
                                {...register(t(SET_PASSWORD_FORM_MODEL.password.name))}
                                helpText={t(SET_PASSWORD_FORM_MODEL.password.helpText)}
                            />
                            <InputText
                                autoComplete={'repeat new-password'}
                                label={t(SET_PASSWORD_FORM_MODEL.confirm_password.label)}
                                type={'password'}
                                visibilityToggle
                                touched={touchedFields.confirm_password}
                                errorMessage={t(errors.confirm_password?.message || '')}
                                placeholder={t(SET_PASSWORD_FORM_MODEL.confirm_password.placeholder)}
                                {...register(t(SET_PASSWORD_FORM_MODEL.confirm_password.name))}
                                helpText={t(SET_PASSWORD_FORM_MODEL.confirm_password.helpText)}
                            />
                        </Flexbox>

                        <Flexbox direction={'column'} gap={2} align={'stretch'}>
                            <Button variant={'primary'} size={'md'} type={'submit'} disabled={isPending}>
                                {t('setPassword:primary_cta')}
                                {isPending && <Spinner size={16} />}
                            </Button>
                        </Flexbox>
                    </StyledSetPasswordBoxForm>
                </StyledSetPasswordBox>
            </StyledSetPasswordWrapper>
        </Container>
    )
}

SetPassword.displayName = 'SetPassword'
