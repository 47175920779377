import {IconContainer} from '@components/ui/icon-container/IconContainer.tsx'
import {FC} from 'react'
import {DefaultNamespace} from 'i18next'
import {
    StyledSubHeaderBannerBody,
    StyledSubHeaderBannerText,
    StyledSubHeaderBannerWrapper
} from '@/features/photoset/components/subheader-banner/style.ts'
import {Container} from '@components/ui/container/Container.tsx'
import {AlertCircleIcon, CheckIcon} from '@components/ui/icon/Icon.tsx'

interface SubHeaderBannerProps {
    title: string | DefaultNamespace
    subtitle?: string | DefaultNamespace
    type?: 'success' | 'danger' | 'warning' | 'blue'
}

export const SubHeaderBanner: FC<SubHeaderBannerProps> = ({subtitle, title, type = 'danger'}) => {
    return (
        <StyledSubHeaderBannerWrapper type={type}>
            <Container fullWidth>
                <StyledSubHeaderBannerBody align={'center'} gap={3}>
                    <IconContainer type={type}>{type == 'success' ? <CheckIcon /> : <AlertCircleIcon />}</IconContainer>
                    <StyledSubHeaderBannerText direction="column" type={type}>
                        <h3>{title}</h3>
                        <p>{subtitle}</p>
                    </StyledSubHeaderBannerText>
                </StyledSubHeaderBannerBody>
            </Container>
        </StyledSubHeaderBannerWrapper>
    )
}

SubHeaderBanner.displayName = 'SubHeaderBanner'
