import {Button} from '@/components/ui/button/Button'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import styled, {css} from 'styled-components'

export const StyledImagesSortControls = styled(Flexbox)`
    position: relative;
`

export const StyledSelectPermanentLabel = styled.span(
    ({theme: {palette}}) => css`
        font-weight: 200;
        color: ${palette.neutral['600']};
    `
)

export const StyledSelectTriggerButton = styled(Button)<{$minWidth?: number; $isPointer?: boolean}>(
    ({theme: {palette, spacing}, $minWidth: minWidth, $isPointer: isPointer = true}) => css`
        border: 1px solid transparent;
        justify-content: space-between;
        min-width: ${minWidth ?? 0}px;
        padding: 0 ${spacing * 3}px;
        cursor: ${isPointer ? 'pointer' : 'auto'};

        &:not(:disabled):hover {
            background: transparent;
        }

        svg {
            fill: ${palette.neutral[500]};
            transition: ease-in-out 200ms;
        }

        &[aria-expanded='true'] {
            border: 1px solid ${palette.primary[300]};
            box-shadow: 0 0 0 4px ${palette.primary[200]};

            svg {
                transform: rotate(180deg);
            }
        }
    `
)
