import {useDraggable, useDroppable} from '@dnd-kit/core'
import {HTMLAttributes, PointerEvent, ReactNode, useEffect, useState} from 'react'
import {useTheme} from 'styled-components'

export const Draggable = ({
    id,
    children,
    draggingElement,
    ...props
}: Omit<HTMLAttributes<HTMLDivElement>, 'children'> & {
    id: string
    children: (draggable: ReturnType<typeof useDraggable>, onPointerDown: (event: PointerEvent) => void) => ReactNode
    draggingElement: ReactNode
}) => {
    const theme = useTheme()
    const draggable = useDraggable({id, attributes: {tabIndex: -1}})
    const [initialDraggingPosition, setInitialDraggingPosition] = useState<[number, number]>([0, 0])
    const [cursorPosition, setCursorPosition] = useState<[number, number]>([0, 0])

    useEffect(() => {
        window.addEventListener('mousemove', event => setCursorPosition([event.clientX, event.clientY]))
    }, [])

    const onPointerDown = (event: PointerEvent) => {
        draggable.listeners?.onPointerDown(event)
        setInitialDraggingPosition([event.clientX, event.clientY])
    }

    return (
        <div ref={draggable.setNodeRef} {...draggable.attributes} {...props}>
            {children(draggable, onPointerDown)}
            {draggable.isDragging && (
                <div
                    style={{
                        left: initialDraggingPosition[0] - 8,
                        top: initialDraggingPosition[1] - 8,
                        transform: `translate(${cursorPosition[0] - initialDraggingPosition[0]}px, ${cursorPosition[1] - initialDraggingPosition[1]}px)`,
                        position: 'fixed',
                        zIndex: theme.zIndex.draggingElement
                    }}
                >
                    {draggingElement}
                </div>
            )}
        </div>
    )
}
Draggable.displayName = 'Draggable'

export const Droppable = ({
    children,
    id
}: {
    children: (droppable: ReturnType<typeof useDroppable>) => ReactNode
    id: string
}) => {
    const droppable = useDroppable({id})
    return children(droppable)
}
Droppable.displayName = 'Droppable'
