import {
    StyledImagePreviewSlot,
    StyledImagePreviewSlotOverlay,
    StyledImagePreviewSlowPlaceholder,
    StyledShortcutLabel,
    StyledSpaceCardHeader,
    StyledSpaceCardImagesPreviewGrid,
    StyledSpaceCardWrapper
} from '@/features/photoset/components/space-card-atoms/style.ts'
import {forwardRef, HTMLAttributes, ReactNode} from 'react'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {ArrowRightIcon, ImageXIcon} from '@components/ui/icon/Icon.tsx'
import {shortcutToLabelTuple} from '@/features/photoset/utils.ts'
import {useTranslation} from 'react-i18next'
import {Image} from '@/features/photoset/types.ts'

type SpaceCardWrapperProps = HTMLAttributes<HTMLDivElement> & {
    hasImages: boolean
}
export const SpaceCardWrapper = ({hasImages, ...props}: SpaceCardWrapperProps) => (
    <StyledSpaceCardWrapper forwardedAs="div" direction="column" gap={3} $hasImages={hasImages} {...props} />
)

type SpaceCardHeaderProps = HTMLAttributes<HTMLDivElement> & {
    headerTitle: string
    images: Image[]
    openSpaceDetailModal?: () => void
    shortcut: string
}
export const SpaceCardHeader = ({
    headerTitle,
    images,
    openSpaceDetailModal,
    shortcut,
    ...props
}: SpaceCardHeaderProps) => {
    const {t} = useTranslation()

    return (
        <StyledSpaceCardHeader forwardedAs="div" justify="space-between" gap={1} {...props}>
            <Flexbox direction="column" gap={1}>
                <h3>{headerTitle}</h3>
                {images.length >= 1 && <h4>{t('commons:x_images', {count: images.length})}</h4>}
            </Flexbox>
            {!!openSpaceDetailModal && (
                <Button className="show-hover" variant="secondary" size="sm" iconOnly onClick={openSpaceDetailModal}>
                    <ArrowRightIcon />
                </Button>
            )}
            <Flexbox className={openSpaceDetailModal && 'show-not-hover'} gap={1}>
                {shortcutToLabelTuple(shortcut).map(shortcutLabel => (
                    <StyledShortcutLabel justify="center" align="center" key={shortcutLabel}>
                        {shortcutLabel}
                    </StyledShortcutLabel>
                ))}
            </Flexbox>
        </StyledSpaceCardHeader>
    )
}

export const SpaceCardImagesPreviewGrid = forwardRef<
    HTMLDivElement,
    HTMLAttributes<HTMLDivElement> & {$isDraggingOver?: boolean}
>((props, ref) => <StyledSpaceCardImagesPreviewGrid ref={ref} {...props} />)

type SpaceCardImagePreviewSlot = {
    image: Image
    otherImagesCount: number
    overlayComponent?: ReactNode
}

export const SpaceCardImagePreviewSlot = forwardRef<HTMLDivElement, SpaceCardImagePreviewSlot>(
    ({image, otherImagesCount, overlayComponent}, ref) => (
        <StyledImagePreviewSlot ref={ref} $hasOtherImagesCount={otherImagesCount >= 1}>
            {overlayComponent && <StyledImagePreviewSlotOverlay>{overlayComponent}</StyledImagePreviewSlotOverlay>}

            {image.thumbnails?.xs ? (
                <img src={image.thumbnails.xs} alt={image.name} key={image.id} />
            ) : (
                <StyledImagePreviewSlowPlaceholder
                    title={image.name}
                    align="center"
                    justify="center"
                    direction="column"
                >
                    <ImageXIcon size={18} />
                </StyledImagePreviewSlowPlaceholder>
            )}

            {otherImagesCount >= 1 && <span>+{otherImagesCount}</span>}
        </StyledImagePreviewSlot>
    )
)

export const SpaceCardEmptyPreviewSlot = () => <StyledImagePreviewSlot />
