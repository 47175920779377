import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import styled, {css} from 'styled-components'

export const StyledCurationElementImagesContainer = styled.div(
    () => css`
        display: grid;
        width: 100%;
        grid-template-columns: repeat(4, minmax(0, 1fr));
    `
)

export const StyledAllHiddenImages = styled(Flexbox)(
    ({theme: {spacing, palette, typography}}) => css`
        background-color: ${palette.neutral['100']};
        border: 1px solid ${palette.neutral['200']};
        border-radius: 4px;
        box-sizing: border-box;
        width: 100%;
        padding: ${spacing * 12}px 0;
        z-index: 1;

        & > h3 {
            ${typography.textLg};
            color: ${palette.neutral.black};
            font-weight: 500;
        }

        & > p {
            ${typography.textSm};
            color: ${palette.neutral['600']};
        }
    `
)
