import {generatePath, Navigate, Outlet, useLocation} from 'react-router-dom'
import {routes} from '@/utilities/constants/routes.tsx'
import {useAuthStore} from '@/features/auth/store/store.ts'
import {AuthRedirectLocationState} from '@/features/auth/types.ts'

export const PublicRoute = () => {
    const accessToken = useAuthStore(state => state.accessToken)
    const location = useLocation()
    const locationState = location.state as AuthRedirectLocationState

    // Handle the case when accessToken is not available.
    if (!accessToken) {
        return <Outlet />
    }

    // AccessToken is available, proceed with further conditions
    if (locationState?.id) {
        // If there's a photoset ID in the location state, navigate to the PHOTOSET_INITIAL_QA_STEP routeya
        return <Navigate to={generatePath(routes.PHOTOSET_INITIAL_QA.path, {id: locationState.id})} />
    } else {
        // If there's no photoset ID in the location state, navigate to the NOT_FOUND route
        return <Navigate to={routes.WELCOME.path} />
    }
}
