import React, {useState} from 'react'
import {Button} from '@components/ui/button/Button.tsx'
import {Container} from '@components/ui/container/Container.tsx'
import {StyledSetPasswordBox, StyledSetPasswordBoxForm, StyledSetPasswordWrapper} from '@pages/set-password/style.ts'
import {useTranslation} from 'react-i18next'
import {Helmet} from 'react-helmet'
import {SubmitHandler, useForm} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import {InputText} from '@components/commons/input-text/InputText.tsx'
import {AirbnbIcon, CheckIcon} from '@components/ui/icon/Icon.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {errorHandler} from '@utilities/helpers.ts'
import {routes} from '@utilities/constants/routes.tsx'
import {Spinner} from '@components/ui/spinner/Spinner.tsx'
import {
    RESET_PASSWORD_FORM_MODEL,
    ResetPasswordFormSchema,
    ResetPasswordValidationSchema
} from '@pages/reset-password/ResetPasswordFormModel.ts'
import {useResetPassword} from '@/features/auth/services/queries/useResetPassword.ts'
import {ButtonLink} from '@components/ui/button-link/ButtonLink.tsx'

export const ResetPassword: React.FC = () => {
    const {t} = useTranslation()
    const [resetSuccess, setResetSuccess] = useState(false)
    const {
        register,
        handleSubmit,
        formState: {errors}
    } = useForm<ResetPasswordValidationSchema>({
        mode: 'onBlur',
        resolver: zodResolver(ResetPasswordFormSchema)
    })

    //reset password mutation
    const {mutate: resetPassword, isPending} = useResetPassword({
        onError: e => errorHandler(e), //toast.error(t(e.message)),
        onSuccess: () => {
            setResetSuccess(true)
        }
    })

    const onSubmit: SubmitHandler<ResetPasswordValidationSchema> = data => {
        resetPassword({email: data.email})
    }

    return (
        <Container>
            <Helmet title={t('setPassword:page_title_meta')} />
            <StyledSetPasswordWrapper align={'center'} justify={'center'}>
                <StyledSetPasswordBox direction={'column'} align={'stretch'}>
                    {resetSuccess && (
                        <>
                            <Flexbox justify={'center'}>
                                <CheckIcon size={40} fill={'#079455'} />
                            </Flexbox>
                            <h4>{t('resetPassword:success_title')}</h4>
                            <p>{t('resetPassword:success_message')}</p>
                            <ButtonLink to={routes.LOGIN.path} variant={'primary'} size={'md'} type={'submit'}>
                                {t('resetPassword:success_cta')}
                            </ButtonLink>
                        </>
                    )}
                    {!resetSuccess && (
                        <>
                            <Flexbox justify={'center'}>
                                <AirbnbIcon size={40} fill={'#E31B54'} />
                            </Flexbox>
                            <h4>{t('resetPassword:title')}</h4>
                            <p>{t('resetPassword:subtitle')}</p>
                            <StyledSetPasswordBoxForm onSubmit={handleSubmit(onSubmit)}>
                                <Flexbox direction={'column'} gap={4}>
                                    <InputText
                                        label={t(RESET_PASSWORD_FORM_MODEL.email.label)}
                                        type={'email'}
                                        placeholder={t(RESET_PASSWORD_FORM_MODEL.email.placeholder)}
                                        errorMessage={t(errors.email?.message || '')}
                                        {...register(t(RESET_PASSWORD_FORM_MODEL.email.name))}
                                    />
                                </Flexbox>

                                <Flexbox direction={'column'} gap={2} align={'stretch'}>
                                    <Button variant={'primary'} size={'md'} type={'submit'} disabled={isPending}>
                                        {t('resetPassword:primary_cta')}
                                        {isPending && <Spinner size={16} />}
                                    </Button>
                                    <ButtonLink to={routes.LOGIN.path} variant={'tertiary'} size={'md'} type={'submit'}>
                                        {t('resetPassword:success_cta')}
                                    </ButtonLink>
                                </Flexbox>
                            </StyledSetPasswordBoxForm>
                        </>
                    )}
                </StyledSetPasswordBox>
            </StyledSetPasswordWrapper>
        </Container>
    )
}

ResetPassword.displayName = 'SetPassword'
