import {ArrowLeftIcon, ArrowRightIcon, MinusCircleIcon, XCloseIcon} from '@components/ui/icon/Icon.tsx'
import {
    StyledImagesSelectedLabel,
    StyledNavigationButton,
    StyledScrollbar,
    StyledSpaceDetailModalCard,
    StyledSpaceDetailModalCardHeader,
    StyledSpaceDetailModalContent,
    StyledSpaceDetailModalOverlay
} from '@/features/photoset/components/space-detail-modal/style.ts'
import {Dispatch, FC, SetStateAction, useMemo, useState} from 'react'
import {OnAllocateAction, PhotosetSpace, PhotosetURLParams} from '@/features/photoset/types.ts'
import {handleImagesRangeSelect, spaceNameToLabel} from '@/features/photoset/utils.ts'
import {Button} from '@components/ui/button/Button.tsx'
import {createPortal} from 'react-dom'
import {useTranslation} from 'react-i18next'
import {usePhotosetStore} from '@/features/photoset/store/store.ts'
import {ListHeader} from '@components/ui/list-header/ListHeader.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {ImagesList} from '@/features/photoset/components/images-list/ImagesList.tsx'
import {ImageCard} from '@components/commons/image-card/ImageCard.tsx'
import {useHotkeys} from 'react-hotkeys-hook'
import {ScrollArea} from '@components/commons/scroll-area/ScrollArea.tsx'
import {errorHandler} from '@/utilities/helpers'
import {useDeallocateImages} from '@/features/photoset/services/useDeallocateImages.ts'
import {useOutletContext} from 'react-router-dom'
import {useReallocateImages} from '@/features/photoset/services/useReallocateImages.tsx'
import {AllocateDropdown} from '@/features/photoset/components/allocate-dropdown/AllocateDropdown'
import {useMutationState} from '@tanstack/react-query'
import {MUTATION_KEYS} from '@/queryClient'
import {HttpAllocateImagesOptions, HttpDeallocateImagesOptions} from '@/features/photoset/services/photoset.http'

type SpaceDetailModalProps = {
    onClose: () => void
    currentSpaceIndex: number
    setCurrentSpaceIndex: Dispatch<SetStateAction<number | null>>
    photosetSpaces: PhotosetSpace[]
}

export const SpaceDetailModal: FC<SpaceDetailModalProps> = ({
    onClose,
    currentSpaceIndex,
    setCurrentSpaceIndex,
    photosetSpaces
}) => {
    const {t} = useTranslation()
    const [modalRef, setModalRef] = useState<HTMLDivElement | null>(null)
    const {urlParams} = useOutletContext<{urlParams: PhotosetURLParams}>()
    const images = usePhotosetStore(state => state.images)
    const selectedImages = usePhotosetStore(state => state.selectedImages)
    const unselectAll = usePhotosetStore(state => state.unselectAll)
    const selectImages = usePhotosetStore(state => state.selectImages)
    const unselectImages = usePhotosetStore(state => state.unselectImages)
    const allocateImages = usePhotosetStore(state => state.allocateImages)
    const deallocateImages = usePhotosetStore(state => state.deallocateImages)
    const prevIndex = currentSpaceIndex == 0 ? photosetSpaces.length - 1 : currentSpaceIndex - 1
    const nextIndex = currentSpaceIndex == photosetSpaces.length - 1 ? 0 : currentSpaceIndex + 1
    const currentSpaceImages = [...images.values()].filter(
        image => image.space?.key_name == photosetSpaces[currentSpaceIndex].space.key_name
    )
    const [lastSelectedImageId, setLastSelectedImageId] = useState<number | null>(null)
    const reallocateImagesMutation = useReallocateImages()
    const deallocateImagesMutation = useDeallocateImages({onError: errorHandler})

    // Deallocate action
    const onDeallocate = async ({
        photosetSpaceId,
        imagesIds,
        keepSelected = false,
        trackInHistory = false
    }: OnAllocateAction) => {
        try {
            await deallocateImagesMutation.mutateAsync({
                urlParams: {
                    photosetId: urlParams.id,
                    photosetSpaceId: photosetSpaceId
                },
                payload: imagesIds
            })

            deallocateImages({
                imagesIds,
                photosetSpaceId,
                keepSelected,
                trackInHistory
            })
        } catch (error) {
            errorHandler(error)
        }
    }

    // Allocate action
    const onAllocate = async ({photosetSpaceId, imagesIds = selectedImages}: OnAllocateAction) => {
        await onDeallocate({
            photosetSpaceId,
            imagesIds,
            keepSelected: false,
            trackInHistory: false
        })

        try {
            await reallocateImagesMutation.mutateAsync({
                urlParams: {
                    photosetId: urlParams.id,
                    photosetSpaceId: photosetSpaceId
                },
                payload: imagesIds
            })

            allocateImages({
                imagesIds,
                photosetSpaceId,
                keepSelected: false,
                trackInHistory: false
            })

            unselectAll()
        } catch (error) {
            errorHandler(error)
        }
    }

    const goToPrevSpace = () => {
        setCurrentSpaceIndex(prevIndex)
        unselectAll()
    }
    const goToNextSpace = () => {
        setCurrentSpaceIndex(nextIndex)
        unselectAll()
    }

    // Get all the mutation status targetized to the allocate images key
    const allocateMutationState = useMutationState({
        filters: {mutationKey: [MUTATION_KEYS.ALLOCATE_IMAGES], status: 'pending'},
        select: mutation => ({
            variables: mutation.state.variables as HttpAllocateImagesOptions
        })
    })

    // Get all the mutation status targetized to the deallocate images key
    const deallocateMutationState = useMutationState({
        filters: {mutationKey: [MUTATION_KEYS.DEALLOCATE_IMAGES], status: 'pending'},
        select: mutation => ({
            variables: mutation.state.variables as HttpDeallocateImagesOptions
        })
    })

    // Retrieve all the image ids that are in pending allocation and deallocation
    const pendingAllocationImageIds = useMemo(
        () =>
            [...allocateMutationState, ...deallocateMutationState].map(allocateState => ({
                imageIds: allocateState.variables.payload
            })),
        [allocateMutationState, deallocateMutationState]
    )

    // Shortcut actions
    useHotkeys('ArrowLeft', goToPrevSpace, {preventDefault: true, enableOnFormTags: true})
    useHotkeys('ArrowRight', goToNextSpace, {preventDefault: true, enableOnFormTags: true})
    useHotkeys('Esc', onClose, {preventDefault: true, enableOnFormTags: true})

    return createPortal(
        <>
            <StyledSpaceDetailModalOverlay />
            <StyledSpaceDetailModalContent fullWidth fullHeight ref={setModalRef}>
                <StyledNavigationButton variant="tertiary" onClick={goToPrevSpace}>
                    <ArrowLeftIcon />
                    {spaceNameToLabel[photosetSpaces[prevIndex].space.key_name]}
                </StyledNavigationButton>
                <StyledSpaceDetailModalCard>
                    <StyledSpaceDetailModalCardHeader justify="center" align="center">
                        <Button variant="ghost" iconOnly onClick={onClose}>
                            <XCloseIcon />
                        </Button>
                        <h3>{spaceNameToLabel[photosetSpaces[currentSpaceIndex].space.key_name]}</h3>
                    </StyledSpaceDetailModalCardHeader>
                    <ListHeader
                        size="sm"
                        title={t('commons:x_images', {count: currentSpaceImages.length})}
                        endSlot={
                            <Flexbox align="center" gap={4}>
                                {selectedImages.length >= 1 ? (
                                    <>
                                        <Flexbox align="center" gap={2}>
                                            <StyledImagesSelectedLabel>
                                                {t('commons:x_images_selected', {count: selectedImages.length})}
                                            </StyledImagesSelectedLabel>
                                            <Button
                                                size="sm"
                                                variant="tertiary"
                                                onClick={() => {
                                                    unselectAll()
                                                    setLastSelectedImageId(null)
                                                }}
                                            >
                                                {t('commons:unselect_all')}
                                            </Button>
                                        </Flexbox>

                                        <AllocateDropdown
                                            container={modalRef}
                                            onAllocate={onAllocate}
                                            excludedSpaces={[photosetSpaces[currentSpaceIndex].space.key_name]}
                                        />

                                        <Button
                                            variant="secondary"
                                            size="sm"
                                            onClick={() =>
                                                onDeallocate({
                                                    photosetSpaceId: photosetSpaces[currentSpaceIndex].id,
                                                    imagesIds: selectedImages,
                                                    keepSelected: false,
                                                    trackInHistory: true
                                                })
                                            }
                                        >
                                            <MinusCircleIcon />
                                            {t('commons:remove')}
                                        </Button>
                                    </>
                                ) : currentSpaceImages.length >= 1 ? (
                                    <Button
                                        size="sm"
                                        variant="tertiary"
                                        onClick={() => {
                                            selectImages(currentSpaceImages.map(image => image.id))
                                            setLastSelectedImageId(currentSpaceImages[0].id)
                                        }}
                                    >
                                        {t('commons:select_all')}
                                    </Button>
                                ) : (
                                    false
                                )}
                            </Flexbox>
                        }
                    />
                    <ScrollArea scrollbar={<StyledScrollbar />}>
                        <ImagesList>
                            {currentSpaceImages.map((image, index, array) => {
                                const isSelected = selectedImages.some(selectedImageId => selectedImageId == image.id)
                                return (
                                    <ImageCard
                                        image={image}
                                        key={image.id}
                                        isSelected={isSelected}
                                        thumbnail={image.thumbnails?.xs}
                                        isLoading={pendingAllocationImageIds.some(pendingStatus =>
                                            pendingStatus.imageIds.includes(image.id)
                                        )}
                                        onSelect={isShiftPressed => {
                                            handleImagesRangeSelect({
                                                array,
                                                image,
                                                index,
                                                isSelected,
                                                isShiftPressed,
                                                lastSelectedImageId,
                                                selectImages: selectImages,
                                                setLastSelectedImageId,
                                                unselectImages: unselectImages
                                            })
                                        }}
                                    />
                                )
                            })}
                        </ImagesList>
                    </ScrollArea>
                </StyledSpaceDetailModalCard>
                <StyledNavigationButton variant="tertiary" onClick={goToNextSpace}>
                    {spaceNameToLabel[photosetSpaces[nextIndex].space.key_name]}
                    <ArrowRightIcon />
                </StyledNavigationButton>
            </StyledSpaceDetailModalContent>
        </>,
        document.body
    )
}

SpaceDetailModal.displayName = 'SpaceDetailModal'
