import {create} from 'zustand'
import {Image, PhotosetSpace, PhotosetSpacesChecks, Space} from '@/features/photoset/types.ts'
import {raise} from '@/utilities/helpers'
import {validatePhotosetSpaces} from '@/features/photoset/utils'

export type PhotosetStore = {
    images: Map<Image['id'], Image>
    photosetSpacesDict: Record<PhotosetSpace['id'], PhotosetSpace>
    photosetSpaces: PhotosetSpace[]
    photosetSpacesChecks: Record<PhotosetSpace['configuration_space_id'], PhotosetSpacesChecks>
    photosetSpacesSelectedOnly: Space['id'][]
    selectedImages: Image['id'][]
    allocationHistory: Array<{
        type: 'allocation' | 'deallocation'
        photosetSpaceId: PhotosetSpace['id']
        imagesIds: Image['id'][]
    }>
    initImages: (images: Image[]) => void
    initSpaces: (photosetSpaces: PhotosetSpace[]) => void
    initPhotosetSpaces: (photosetSpaces: PhotosetSpace[]) => void
    photosetSpaceImageSelectionToggle: (spaceIndex: number, phselectedImageId: Image['id']) => void
    photosetSpaceImageUnselectAll: (spaceIndex: number) => void
    resetPhotosetSpaces: () => void
    toggleHideUnselected: (spaceName: Space['id']) => void
    selectImages: (selectedImageId: Image['id'][]) => void
    unselectImages: (unselectedImageId: Image['id'][]) => void
    toggleSelectImage: (imageId: Image['id']) => void
    unselectAll: () => void
    selectAll: () => void
    allocateImages: ({
        imagesIds,
        photosetSpaceId,
        keepSelected,
        trackInHistory
    }: {
        imagesIds: Image['id'][]
        photosetSpaceId: PhotosetSpace['id']
        keepSelected: boolean
        trackInHistory: boolean
    }) => void
    deallocateImages: ({
        imagesIds,
        photosetSpaceId,
        keepSelected,
        trackInHistory
    }: {
        imagesIds: Image['id'][]
        photosetSpaceId: PhotosetSpace['id']
        keepSelected: boolean
        trackInHistory: boolean
    }) => void
    undoLatestAction: (keepSelected?: boolean) => void
}

export const usePhotosetStore = create<PhotosetStore>((set, getState) => ({
    images: new Map<Image['id'], Image>(),
    photosetSpacesDict: {},
    photosetSpaces: [],
    photosetSpacesChecks: {},
    photosetSpacesSelectedOnly: [],
    selectedImages: [],
    allocationHistory: [],
    initImages: images => set({images: new Map(images.map(image => [image.id, image]))}),
    initSpaces: photosetSpaces =>
        set({
            photosetSpacesDict: Object.fromEntries(
                photosetSpaces.map(photosetSpace => [photosetSpace.id, photosetSpace])
            )
        }),
    initPhotosetSpaces: photosetSpaces =>
        set(store => {
            const spacesChecks: Record<PhotosetSpace['configuration_space_id'], PhotosetSpacesChecks> =
                validatePhotosetSpaces(photosetSpaces, store.photosetSpacesChecks)

            return {
                ...store,
                photosetSpaces: photosetSpaces,
                photosetSpacesChecks: spacesChecks
            }
        }),
    photosetSpaceImageSelectionToggle: (spaceIndex, selectedImageId) =>
        set(store => {
            const newPhotosetSpaces = store.photosetSpaces
            const newPhotosetSpacesChecks = store.photosetSpacesChecks
            newPhotosetSpaces[spaceIndex].images.map(image => {
                if (image.id === selectedImageId) {
                    image.is_final_select
                        ? --newPhotosetSpacesChecks[spaceIndex].selectedCounter
                        : ++newPhotosetSpacesChecks[spaceIndex].selectedCounter
                    image.is_final_select = !image.is_final_select
                }
                return image
            })

            const spacesChecks: Record<PhotosetSpace['configuration_space_id'], PhotosetSpacesChecks> =
                validatePhotosetSpaces(newPhotosetSpaces, store.photosetSpacesChecks)

            return {
                photosetSpaces: [...newPhotosetSpaces],
                photosetSpacesChecks: spacesChecks
            }
        }),
    photosetSpaceImageUnselectAll: spaceIndex =>
        set(store => {
            const newPhotosetSpaces = store.photosetSpaces
            const newPhotosetSpacesChecks = store.photosetSpacesChecks
            newPhotosetSpaces[spaceIndex].images.map(image => (image.is_final_select = false))
            newPhotosetSpacesChecks[spaceIndex].selectedCounter = 0
            const spacesChecks: Record<PhotosetSpace['configuration_space_id'], PhotosetSpacesChecks> =
                validatePhotosetSpaces(newPhotosetSpaces, store.photosetSpacesChecks)

            return {
                photosetSpaces: [...newPhotosetSpaces],
                photosetSpacesChecks: spacesChecks
            }
        }),
    resetPhotosetSpaces: () => set({photosetSpaces: [], photosetSpacesChecks: {}}),
    toggleHideUnselected: spaceId =>
        set(store => {
            return {
                photosetSpacesSelectedOnly: store.photosetSpacesSelectedOnly.includes(spaceId)
                    ? store.photosetSpacesSelectedOnly.filter(name => name != spaceId)
                    : store.photosetSpacesSelectedOnly.concat(spaceId)
            }
        }),
    selectImages: selectedImageIds => set(store => ({selectedImages: [...store.selectedImages, ...selectedImageIds]})),
    unselectImages: unselectedImageIds =>
        set(store => ({selectedImages: store.selectedImages.filter(imageId => !unselectedImageIds.includes(imageId))})),
    toggleSelectImage: imageId =>
        set(store => ({
            selectedImages: store.selectedImages.includes(imageId)
                ? store.selectedImages.filter(image => image != imageId)
                : [...store.selectedImages, imageId]
        })),
    unselectAll: () => set({selectedImages: []}),
    selectAll: () => set(store => ({selectedImages: [...store.images.keys()]})),
    allocateImages: ({imagesIds, photosetSpaceId, keepSelected, trackInHistory}) => {
        const images = getState().images
        const photosetSpacesDict = getState().photosetSpacesDict

        imagesIds.forEach(selectedImageId => {
            const selectedImage = images.get(selectedImageId) ?? raise(`Can't find the image (ID: ${selectedImageId})`)
            images.set(selectedImageId, {...selectedImage, space: photosetSpacesDict[photosetSpaceId].space})
        })

        set(store => ({
            images,
            selectedImages: keepSelected ? imagesIds : [],
            allocationHistory: trackInHistory
                ? [
                      {
                          type: 'allocation',
                          imagesIds: store.selectedImages,
                          photosetSpaceId
                      },
                      ...store.allocationHistory
                  ]
                : store.allocationHistory
        }))
    },
    deallocateImages: ({imagesIds, photosetSpaceId, keepSelected, trackInHistory}) => {
        const images = getState().images

        imagesIds.forEach(selectedImageId => {
            const image = images.get(selectedImageId) ?? raise(`Can't find the image (ID: ${selectedImageId})`)
            images.set(selectedImageId, {...image, space: null})
        })

        set(store => ({
            images,
            selectedImages: keepSelected ? imagesIds : [],
            allocationHistory: trackInHistory
                ? [
                      {
                          type: 'deallocation',
                          imagesIds,
                          photosetSpaceId: photosetSpaceId
                      },
                      ...store.allocationHistory
                  ]
                : store.allocationHistory
        }))
    },
    undoLatestAction: keepSelected => {
        const latestAction = getState().allocationHistory[0]
        if (!latestAction) {
            return
        }
        const images = getState().images
        const photosetSpaces = getState().photosetSpacesDict

        latestAction.imagesIds.forEach(selectedImageId => {
            const selectedImage = images.get(selectedImageId) ?? raise(`Can't find the image (ID: ${selectedImageId})`)

            if (latestAction.type == 'allocation') {
                selectedImage.space = null
            } else {
                selectedImage.space = photosetSpaces[latestAction.photosetSpaceId].space ?? null
            }

            images.set(selectedImageId, selectedImage)
        })

        set(store => ({
            images,
            selectedImages: keepSelected ? latestAction.imagesIds : store.selectedImages,
            allocationHistory: store.allocationHistory.slice(1)
        }))
    }
}))
