import {FC, useMemo} from 'react'
import {Flag, Image, PhotosetSpace, StepKey} from '@/features/photoset/types'
import {Chip} from '@/components/commons/chip/Chip'
import {useTranslation} from 'react-i18next'
import {PlusIcon} from '@/components/ui/icon/Icon'
import {FlagsPopover} from '@/features/photoset/components/flags-popover/FlagsPopover'
import {StyledEditingQaFlagsSelector} from '@/features/photoset/components/editing-qa-flags-selector/style'
import {EditingQaChip} from '@/features/photoset/components/editing-qa-chip/EditingQaChip'

interface EditingQaFlagsSelectorProps {
    image: Omit<Image, 'space'>
    types?: Flag['type'][]
    photosetSpaceId: PhotosetSpace['id']
    isQaView: boolean
}

export const EditingQaFlagsSelector: FC<EditingQaFlagsSelectorProps> = ({image, types, photosetSpaceId, isQaView}) => {
    const {t} = useTranslation()

    const flags = useMemo(() => {
        if (!image.flags) return []
        if (!types) return image.flags

        return image.flags.filter(flag => types.includes(flag.type))
    }, [image.flags, types])

    return (
        <StyledEditingQaFlagsSelector gap={1.5}>
            {flags.map(flag => (
                <EditingQaChip
                    key={flag.id}
                    flag={flag}
                    image={image}
                    label={t(`photoset:flags:${flag.key_name}:label`)}
                    photosetSpaceIds={[photosetSpaceId]}
                    isQaView={isQaView}
                />
            ))}

            {isQaView && (
                <FlagsPopover
                    stepId={StepKey['editing-qa-flags']}
                    selectedImagesIds={[image.id]}
                    types={types}
                    photosetSpaceIds={[photosetSpaceId]}
                    disableScrollBelow
                    trigger={
                        <Chip
                            label={t(`commons:flag`, {count: 1})}
                            isActive={false}
                            variant="info"
                            endSlot={<PlusIcon />}
                        />
                    }
                />
            )}
        </StyledEditingQaFlagsSelector>
    )
}
