import styled, {css} from 'styled-components'
import {NeutralPalette, Palette} from '@/types/style'
import {CSSProperties} from 'react'

type StyledDividerProps = {
    direction: 'vertical' | 'horizontal'
    width?: CSSProperties['width']
    height?: CSSProperties['height']
    startSpacing?: number
    $endSpacing?: number
    background?: Palette[keyof Palette] | NeutralPalette[keyof NeutralPalette]
}

export const StyledDivider = styled.div<StyledDividerProps>(
    ({direction, width, height, startSpacing = 0, $endSpacing = 0, background, theme: {palette, spacing}}) =>
        direction == 'vertical'
            ? css`
                  height: ${height ?? '100%'};
                  width: ${width ?? '1px'};
                  margin-inline-start: ${startSpacing * spacing}px;
                  margin-inline-end: ${$endSpacing * spacing}px;
                  background: ${background ?? palette.neutral['200']};
              `
            : css`
                  height: ${height ?? '1px'};
                  width: ${width ?? '100%'};
                  margin-top: ${startSpacing * spacing}px;
                  margin-bottom: ${$endSpacing * spacing}px;
                  background: ${background ?? palette.neutral['200']};
              `
)
