import {hotKeysEventToSpace, spaceNameToShortcut} from '@/features/photoset/utils.ts'
import {Image, OnAllocateAction} from '@/features/photoset/types.ts'
import {ObjectValues} from '@utilities/helpers.ts'
import {usePhotosetStore} from '@/features/photoset/store/store.ts'
import {useHotkeys} from 'react-hotkeys-hook'
import {Dispatch, SetStateAction} from 'react'

type useGalleryAllocatioShortcuts = {
    selectableImages: Omit<Image, 'space'>[]
    currentDetailImageIndex: number
    setCurrentDetailImageIndex: Dispatch<SetStateAction<number | null>>
    onAllocate: (values: OnAllocateAction) => void
    onUndo: (values: OnAllocateAction) => void
    onClose: () => void
    isPending: boolean
}

export const useGalleryAllocationShortcuts = (args: useGalleryAllocatioShortcuts) => {
    const {
        currentDetailImageIndex,
        setCurrentDetailImageIndex,
        selectableImages,
        onAllocate,
        onUndo,
        onClose,
        isPending
    } = args

    const selectedImages = usePhotosetStore(store => store.selectedImages)
    const allocationHistory = usePhotosetStore(store => store.allocationHistory)
    const photosetSpacesDict = usePhotosetStore(store => store.photosetSpacesDict)
    const selectImages = usePhotosetStore(store => store.selectImages)
    const unselectImages = usePhotosetStore(store => store.unselectImages)

    useHotkeys(
        ObjectValues(spaceNameToShortcut),
        (_, hotkeysEvent) => {
            if (selectedImages.length != 1 || isPending) return
            onAllocate({
                photosetSpaceId: hotKeysEventToSpace(hotkeysEvent, ObjectValues(photosetSpacesDict)),
                imagesIds: selectedImages,
                isShortcut: true,
                trackInHistory: true
            })
        },
        {
            preventDefault: true,
            enableOnFormTags: true
        },
        [isPending, selectedImages, ObjectValues(photosetSpacesDict)]
    )

    useHotkeys(
        'mod+Z',
        () => {
            if (allocationHistory.length == 0 || isPending) return
            onUndo({
                imagesIds: allocationHistory[0].imagesIds,
                photosetSpaceId: allocationHistory[0].photosetSpaceId,
                isShortcut: true
            })
        },
        {
            preventDefault: true,
            enableOnFormTags: true
        },
        [isPending, allocationHistory]
    )

    useHotkeys(
        'ArrowLeft',
        () => {
            unselectImages([selectableImages[currentDetailImageIndex].id])
            selectImages([selectableImages[currentDetailImageIndex - 1].id])
            setCurrentDetailImageIndex(currentDetailImageIndex - 1)
        },
        {enabled: currentDetailImageIndex >= 1},
        [currentDetailImageIndex, selectableImages]
    )

    useHotkeys(
        'ArrowRight',
        () => {
            unselectImages([selectableImages[currentDetailImageIndex].id])
            selectImages([selectableImages[currentDetailImageIndex + 1].id])
            setCurrentDetailImageIndex(currentDetailImageIndex + 1)
        },
        {enabled: currentDetailImageIndex < selectableImages.length - 1},
        [currentDetailImageIndex, selectableImages]
    )

    useHotkeys('Esc', onClose)
}
