import {z} from 'zod'

export const User = z.object({
    airbnb_user_id: z.coerce.string(),
    email: z.string().email(),
    first_name: z.string().nullable(),
    id: z.coerce.string(),
    last_name: z.string().nullable()
})
export type User = z.infer<typeof User>

const AuthState = z.object({
    user: User.nullable(),
    accessToken: z.string(),
    refreshToken: z.string()
})
export type AuthState = z.infer<typeof AuthState>

export const AuthRedirectLocationState = z.object({
    id: z.string()
})
export type AuthRedirectLocationState = z.infer<typeof AuthRedirectLocationState>

export const HttpLoginResponse = z.object({
    user: User,
    access_token: z.string(),
    refresh_token: z.string()
})
export type HttpLoginResponse = z.infer<typeof HttpLoginResponse>
