import {HTMLAttributes, forwardRef} from 'react'
import {StyledSpaceImageFrame, StyledSpaceImagesContainer} from './style'
import styled, {css} from 'styled-components'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'

export const SpaceImagesContainer = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement> & {$gap?: number}>(
    (props, ref) => <StyledSpaceImagesContainer {...props} ref={ref} />
)

interface SpaceImageFrameProps extends HTMLAttributes<HTMLDivElement> {
    $isSelected: boolean
}

export const SpaceImageFrame = forwardRef<HTMLDivElement, SpaceImageFrameProps>((props, ref) => (
    <StyledSpaceImageFrame {...props} ref={ref} />
))

export const SpaceImageSelectionInfo = styled(Flexbox)(
    ({theme: {palette, typography}}) => css`
        width: 100%;
        min-height: 68px;
        h4 {
            ${typography.textSm};
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            font-weight: 500;
            color: ${palette.neutral[900]};
        }
    `
)
