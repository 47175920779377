import styled, {css} from 'styled-components'

export const StyledOverlay = styled.div<{$type?: 'light' | 'dark'}>(
    ({theme: {palette, zIndex}, $type}) => css`
        position: fixed;
        inset: 0;
        background: ${$type == 'light' ? `${palette.neutral.white}CC` : `${palette.neutral.black}66`};
        z-index: ${zIndex.modalOverlay};
    `
)
