import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {Button} from '@/components/ui/button/Button'

export const StyledCurationPhotosetSpaceCounterWrapper = styled(Flexbox)`
    width: 100%;
`

export const StyledCurationPhotosetSpaceCounter = styled(Flexbox)<{$isError: boolean; $isSuccess: boolean}>(
    ({$isError: isError, $isSuccess: isSuccess, theme: {spacing, typography, palette}}) => css`
        background: ${isError ? palette.danger['100'] : isSuccess ? palette.success['100'] : palette.neutral['100']};
        border: 1px solid
            ${isError ? palette.danger['200'] : isSuccess ? palette.success['200'] : palette.neutral['200']};
        border-radius: 4px;
        box-sizing: border-box;
        padding: ${spacing}px ${spacing * 2}px;
        & > p {
            ${typography.textSm};
            color: ${isError ? palette.danger['600'] : isSuccess ? palette.success['600'] : palette.neutral['600']};
        }
        & > svg {
            fill: ${isError ? palette.danger['600'] : isSuccess ? palette.success['600'] : palette.neutral['600']};
        }
    `
)

export const StyledUnselectAllImagesButton = styled(Button)(
    ({theme: {spacing}}) => css`
        padding: 0 ${spacing * 4}px;
        min-height: 30px;
    `
)

export const StyledPhotosetSpaceCounterButton = styled(Button)`
    padding: 0;
`

export const StyledCurrentRule = styled.b`
    font-weight: 500;
`
