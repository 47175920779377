import {Dispatch, FC, SetStateAction, useMemo} from 'react'
import {GalleryModalHeader} from '@/components/commons/gallery-modal-header/GalleryModalHeader'
import {GalleryModalThumbnails} from '@/components/commons/gallery-modal-thumbnails/GalleryModalThumbnails'
import {Image, Space} from '@/features/photoset/types'
import {spaceNameToLabel} from '@/features/photoset/utils'
import {GalleryModal} from '@/components/commons/gallery-modal/GalleryModal'
import {useTranslation} from 'react-i18next'
import {useGalleryNeedsReviewShortcuts} from '@/features/photoset/hooks/useGalleryNeedsReviewShortcuts'
import {GalleryModalSidebar} from '@/components/commons/gallery-modal-sidebar/GalleryModalSidebar'
import {GalleryNeedsReviewSidebar} from '@/features/photoset/components/gallery-photoset-modal/gallery-needs-review-modal/GalleryNeedsReviewSidebar'
import {ImageBadge} from '@/features/photoset/components/image-badge/ImageBadge'

type GalleryNeedsReviewModalProps = {
    selectableImages: Omit<Image, 'space'>[]
    currentDetailImageIndex: number
    setCurrentDetailImageIndex: Dispatch<SetStateAction<number | null>>
    onClose: () => void
    currentSpaceName: Space['key_name']
}

export const GalleryNeedsReviewModal: FC<GalleryNeedsReviewModalProps> = ({
    selectableImages,
    currentDetailImageIndex,
    setCurrentDetailImageIndex,
    onClose,
    currentSpaceName
}) => {
    const {t} = useTranslation()
    const currentImage = useMemo(
        () => selectableImages[currentDetailImageIndex],
        [currentDetailImageIndex, selectableImages]
    )

    useGalleryNeedsReviewShortcuts({
        selectableImages,
        currentDetailImageIndex,
        setCurrentDetailImageIndex,
        onClose
    })

    return (
        <GalleryModal
            currentImage={currentImage}
            thumbnails={currentImage?.retouched_thumbnails || currentImage?.ai_retouched_thumbnails}
            onClose={onClose}
            headerSlot={
                <GalleryModalHeader
                    kickerTitle={spaceNameToLabel[currentSpaceName]}
                    currentImage={currentImage}
                    onClose={onClose}
                    endSlot={<ImageBadge type="to_revise" />}
                />
            }
            sidebarSlot={
                <GalleryModalSidebar
                    title={t('commons:feedback')}
                    content={<GalleryNeedsReviewSidebar currentImage={currentImage} />}
                />
            }
            thumbnailsSlot={
                <GalleryModalThumbnails
                    currentImage={currentImage}
                    thumbnails={'retouched_thumbnails'}
                    thumbnailsFallback={'ai_retouched_thumbnails'}
                    images={selectableImages}
                    onSelect={(_, index) => {
                        setCurrentDetailImageIndex(index)
                    }}
                />
            }
        />
    )
}
