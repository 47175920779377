import {Button} from '@/components/ui/button/Button'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import styled, {css} from 'styled-components'

export const StyledPhotosetCurationV1Page = styled.div`
    display: grid;
    grid-template-rows: auto 1fr;
    overflow: auto;
`
export const StyledFilterButton = styled(Button)<{$minWidth: number}>(
    ({theme: {palette}, $minWidth}) => css`
        min-width: ${$minWidth}px;
        justify-content: space-between;
        border-radius: 0;
        border-left: 1px solid ${palette.neutral[300]};
    `
)

export const StyledCurationSleElementHeaderCounter = styled(Flexbox)`
    width: 100%;
`
