import {Trans, useTranslation} from 'react-i18next'
import {StyledUndoButton} from '@/features/photoset/components/undo-message/style'
import {isWindows} from '@/utilities/helpers'
import {Space} from '@/features/photoset/types'
import {FC} from 'react'
import {spaceNameToLabel} from '@/features/photoset/utils'

interface UndoMessageProps {
    operationType: 'allocation' | 'deallocation'
    onUndo: () => void
    spaceKeyName?: Space['key_name']
}

const operationTypeToLabel: Record<UndoMessageProps['operationType'], string> = {
    deallocation: 'photoset:allocation:image_deallocated_success',
    allocation: 'photoset:allocation:image_allocated_success'
}

export const UndoMessage: FC<UndoMessageProps> = ({operationType, onUndo, spaceKeyName}) => {
    const {t} = useTranslation()

    return (
        <p>
            <Trans
                i18nKey={t(operationTypeToLabel[operationType])}
                components={{button: <StyledUndoButton onClick={onUndo} />}}
                values={{
                    shortcut: isWindows ? 'CTRL+Z' : '⌘Z',
                    space: spaceKeyName ? spaceNameToLabel[spaceKeyName] : ''
                }}
            />
        </p>
    )
}
