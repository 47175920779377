import {useTranslation} from 'react-i18next'
import {PageHero} from '@components/commons/page-hero/PageHero.tsx'
import {PageFooter} from '@components/commons/page-footer/PageFooter.tsx'
import {generatePath, useNavigate, useOutletContext} from 'react-router-dom'
import {Image, PhotosetURLParams} from '@/features/photoset/types.ts'
import {useEffect, useMemo, useState} from 'react'
import {usePhotosetStore} from '@/features/photoset/store/store.ts'
import {Button} from '@components/ui/button/Button.tsx'
import {useGetPhotosetImagesList} from '@/features/photoset/services/useGetPhotosetImagesList.ts'
import {ImageCard} from '@components/commons/image-card/ImageCard.tsx'
import {StyledCurationSleElementHeader} from '@/features/photoset/components/curation-photoset-space/styel.ts'
import {StyledCurationElementImagesContainer} from '@/features/photoset/components/curation-photoset-space-images/style.ts'
import {handleImagesRangeSelect} from '@/features/photoset/utils.ts'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {SubHeaderBanner} from '@/features/photoset/components/subheader-banner/SubHeaderBanner.tsx'
import {useGetPhotoset} from '@/features/photoset/services/useGetPhotoset.ts'
import {ButtonLink} from '@components/ui/button-link/ButtonLink.tsx'
import {CheckIcon, ChevronDownIcon, DownloadCloud02Icon, ImageXIcon} from '@components/ui/icon/Icon.tsx'
import {StyledCompletedStepsContainer} from '@pages/photoset/photoset-initial-qa-step-v1/style.ts'
import {
    StyledCompletedCopyWrapper,
    StyledCompletedStepsParapraph,
    StyledCompletedStepsTitle
} from '@pages/photoset/photoset-completed-steps/style.ts'
import {Spinner} from '@components/ui/spinner/Spinner.tsx'
import {ExpandImageButton} from '@/components/commons/expand-image-button/ExpandImageButton'
import {GalleryCurationModal} from '@/features/photoset/components/gallery-photoset-modal/gallery-curation-modal/GalleryCurationModal'
import {errorHandler} from '@/utilities/helpers'
import {routes} from '@utilities/constants/routes.tsx'
import {useUpdateStepToCompleted} from '@/features/photoset/services/useUpdateStepToCompleted.ts'
import {QUERY_KEYS, queryClient} from '@/queryClient.ts'
import {Skeleton} from '@/components/ui/skeleton/Skeleton'
import {
    StyledCurationSleElementHeaderCounter,
    StyledFilterButton,
    StyledPhotosetCurationV1Page
} from '@/pages/photoset/photoset-curation-step-v1/style'
import {Select, SelectProvider} from '@ariakit/react'
import {SelectItem, SelectPopover} from '@/components/ui/select-atoms/SelectAtoms'
import {
    PhotosetStepBodyContainer,
    PhotosetStepWrapper
} from '@/features/photoset/components/photoset-step-atoms/PhotosetStepAtoms'

type ImageFilters = 'all' | 'selected' | 'unselected'

export const PhotosetCurationStepV1 = () => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const {urlParams} = useOutletContext<{urlParams: PhotosetURLParams}>()
    const selectImages = usePhotosetStore(state => state.selectImages)
    const unselectAll = usePhotosetStore(state => state.unselectAll)
    const unselectImages = usePhotosetStore(state => state.unselectImages)
    const selectedImages = usePhotosetStore(state => state.selectedImages)
    const [currentDetailImageIndex, setCurrentDetailImageIndex] = useState<number | null>(null)
    const [imageFilters, setImageFilters] = useState<ImageFilters>('all')
    //queries
    const {data: photoset, isFetching: isPhotosetFetching} = useGetPhotoset({id: urlParams.id}, {enabled: false})
    const {
        data: images,
        isFetching: isImageFetching,
        isError
    } = useGetPhotosetImagesList({urlParams: {photosetId: urlParams.id}})
    //state
    const [lastSelectedImageId, setLastSelectedImageId] = useState<number | null>(null)
    const [curationTouched, setCurationTouched] = useState(false)
    //checks
    const isFetching = isPhotosetFetching || isImageFetching
    const showErrorBanner = useMemo(() => selectedImages.length < 1, [selectedImages])
    //mutations
    const updateStepToCompleted = useUpdateStepToCompleted({
        onError: errorHandler,
        onSuccess: () => {
            navigate(generatePath(routes.PHOTOSET_IMAGEN_AI_STEP_V1.path, {id: urlParams.id}))
            queryClient.invalidateQueries({queryKey: [QUERY_KEYS.PHOTOSET]})
            queryClient.invalidateQueries({queryKey: [QUERY_KEYS.IMAGES]})
        }
    })

    const onSendToImagenAi = () => {
        setCurationTouched(true)
        if (!showErrorBanner) {
            updateStepToCompleted.mutate({photosetId: urlParams.id, imagesIds: selectedImages})
        }
    }

    const onSelectAll = () => {
        if (images?.length) {
            images.forEach(image => selectImages([image.id]))
        }
    }

    const onExpandGallerys = (imageId: Image['id']) => {
        if (!images || images.length < 0) return
        setCurrentDetailImageIndex(images.findIndex(imageListItem => imageListItem.id == imageId))
    }

    //If curation or allocation process is already completed navigate to imagen-ai route
    useEffect(() => {
        if (photoset) {
            if (photoset.curation_completed_at) {
                navigate(generatePath(routes.PHOTOSET_IMAGEN_AI_STEP_V1.path, {id: urlParams.id}))
            } else if (photoset.allocation_completed_at) {
                navigate(generatePath(routes.PHOTOSET_INITIAL_QA_V1.path, {id: urlParams.id}))
            }
        }
    }, [photoset])

    // Sort images by file name
    const remappedImages = useMemo(
        () =>
            images && images?.length > 0
                ? images.filter(image => {
                      if (imageFilters == 'all') return image
                      if (imageFilters == 'selected') return selectedImages.includes(image.id)
                      if (imageFilters == 'unselected') return !selectedImages.includes(image.id)

                      return image
                  })
                : [],
        [images, imageFilters, selectedImages]
    )

    if (!isFetching && !isError && images?.length == 0) {
        return (
            <StyledCompletedStepsContainer>
                <StyledCompletedCopyWrapper direction="column" gap={3} align="center">
                    <ImageXIcon size={48} />
                    <StyledCompletedStepsTitle>{t('photoset:curation_v1:title_no_photo')}</StyledCompletedStepsTitle>
                    <StyledCompletedStepsParapraph>
                        {t('photoset:curation_v1:paragraph_no_photo')}
                    </StyledCompletedStepsParapraph>
                </StyledCompletedCopyWrapper>
            </StyledCompletedStepsContainer>
        )
    }

    if (!isFetching && !photoset?.thumbnail_generated_at) {
        return (
            <StyledCompletedStepsContainer>
                <StyledCompletedCopyWrapper direction="column" gap={3} align="center">
                    <Spinner size={32} color={'#000'} />
                    <StyledCompletedStepsTitle>{t('photoset:show:thumb_gen_title')}</StyledCompletedStepsTitle>
                    <StyledCompletedStepsParapraph>
                        {t('photoset:show:thumb_gen_subtitle')}
                    </StyledCompletedStepsParapraph>
                    {photoset?.zip_url && (
                        <ButtonLink size="sm" variant="secondary" to={photoset.zip_url} disabled={isFetching}>
                            {t('photoset:imagen_ai:completed:download_all_set')}
                            <DownloadCloud02Icon />
                        </ButtonLink>
                    )}
                </StyledCompletedCopyWrapper>
            </StyledCompletedStepsContainer>
        )
    }

    return (
        <StyledPhotosetCurationV1Page>
            <PageHero
                title={t('photoset:curation:title')}
                subtitle={t('photoset:curation:subtitle_v1')}
                endSlot={
                    photoset?.zip_url && (
                        <ButtonLink size="sm" variant="secondary" to={photoset.zip_url} disabled={isFetching}>
                            {t('photoset:imagen_ai:completed:download_all_set')}
                            <DownloadCloud02Icon />
                        </ButtonLink>
                    )
                }
            />
            {showErrorBanner && curationTouched && (
                <SubHeaderBanner
                    type={'danger'}
                    title={t('photoset:curation:selection_error_title_v1')}
                    subtitle={t('photoset:curation:selection_error_subtitle_v1', {selected: selectedImages.length})}
                />
            )}
            <PhotosetStepWrapper>
                <PhotosetStepBodyContainer fullWidth>
                    <StyledCurationSleElementHeader gap={1} justify="space-between" align="center">
                        {isImageFetching ? (
                            <Skeleton width={80} height={28} />
                        ) : (
                            <StyledCurationSleElementHeaderCounter align="center" justify="space-between">
                                <Flexbox align="center" gap={4}>
                                    <h4>
                                        {images?.length} {t('common:images')}
                                    </h4>

                                    {images?.length && (
                                        <SelectProvider
                                            value={imageFilters}
                                            setValue={(value: ImageFilters) => setImageFilters(value)}
                                        >
                                            <Select
                                                render={
                                                    <StyledFilterButton size="sm" variant="ghost" $minWidth={160}>
                                                        <Flexbox align="center" gap={1}>
                                                            <span>
                                                                {t(`photoset:curation_v1:filters:${imageFilters}`)}
                                                            </span>
                                                            <span>
                                                                {imageFilters !== 'all' && `(${remappedImages.length})`}
                                                            </span>
                                                        </Flexbox>
                                                        <ChevronDownIcon />
                                                    </StyledFilterButton>
                                                }
                                            />

                                            <SelectPopover gutter={4} sameWidth>
                                                {['selected', 'unselected', 'all'].map(option => (
                                                    <SelectItem key={option} value={option}>
                                                        <span>{t(`photoset:curation_v1:filters:${option}`)}</span>
                                                        {option == imageFilters && <CheckIcon size={20} />}
                                                    </SelectItem>
                                                ))}
                                            </SelectPopover>
                                        </SelectProvider>
                                    )}
                                </Flexbox>

                                {images?.length && selectedImages.length == 0 ? (
                                    <Button variant={'tertiary'} size={'sm'} onClick={onSelectAll}>
                                        {t('commons:select_all')}
                                    </Button>
                                ) : (
                                    selectedImages.length > 0 && (
                                        <Flexbox align="center">
                                            <p>
                                                {t('commons:x_images_selected_one', {
                                                    count: selectedImages.length
                                                })}
                                            </p>
                                            {selectedImages.length > 0 && (
                                                <Button variant={'tertiary'} size={'sm'} onClick={unselectAll}>
                                                    {t('commons:unselect_all')}
                                                </Button>
                                            )}
                                        </Flexbox>
                                    )
                                )}
                            </StyledCurationSleElementHeaderCounter>
                        )}
                    </StyledCurationSleElementHeader>

                    <StyledCurationElementImagesContainer>
                        {isFetching && [...Array(20).keys()].map(e => <ImageCard asSkeleton key={`${e}-id`} />)}
                        {!isFetching &&
                            remappedImages.map((image, index, array) => {
                                const isSelected = selectedImages.some(selectedImageId => selectedImageId == image.id)
                                return (
                                    <ImageCard
                                        image={image}
                                        key={image.id}
                                        isSelected={isSelected}
                                        thumbnail={image.thumbnails?.xs}
                                        endSlot={<ExpandImageButton onClick={() => onExpandGallerys(image.id)} />}
                                        onSelect={isShiftPressed => {
                                            handleImagesRangeSelect({
                                                array,
                                                image,
                                                index,
                                                isSelected,
                                                isShiftPressed,
                                                lastSelectedImageId,
                                                selectImages: selectImages,
                                                setLastSelectedImageId,
                                                unselectImages: unselectImages
                                            })
                                        }}
                                    />
                                )
                            })}
                    </StyledCurationElementImagesContainer>
                </PhotosetStepBodyContainer>
                <PageFooter
                    startSlot={
                        <Flexbox align="center">
                            <p>
                                {t('commons:x_images_selected_one', {
                                    count: selectedImages.length
                                })}
                            </p>
                            {selectedImages.length > 0 && (
                                <Button variant={'tertiary'} size={'sm'} onClick={unselectAll}>
                                    {t('commons:unselect_all')}
                                </Button>
                            )}
                        </Flexbox>
                    }
                    endSlot={
                        <Button onClick={onSendToImagenAi} variant="primarySuccess" disabled={isFetching}>
                            {t('commons:done')}
                        </Button>
                    }
                />
            </PhotosetStepWrapper>
            {currentDetailImageIndex != null && images && (
                <GalleryCurationModal
                    currentDetailImageIndex={currentDetailImageIndex}
                    setCurrentDetailImageIndex={setCurrentDetailImageIndex}
                    selectableImages={images}
                    onClose={() => setCurrentDetailImageIndex(null)}
                />
            )}
        </StyledPhotosetCurationV1Page>
    )
}

PhotosetCurationStepV1.displayName = 'PhotosetCurationStepV1'
