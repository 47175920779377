import {z} from 'zod'

export const RESET_PASSWORD_FORM_MODEL = {
    email: {
        name: 'email',
        label: 'login:form:email',
        placeholder: 'login:form:email',
        defaultValue: '',
        helpText: ''
    }
} as const

export const ResetPasswordFormSchema = z.object({
    email: z.string().min(1, {message: 'errors:email'}).email({
        message: 'errors:email_valid'
    })
})

export type ResetPasswordValidationSchema = z.infer<typeof ResetPasswordFormSchema>
