import {Spinner} from '@/components/ui/spinner/Spinner'
import {useTheme} from 'styled-components'
import {
    StyledPhotosetGeneratingContainer,
    StyledPhotosetGeneratingCopyWrapper,
    StyledPhotosetGeneratingParapraph,
    StyledPhotosetGeneratingTitle
} from './style'

export const PhotosetGenerating = ({title, subtitle}: {title: string; subtitle: string}) => {
    const {palette} = useTheme()

    return (
        <StyledPhotosetGeneratingContainer>
            <StyledPhotosetGeneratingCopyWrapper direction="column" gap={3} align="center">
                <Spinner size={32} color={palette.neutral.black} />
                <StyledPhotosetGeneratingTitle>{title}</StyledPhotosetGeneratingTitle>
                <StyledPhotosetGeneratingParapraph>{subtitle}</StyledPhotosetGeneratingParapraph>
            </StyledPhotosetGeneratingCopyWrapper>
        </StyledPhotosetGeneratingContainer>
    )
}
