import {useMutation, UseMutationOptions} from '@tanstack/react-query'
import {httpUpdateStepToRetouchingQa} from '@/features/photoset/services/photoset.http.ts'
import {MUTATION_KEYS} from '@/queryClient.ts'
import {AxiosError} from 'axios'

export const useUpdateStepToRetouchingQa = (
    options?: Omit<UseMutationOptions<unknown, AxiosError, number>, 'mutationFn' | 'mutationKey'>
) =>
    useMutation({
        ...options,
        mutationKey: [MUTATION_KEYS.UPDATE_STEP_RETOUCHING_QA],
        mutationFn: httpUpdateStepToRetouchingQa
    })
