import {Button} from '@/components/ui/button/Button'
import styled, {css} from 'styled-components'

export const StyledExpandImageButton = styled(Button)(
    ({theme: {spacing}}) => css`
        position: absolute;
        top: ${spacing * 8}px;
        right: ${spacing * 8}px;
    `
)
