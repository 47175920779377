import {PageHero} from '@/components/commons/page-hero/PageHero'
import {Button} from '@/components/ui/button/Button'
import {ArrowLeftIcon, DownloadCloud02Icon, ImageDownIcon, ImageUpIcon} from '@/components/ui/icon/Icon'
import {
    PhotosetStepCardsContainer,
    PhotosetStepWrapper
} from '@/features/photoset/components/photoset-step-atoms/PhotosetStepAtoms'
import {useTranslation} from 'react-i18next'
import {ManualRetouchingCard} from '@/features/photoset/components/manual-retouching-card/ManualRetouchingCard'
import {useOutletContext} from 'react-router-dom'
import {PhotosetURLParams} from '@/features/photoset/types'
import {useGetPhotoset} from '@/features/photoset/services/useGetPhotoset'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {PageFooter} from '@/components/commons/page-footer/PageFooter'
import {errorHandler} from '@/utilities/helpers'
import {QUERY_KEYS, queryClient} from '@/queryClient'
import {ButtonLink} from '@/components/ui/button-link/ButtonLink'
import {useUpdateStepToNeedsReview} from '@/features/photoset/services/useUpdateStepToNeedsReview'
import {StyledRetouchingRevisionDownload} from './style'
import {ManualRetouchingUploader} from '@/features/photoset/components/manual-retouching-uploader/ManualRetouchingUploader'
import {useUpdateStepToRetouchingRevision} from '@/features/photoset/services/useUpdateStepToRetouchingRevision'
import {useEffect, useState} from 'react'
import {StepHeroControls} from '@/features/photoset/components/step-hero-controls/StepHeroControls'

export const PhotosetRetouchingRevisionUploadStep = () => {
    const {t} = useTranslation()
    const {urlParams} = useOutletContext<{urlParams: PhotosetURLParams}>()
    const [isGenerationThumbsCompleted, setIsGenerationThumbsCompleted] = useState(false)

    // queries
    const {data: photoset} = useGetPhotoset(
        {id: urlParams.id},
        {
            refetchInterval: () => (!isGenerationThumbsCompleted ? 10000 : false)
        }
    )

    // mutations
    const {mutate: updateStepToNeedsReview, isPending: isPendingUpdateStepToNeedsReview} = useUpdateStepToNeedsReview({
        onError: errorHandler,
        onSuccess: () => {
            void queryClient.invalidateQueries({queryKey: [QUERY_KEYS.PHOTOSET]})
            void queryClient.invalidateQueries({queryKey: [QUERY_KEYS.PHOTOSET_SPACES]})
        }
    })

    const updateStepToRetouchingRevisionMutation = useUpdateStepToRetouchingRevision({
        onError: errorHandler,
        onSuccess: () => {
            void queryClient.invalidateQueries({queryKey: [QUERY_KEYS.PHOTOSET]})
            void queryClient.invalidateQueries({queryKey: [QUERY_KEYS.PHOTOSET_SPACES]})
        }
    })

    useEffect(() => {
        if (photoset?.need_retouching_zip_url) {
            setIsGenerationThumbsCompleted(true)
        }
    }, [photoset?.need_retouching_zip_url])

    return (
        <>
            <PageHero
                title={t('photoset:retouching_revision:title')}
                subtitle={t('photoset:retouching_revision:upload_subtitle')}
                endSlot={<StepHeroControls hideSortControls />}
            />
            <PhotosetStepWrapper>
                <PhotosetStepCardsContainer>
                    <ManualRetouchingCard
                        icon={<ImageDownIcon size={40} />}
                        title={t('commons:download_title')}
                        subtitle={t('photoset:retouching_revision:download_subtitle')}
                        paragraph={t('photoset:retouching_revision:download_paragraph')}
                        endSlot={
                            <StyledRetouchingRevisionDownload direction="column" align="center" gap={3}>
                                <ButtonLink
                                    disabled={!photoset?.need_retouching_zip_url}
                                    to={photoset?.need_retouching_zip_url ?? {}}
                                >
                                    {photoset?.need_retouching_zip_url ? (
                                        <>
                                            <DownloadCloud02Icon size={20} />
                                            <span>
                                                {t('commons:download_image', {
                                                    count: photoset.need_retouching_zip_images_count
                                                })}
                                            </span>
                                        </>
                                    ) : (
                                        <>
                                            <Spinner size={20} />
                                            <span>{t('commons:creating_zip')}</span>
                                        </>
                                    )}
                                </ButtonLink>
                                <p>{t('commons:download_pending_paragraph')}</p>
                            </StyledRetouchingRevisionDownload>
                        }
                    />

                    <ManualRetouchingCard
                        icon={<ImageUpIcon size={40} />}
                        title={t('commons:upload_retouched_images')}
                        subtitle={t('photoset:retouching_revision:upload_subtitle')}
                        paragraph={t('commons:upload_jpgs_name_info')}
                        endSlot={
                            <ManualRetouchingUploader
                                photosetId={urlParams.id}
                                isDisabled={!photoset?.need_retouching_zip_url}
                                onUploadCb={updateStepToRetouchingRevisionMutation.mutateAsync}
                            />
                        }
                    />
                </PhotosetStepCardsContainer>

                <PageFooter
                    startSlot={
                        <Button
                            onClick={() => updateStepToNeedsReview(urlParams.id)}
                            variant="tertiary"
                            disabled={isPendingUpdateStepToNeedsReview || isPendingUpdateStepToNeedsReview}
                        >
                            <ArrowLeftIcon />
                            {t('photoset:needs_review:title')}
                        </Button>
                    }
                />
            </PhotosetStepWrapper>
        </>
    )
}
