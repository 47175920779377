import {FC} from 'react'
import {Image} from '@/features/photoset/types'
import {ImageCard} from '@/components/commons/image-card/ImageCard'
import {Button} from '@components/ui/button/Button.tsx'
import {useTranslation} from 'react-i18next'
import {CheckIcon, ThumbsDownIcon} from '@components/ui/icon/Icon.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {StyledFinalSelectedBadge} from '@/features/photoset/components/editing-qa-photoset-space-images/style.ts'
import {errorHandler} from '@utilities/helpers.ts'
import {useImageSelectionApproval} from '@/features/photoset/services/useImageSelectionApproval.ts'
import {
    SpaceImageFrame,
    SpaceImagesContainer,
    SpaceImageSelectionInfo
} from '@/features/photoset/components/space-images-atoms/SpaceImagesAtoms'

interface EditingQaSelectionSpaceImagesProps {
    images: Omit<Image, 'space'>[]
    photosetId: number
    spaceId: number
    isQaView: boolean
}

export const EditingQaSelectionSpaceImages: FC<EditingQaSelectionSpaceImagesProps> = ({
    images,
    photosetId,
    spaceId,
    isQaView
}) => {
    const {t} = useTranslation()
    const {mutate: imageSelectionApprovalToggle} = useImageSelectionApproval({
        photosetId,
        photosetSpaceId: spaceId,
        options: {
            onError: errorHandler
        }
    })

    const onToggleClick = (image: Omit<Image, 'space'>) => {
        imageSelectionApprovalToggle({
            imageId: image.id,
            curation_approved: !image.curation_approved
        })
    }

    return (
        <SpaceImagesContainer $gap={4}>
            {images.map(image => (
                <SpaceImageFrame $isSelected={image.is_final_select} key={image.id}>
                    <ImageCard
                        image={image}
                        thumbnail={image.thumbnails?.xs}
                        isSelected={false}
                        hideImageInfo
                        endSlot={
                            <SpaceImageSelectionInfo direction={'column'} gap={2} fullWidth>
                                <Flexbox gap={2} align="center" justify="space-between" render="section" fullWidth>
                                    <h4>{image.name}</h4>
                                    {image.is_final_select && (
                                        <StyledFinalSelectedBadge gap={1} align="center" justify="space-between">
                                            <span>
                                                <CheckIcon size={12} />
                                            </span>
                                            <p>{t('commons:selected')}</p>
                                        </StyledFinalSelectedBadge>
                                    )}
                                </Flexbox>
                                <Button
                                    fullWidth
                                    variant={image.curation_approved ? 'secondary' : 'secondaryDanger'}
                                    size={'sm'}
                                    disabled={!isQaView}
                                    onClick={() => onToggleClick(image)}
                                >
                                    <ThumbsDownIcon />
                                    {t(image.curation_approved ? 'commons:reject' : 'commons:rejected')}
                                </Button>
                            </SpaceImageSelectionInfo>
                        }
                    />
                </SpaceImageFrame>
            ))}
        </SpaceImagesContainer>
    )
}
