import styled, {css} from 'styled-components'

export const StyledRadioInputWrapper = styled.label(
    ({theme: {spacing, palette, typography}}) => css`
        display: flex;
        gap: ${spacing * 3}px;
        cursor: pointer;

        & > p {
            ${typography.textMd};
            color: ${palette.neutral['900']};
            word-break: break-word;
        }
    `
)

export const StyledRadio = styled.input<{labelPosition: 'left' | 'right'}>(
    ({labelPosition, theme: {palette, shadows, spacing}}) => css`
        flex: none;
        order: ${labelPosition == 'left' ? '2' : '0'};
        background-color: ${palette.neutral.white};
        width: 20px;
        height: 20px;
        padding: ${spacing}px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid ${palette.neutral['300']};
        transition: box-shadow ease-in-out 300ms;

        &:checked {
            border: 6px solid ${palette.primary['600']};
        }

        &:disabled:checked {
            border: 6px solid ${palette.neutral['300']};
            cursor: not-allowed;
        }

        &:disabled {
            background: ${palette.neutral['100']};
            border: 1px solid ${palette.neutral['300']};
            cursor: not-allowed;
        }

        &:not(:disabled):hover {
            box-shadow: ${`${shadows.xs}, 0px 0px 0px 4px ${palette.primary['100']}`};
        }
    `
)
