import styled, {css} from 'styled-components'

export const StyledSpaceImagesContainer = styled.div<{$gap?: number}>(
    ({theme: {spacing, mediaQueries}, $gap = 0}) => css`
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        width: 100%;
        margin: ${spacing * 3}px 0;
        gap: ${$gap * spacing}px;

        ${mediaQueries.xl} {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }

        ${mediaQueries.l} {
            grid-template-columns: 1fr;
        }
    `
)

export const StyledSpaceImageFrame = styled.div<{$isSelected: boolean}>(
    ({$isSelected, theme: {spacing, palette}}) => css`
        & > div {
            box-shadow: ${$isSelected ? `0 0 0 4px ${palette.neutral['700']}` : 'unset'};
            border-radius: 12px;
            padding: ${spacing * 3}px;
            justify-content: flex-start;
            height: 100%;
            &:focus-within {
                background-color: unset;
                box-shadow: ${$isSelected ? `0 0 0 4px ${palette.neutral['700']}` : 'unset'};
            }
        }
    `
)
