import {MUTATION_KEYS} from '@/queryClient.ts'
import {useMutation} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {ErrorResponseData} from '@/types/commons.ts'
import {httpResetPassword} from '@/features/auth/services/auth.http.ts'

type useResetPasswordProps = {
    onSuccess?: () => void
    onError?: (error: AxiosError<ErrorResponseData>) => void
}

export const useResetPassword = (options?: useResetPasswordProps) => {
    return useMutation({
        mutationKey: [MUTATION_KEYS.RESET_PASSWORD],
        mutationFn: (params: {email: string}) => httpResetPassword(params),
        onError: (error: AxiosError<ErrorResponseData>) => options?.onError?.(error),
        onSuccess: () => options?.onSuccess?.()
    })
}
