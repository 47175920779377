import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import styled, {css} from 'styled-components'

export const StyledManualRetouchingUploaderCard = styled(Flexbox)(
    ({theme: {spacing, palette}}) => css`
        padding: ${spacing * 4}px;
        border: 1px solid ${palette.neutral[200]};
        border-radius: 16px;
    `
)

export const StyledManualRetouchingUploaderTitle = styled.h4(
    ({theme: {typography, palette}}) => css`
        ${typography.textSm};
        font-weight: 500;
        color: ${palette.neutral[700]};
    `
)

export const StyledManualRetouchingUploaderSubtitle = styled.p(
    ({theme: {typography, palette}}) => css`
        ${typography.textSm};
        color: ${palette.neutral[600]};
    `
)
