import {useLocation, useParams} from 'react-router-dom'

export const useCurrentPhotosetSubRoute = () => {
    const {id} = useParams()
    const location = useLocation()

    if (!id) {
        return null
    }

    // Check the current path to determine if it's `2la`, `qa` or `editing`
    if (location.pathname.includes(`/photoset/${id}/2la`)) {
        return '2la'
    } else if (location.pathname.includes(`/photoset/${id}/qa`)) {
        return 'qa'
    } else if (location.pathname.includes(`/photoset/${id}/editing`)) {
        return 'editing'
    }

    return null
}
