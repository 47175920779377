import {Image} from '@/features/photoset/types.ts'
import {useHotkeys} from 'react-hotkeys-hook'
import {Dispatch, SetStateAction} from 'react'

type useGalleryInitialQaShortcuts = {
    images: Omit<Image, 'space'>[]
    currentDetailImageIndex: number
    setCurrentDetailImageIndex: Dispatch<SetStateAction<number | null>>
    onClose: () => void
}

export const useGalleryInitialQaShortcuts = (args: useGalleryInitialQaShortcuts) => {
    const {currentDetailImageIndex, setCurrentDetailImageIndex, images, onClose} = args
    useHotkeys(
        'ArrowLeft',
        () => {
            setCurrentDetailImageIndex(currentDetailImageIndex - 1)
        },
        {enabled: currentDetailImageIndex >= 1},
        [currentDetailImageIndex]
    )

    useHotkeys(
        'ArrowRight',
        () => {
            setCurrentDetailImageIndex(currentDetailImageIndex + 1)
        },
        {enabled: currentDetailImageIndex < images.length - 1},
        [currentDetailImageIndex]
    )

    useHotkeys('Esc', onClose)
}
