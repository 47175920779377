import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import styled, {css} from 'styled-components'

export const StyledGalleryMetadata = styled(Flexbox)(
    ({theme: {typography, spacing, palette}}) => css`
        ${typography.textXs};
        padding: ${spacing * 1.5}px ${spacing * 3}px;
        margin: 0 ${spacing * 3}px;
        border-bottom: 1px solid ${palette.neutral['200']};
    `
)

export const StyledGalleryMetadataLabel = styled.div`
    min-width: 110px;
`

export const StyledGalleryMetadataValue = styled.div(
    ({theme: {typography, palette}}) => css`
        ${typography.textXs};
        font-weight: 500;
        color: ${palette.neutral['700']};
    `
)

export const StyledUnavailableMetadata = styled.p(
    ({theme: {spacing}}) => css`
        padding: ${spacing * 6}px;
    `
)
