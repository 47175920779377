import {
    StyledHdrChip,
    StyledImageCard,
    StyledImageCardImage,
    StyledImageCardInfoName,
    StyledImageCardLoadingText,
    StyledImageCardPlaceholder
} from '@components/commons/image-card/style.ts'
import {FC, ReactNode, useMemo} from 'react'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {Checkbox} from '@components/commons/checkbox/ChekBox.tsx'
import {Image} from '@/features/photoset/types.ts'
import {Skeleton} from '@components/ui/skeleton/Skeleton.tsx'
import {useTranslation} from 'react-i18next'
import {ImageXIcon} from '@/components/ui/icon/Icon'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {useTheme} from 'styled-components'
import {BadgeType, ImageBadge} from '@/features/photoset/components/image-badge/ImageBadge'

interface ImageCardProps {
    asSkeleton?: undefined
    onSelect?: ((isShiftPressed: boolean) => void) | null
    isSelected: boolean
    image: Omit<Image, 'space'>
    thumbnail?: string | null
    className?: string
    isLoading?: boolean
    loadingText?: string
    endSlot?: ReactNode
    imageNameIcon?: ReactNode
    hideImageInfo?: boolean
    hideCheckbox?: boolean
    showBadge?: boolean
    badgeType?: BadgeType
}

interface ImageCardSkeletonProps {
    asSkeleton: true
    onSelect?: undefined
    isSelected?: undefined
    image?: undefined
    thumbnail?: undefined
    isLoading?: false
    loadingText?: undefined
    className?: string
    endSlot?: ReactNode
    imageNameIcon?: ReactNode
    hideImageInfo?: boolean
    hideCheckbox?: boolean
    badgeType?: undefined
    showBadge?: boolean
}

export const ImageCard: FC<ImageCardProps | ImageCardSkeletonProps> = ({
    className,
    onSelect,
    isSelected,
    image,
    thumbnail,
    asSkeleton,
    isLoading,
    loadingText,
    endSlot,
    imageNameIcon,
    hideImageInfo = false,
    hideCheckbox = false,
    badgeType,
    showBadge
}) => {
    const {t} = useTranslation()
    const isMergedHdr = useMemo(() => image?.is_hdr, [image])
    const {palette} = useTheme()

    if (asSkeleton) {
        return (
            <StyledImageCard className={className} direction="column" gap={4.5} align="stretch">
                <Skeleton style={{aspectRatio: '3/2'}} />
                <Flexbox gap={2} align="center" justify="space-between">
                    <Skeleton width={80} height={20} />
                    <Skeleton width={20} height={20} />
                </Flexbox>
            </StyledImageCard>
        )
    }

    return (
        <StyledImageCard className={className} direction="column" gap={4.5} align="stretch">
            {isMergedHdr && <StyledHdrChip> {t('commons:merged_hdr')} </StyledHdrChip>}
            {thumbnail ? (
                <StyledImageCardImage
                    src={thumbnail}
                    $isSelected={isSelected}
                    $isLoading={isLoading}
                    $isClickable={!!onSelect}
                    draggable={false}
                    alt={image.name}
                    loading="lazy"
                    onClick={event => !isLoading && onSelect?.(event.shiftKey)}
                />
            ) : (
                <StyledImageCardPlaceholder
                    onClick={event => !isLoading && onSelect?.(event.shiftKey)}
                    $isSelected={isSelected}
                    direction="column"
                    align="center"
                    justify="center"
                    gap={2}
                >
                    <ImageXIcon size={18} />
                    <p>{t('errors:unavailable', {subject: 'preview'})}</p>
                </StyledImageCardPlaceholder>
            )}

            <Flexbox direction="column" gap={4}>
                {!hideImageInfo && (
                    <Flexbox gap={2} align="center" justify="space-between" render="section" fullWidth>
                        <StyledImageCardInfoName gap={2}>
                            {imageNameIcon}
                            <h4>{image.name}</h4>
                        </StyledImageCardInfoName>
                        {!hideCheckbox ? (
                            !isLoading ? (
                                <Checkbox
                                    disabled={isLoading}
                                    onChange={event =>
                                        'shiftKey' in event.nativeEvent
                                            ? onSelect?.(Boolean(event.nativeEvent.shiftKey))
                                            : onSelect?.(false)
                                    }
                                    checked={isSelected}
                                />
                            ) : loadingText ? (
                                <StyledImageCardLoadingText gap={2} align="center">
                                    <Spinner size={20} color={palette.neutral[400]} />
                                    <span>{loadingText}</span>
                                </StyledImageCardLoadingText>
                            ) : (
                                <Spinner size={20} color={palette.neutral[400]} />
                            )
                        ) : null}

                        {((hideCheckbox && isSelected) || showBadge) && badgeType && <ImageBadge type={badgeType} />}
                    </Flexbox>
                )}
                {endSlot}
            </Flexbox>
        </StyledImageCard>
    )
}

ImageCard.displayName = 'ImageCard'
