import styled, {css} from 'styled-components'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'

export const HeaderWrapper = styled.div`
    ${({theme: {palette}}) => css`
        box-sizing: border-box;
        background: ${palette.neutral.white};
        height: 64px;
    `}
`

export const StyledHeaderContent = styled(Flexbox)`
    height: 100%;
`

export const MenuItems = styled.div`
    ${() => css`
        display: flex;
        align-items: center;
        gap: 16px;
    `}
`

export const DropdownUserInfosItems = styled.div`
    ${({theme: {spacing, palette, typography}}) => css`
        display: grid;
        align-items: center;
        gap: ${spacing}px;
        & span {
            ${typography.textSm};
            font-weight: 600;
            color: ${palette.neutral['700']};
        }
        & small {
            ${typography.textSm};
            font-weight: 400;
            color: ${palette.neutral['600']};
        }
        & p {
            ${typography.textXs};
            color: ${palette.neutral['400']};
        }
    `}
`
