import {ButtonLink} from '@/components/ui/button-link/ButtonLink'
import {ClockIcon, ImageCheckIcon} from '@/components/ui/icon/Icon'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {useGetPhotoset} from '@/features/photoset/services/useGetPhotoset'
import {PhotosetURLParams} from '@/features/photoset/types'
import {
    StyledCompletedCopyWrapper,
    StyledCompletedStepsContainer,
    StyledCompletedStepsParapraph,
    StyledCompletedStepsTitle
} from '@/pages/photoset/photoset-completed-steps/style'
import {useTranslation} from 'react-i18next'
import {useOutletContext} from 'react-router-dom'
import {useTheme} from 'styled-components'

export const PhotosetInitialQaStepV1 = () => {
    const {t} = useTranslation()
    const theme = useTheme()
    const {urlParams} = useOutletContext<{urlParams: PhotosetURLParams}>()
    const photosetQuery = useGetPhotoset(
        {
            id: urlParams.id
        },
        {enabled: false}
    )

    return (
        <StyledCompletedStepsContainer>
            {photosetQuery.isFetching ? (
                <Spinner size={48} color={theme.palette.neutral[500]} />
            ) : (
                <>
                    {photosetQuery.data?.zip_url ? (
                        <ImageCheckIcon fill={theme.palette.neutral[900]} width={48} height={48} />
                    ) : (
                        <ClockIcon fill={theme.palette.neutral[900]} width={48} height={48} />
                    )}

                    <StyledCompletedCopyWrapper direction="column" gap={2} align="center">
                        <StyledCompletedStepsTitle>
                            {t('photoset:temporary_initial_qa:title')}
                        </StyledCompletedStepsTitle>
                        <StyledCompletedStepsParapraph>
                            {photosetQuery.data?.zip_url
                                ? t('photoset:temporary_initial_qa:paragraph')
                                : t('photoset:temporary_initial_qa:wait_paragraph')}
                        </StyledCompletedStepsParapraph>
                    </StyledCompletedCopyWrapper>

                    {photosetQuery.data?.zip_url && (
                        <ButtonLink to={photosetQuery.data.zip_url} size="lg" target="_blank">
                            {t('commons:download_zip')}
                        </ButtonLink>
                    )}
                </>
            )}
        </StyledCompletedStepsContainer>
    )
}

PhotosetInitialQaStepV1.displayName = 'PhotosetInitialQaStepV1'
