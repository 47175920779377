import {FC, useState} from 'react'
import {Trans, useTranslation} from 'react-i18next'
import {
    StyledCurationPhotosetSpaceCounter,
    StyledCurationPhotosetSpaceCounterWrapper,
    StyledCurrentRule,
    StyledPhotosetSpaceCounterButton,
    StyledUnselectAllImagesButton
} from '@/features/photoset/components/curation-photoset-space-counter/style'
import {PhotosetSpace, PhotosetSpacesChecks} from '@/features/photoset/types.ts'
import {AlertTriangleIcon, CheckIcon} from '@components/ui/icon/Icon.tsx'
import {usePhotosetStore} from '@/features/photoset/store/store'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {CurationPhotosetSpaceRulesModal} from '@/features/photoset/components/curation-photoset-space-rules-modal/CurationPhotosetSpaceRulesModal'

interface CurationPhotosetSpaceCounterProps {
    spaceChecks: PhotosetSpacesChecks
    curationTouched: boolean
    spaceIndex: number
    spaceFlagValue: PhotosetSpace['flag_key_name']
    photosetSpaceId: PhotosetSpace['id']
}

export const CurationPhotosetSpaceCounter: FC<CurationPhotosetSpaceCounterProps> = ({
    spaceChecks,
    curationTouched,
    spaceIndex,
    spaceFlagValue,
    photosetSpaceId
}) => {
    const {t} = useTranslation()
    const photosetSpaceImageUnselectAll = usePhotosetStore(state => state.photosetSpaceImageUnselectAll)
    const [ruleModal, showRuleModal] = useState(false)

    // Verify if this photoset space contains any errors according to the two curation rules: Sufficient Coverage or Quality
    const underMinimum = curationTouched && spaceChecks?.selectedCounter < spaceChecks?.minimumSelection
    const needsFlags =
        curationTouched && spaceChecks?.missingFlags && spaceChecks.selectedCounter < spaceChecks.minimumSelection
    const isSuccess = curationTouched && spaceChecks.selectedCounter >= spaceChecks.minimumSelection
    const isError =
        // Coverage rule
        (spaceFlagValue == 'sufficient_coverage' && underMinimum) ||
        // Quality rule
        (!isSuccess && underMinimum && needsFlags)

    return (
        <StyledCurationPhotosetSpaceCounterWrapper align={'center'} justify="space-between">
            <Flexbox align="center">
                <StyledCurationPhotosetSpaceCounter align={'center'} gap={2} $isError={isError} $isSuccess={isSuccess}>
                    {isError && <AlertTriangleIcon size={18} />}
                    {isSuccess && <CheckIcon size={18} />}
                    <p>
                        {t('photoset:curation:space:minimum_selected', {
                            selectedCount: spaceChecks?.selectedCounter,
                            minimumCount: spaceChecks?.minimumSelection
                        })}
                    </p>
                </StyledCurationPhotosetSpaceCounter>

                {spaceChecks?.selectedCounter > 0 && (
                    <StyledUnselectAllImagesButton
                        variant={'tertiary'}
                        size={'sm'}
                        onClick={() => photosetSpaceImageUnselectAll(spaceIndex)}
                    >
                        {t('commons:unselect_all')}
                    </StyledUnselectAllImagesButton>
                )}
            </Flexbox>

            <Flexbox align="center" gap={2}>
                <p>
                    <Trans
                        i18nKey={t('photoset:curation:validation_rule')}
                        components={{bold: <StyledCurrentRule />}}
                        values={{
                            rule:
                                spaceFlagValue == 'sufficient_coverage'
                                    ? t('photoset:curation:rules:coverage')
                                    : t('photoset:curation:rules:quality')
                        }}
                    />
                </p>

                <StyledPhotosetSpaceCounterButton size="sm" variant="tertiary" onClick={() => showRuleModal(true)}>
                    {t('photoset:curation:learn_more')}
                </StyledPhotosetSpaceCounterButton>
            </Flexbox>

            {ruleModal && (
                <CurationPhotosetSpaceRulesModal
                    photosetSpaceId={photosetSpaceId}
                    spaceFlagValue={spaceFlagValue}
                    hideModal={() => showRuleModal(false)}
                />
            )}
        </StyledCurationPhotosetSpaceCounterWrapper>
    )
}

CurationPhotosetSpaceCounter.displayName = 'CurationPhotosetSpaceCounter'
