import {CircularProgress} from '@/components/commons/circular-progress/CicularProgress'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {Image01Icon} from '@/components/ui/icon/Icon'
import {useTheme} from 'styled-components'
import {
    StyledManualRetouchingUploaderCard,
    StyledManualRetouchingUploaderTitle,
    StyledManualRetouchingUploaderSubtitle
} from './style'
import {useTranslation} from 'react-i18next'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {useMemo} from 'react'

export const ManualRetouchingUploadCard = ({
    totalUploadingImagesCounter,
    uploadedImagesCounter,
    isBulkUploadPhotosPending,
    title,
    subtitle
}: {
    progress: number // todo: remove it if not used
    totalUploadingImagesCounter: number
    uploadedImagesCounter: number
    isBulkUploadPhotosPending: boolean
    title: string
    subtitle: string
}) => {
    const {palette} = useTheme()
    const {t} = useTranslation()

    const percentageCompleted = useMemo(
        () => Math.round((uploadedImagesCounter * 100) / totalUploadingImagesCounter),
        [uploadedImagesCounter, totalUploadingImagesCounter]
    )

    return (
        <StyledManualRetouchingUploaderCard fullWidth justify="space-between" align="center">
            <Flexbox gap={2}>
                <Image01Icon size={16} color={palette.neutral['400']} />
                {isBulkUploadPhotosPending && !uploadedImagesCounter ? (
                    <Flexbox direction="column">
                        <StyledManualRetouchingUploaderTitle>{title}</StyledManualRetouchingUploaderTitle>
                        <StyledManualRetouchingUploaderSubtitle>{subtitle}</StyledManualRetouchingUploaderSubtitle>
                    </Flexbox>
                ) : (
                    <Flexbox direction="column">
                        <StyledManualRetouchingUploaderTitle>
                            {t('commons:upload_total_file', {count: totalUploadingImagesCounter})}
                        </StyledManualRetouchingUploaderTitle>
                        <StyledManualRetouchingUploaderSubtitle>
                            {t('commons:upload_percentage_file', {
                                percentage: isNaN(percentageCompleted) ? 0 : percentageCompleted
                            })}
                        </StyledManualRetouchingUploaderSubtitle>
                    </Flexbox>
                )}
            </Flexbox>

            {isBulkUploadPhotosPending && !uploadedImagesCounter ? (
                <Spinner size={32} color={palette.primary['600']} />
            ) : (
                <CircularProgress
                    progress={percentageCompleted}
                    background={palette.primary['200']}
                    strokeColor={palette.primary['600']}
                />
            )}
        </StyledManualRetouchingUploaderCard>
    )
}
