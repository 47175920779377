import {PageFooter} from '@/components/commons/page-footer/PageFooter'
import {PageHero} from '@/components/commons/page-hero/PageHero'
import {Button} from '@/components/ui/button/Button'
import {ArrowRightIcon} from '@/components/ui/icon/Icon'
import {PhotosetSpaceElement} from '@/features/photoset/components/photoset-space-element/PhotosetSpaceElement'
import {
    PhotosetStepBodyContainer,
    PhotosetStepWrapper
} from '@/features/photoset/components/photoset-step-atoms/PhotosetStepAtoms'
import {StepHeroControls} from '@/features/photoset/components/step-hero-controls/StepHeroControls'
import {VettingQaConfirmationModal} from '@/features/photoset/components/vetting-qa-confirmation-modal/VettingQaConfirmationModal'
import {VettingQaSpaceImages} from '@/features/photoset/components/vetting-qa-space-images/VettingQaSpaceImages'
import {useCurrentPhotosetSubRoute} from '@/features/photoset/hooks/useCurrentPhotosetSubRoute'
import {GET_PHOTOSET_IMAGES_LIST_RESPONSE_TYPE} from '@/features/photoset/services/useGetPhotosetImagesList'
import {useGetPhotosetSpaces} from '@/features/photoset/services/useGetPhotosetSpaces'
import {PhotosetURLParams} from '@/features/photoset/types'
import {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useOutletContext} from 'react-router-dom'

export const PhotosetVettingQaStep = () => {
    const {t} = useTranslation()
    const {urlParams} = useOutletContext<{urlParams: PhotosetURLParams}>()
    const currentPhotosetSubRoute = useCurrentPhotosetSubRoute()

    //statuses
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)

    // queries
    const {
        data: photosetSpaces,
        isFetching: isFetchingManualRetouchingSpaces,
        isError: isErrorManualRetouchingSpaces
    } = useGetPhotosetSpaces({
        urlParams: {
            photosetId: urlParams.id
        },
        params: {
            response_type: GET_PHOTOSET_IMAGES_LIST_RESPONSE_TYPE,
            is_final_select: true
        }
    })

    return (
        <>
            <PageHero
                title={t('photoset:vetting_qa:title')}
                subtitle={t('photoset:vetting_qa:subtitle')}
                endSlot={<StepHeroControls hideSortControls />}
            />

            {showConfirmationModal && (
                <VettingQaConfirmationModal photosetId={urlParams.id} onClose={() => setShowConfirmationModal(false)} />
            )}

            <PhotosetStepWrapper>
                <PhotosetStepBodyContainer fullWidth>
                    {isFetchingManualRetouchingSpaces &&
                        [0, 1, 2].map(e => <PhotosetSpaceElement asSkeleton key={`${e}-id`} />)}
                    {!isFetchingManualRetouchingSpaces &&
                        !isErrorManualRetouchingSpaces &&
                        photosetSpaces?.map(
                            photosetSpace =>
                                photosetSpace.images.length > 0 && (
                                    <PhotosetSpaceElement
                                        key={photosetSpace.space.id}
                                        spaceName={photosetSpace.space.key_name}
                                        endSlot={
                                            <VettingQaSpaceImages
                                                images={photosetSpace.images}
                                                photosetId={urlParams.id}
                                                photosetSpaceId={photosetSpace.id}
                                                isQaView={currentPhotosetSubRoute == 'qa'}
                                            />
                                        }
                                    />
                                )
                        )}
                </PhotosetStepBodyContainer>
            </PhotosetStepWrapper>
            {currentPhotosetSubRoute == 'qa' && (
                <PageFooter
                    centerSlot={<p>{t('photoset:vetting_qa:footer_step_label')}</p>}
                    endSlot={
                        <Button onClick={() => setShowConfirmationModal(true)}>
                            <span>{t('commons:retouching_qa')}</span>
                            <ArrowRightIcon />
                        </Button>
                    }
                />
            )}
        </>
    )
}
