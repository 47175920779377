import {Navigate, Outlet, useParams} from 'react-router-dom'
import {routes} from '@/utilities/constants/routes.tsx'
import {useAuthStore} from '@/features/auth/store/store.ts'
import {AuthRedirectLocationState} from '@/features/auth/types.ts'

export const PrivateRoute = () => {
    const accessToken = useAuthStore(state => state.accessToken)
    const {id} = useParams()

    return accessToken ? <Outlet /> : <Navigate to={routes.LOGIN.path} state={{id: id} as AuthRedirectLocationState} />
}
