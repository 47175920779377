import {ObjectValues} from '@/utilities/helpers'
import {usePhotosetStore} from '@/features/photoset/store/store'
import {StyledSpaceCardButton, StyledSpacesList} from './style'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {Button} from '@/components/ui/button/Button'
import {PlusIcon} from '@/components/ui/icon/Icon'
import {
    SpaceCardEmptyPreviewSlot,
    SpaceCardHeader,
    SpaceCardImagePreviewSlot,
    SpaceCardImagesPreviewGrid,
    SpaceCardWrapper
} from '@/features/photoset/components/space-card-atoms/SpaceCardAtoms'
import {spaceNameToLabel, spaceNameToShortcut} from '@/features/photoset/utils'
import {useTranslation} from 'react-i18next'
import {DeallocateImageButton} from '@/features/photoset/components/deallocate-image-button/DeallocateImageButton'
import {OnAllocateAction} from '@/features/photoset/types'

export const GalleryAllocationSidebar = ({onAllocate}: {onAllocate: (values: OnAllocateAction) => void}) => {
    const {t} = useTranslation()
    const images = usePhotosetStore(store => store.images)
    const photosetSpacesDict = usePhotosetStore(store => store.photosetSpacesDict)
    const selectedImages = usePhotosetStore(store => store.selectedImages)

    return (
        <StyledSpacesList direction="column" gap={8} align="stretch">
            {ObjectValues(photosetSpacesDict).map(photosetSpace => {
                const spaceImages = [...images.values()].filter(image => image.space?.id == photosetSpace.space.id)
                const imagesInPreview = spaceImages?.slice(-5).reverse() ?? []

                return (
                    <SpaceCardWrapper key={photosetSpace.space.key_name} hasImages={spaceImages.length >= 1}>
                        <SpaceCardHeader
                            headerTitle={spaceNameToLabel[photosetSpace.space.key_name]}
                            images={spaceImages}
                            shortcut={spaceNameToShortcut[photosetSpace.space.key_name]}
                        />
                        {spaceImages.length >= 1 ? (
                            <Flexbox gap={2} align="stretch">
                                <Button
                                    iconOnly
                                    variant="secondary"
                                    size="lg"
                                    fullWidth
                                    onClick={() =>
                                        onAllocate({
                                            photosetSpaceId: photosetSpace.id,
                                            imagesIds: selectedImages,
                                            trackInHistory: true,
                                            isShortcut: false,
                                            keepSelected: false
                                        })
                                    }
                                >
                                    <PlusIcon size={20} />
                                </Button>

                                <SpaceCardImagesPreviewGrid>
                                    {imagesInPreview.map(image => {
                                        const otherImagesCount = spaceImages.length - imagesInPreview.length
                                        return (
                                            <SpaceCardImagePreviewSlot
                                                key={image.id}
                                                image={image}
                                                otherImagesCount={otherImagesCount}
                                                overlayComponent={
                                                    <DeallocateImageButton
                                                        image={image}
                                                        photosetSpaceId={photosetSpace.id}
                                                        keepSelected={true}
                                                        trackInHistory={true}
                                                        showToast={false}
                                                    />
                                                }
                                            />
                                        )
                                    })}

                                    {Array.from({length: 5 - imagesInPreview.length}).map((_, index) => (
                                        <SpaceCardEmptyPreviewSlot key={index} />
                                    ))}
                                </SpaceCardImagesPreviewGrid>
                            </Flexbox>
                        ) : (
                            <StyledSpaceCardButton
                                variant="secondary"
                                fullWidth
                                onClick={() =>
                                    onAllocate({
                                        photosetSpaceId: photosetSpace.id,
                                        imagesIds: selectedImages,
                                        trackInHistory: true,
                                        isShortcut: false,
                                        keepSelected: false
                                    })
                                }
                            >
                                <PlusIcon size={20} />
                                <h3>{t('photoset:allocation:place_here')}</h3>
                            </StyledSpaceCardButton>
                        )}
                    </SpaceCardWrapper>
                )
            })}
        </StyledSpacesList>
    )
}
