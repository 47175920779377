import {Container} from '@/components/ui/container/Container'
import styled, {css} from 'styled-components'

export const StyledPhotosetStepWrapper = styled.div(
    ({theme: {palette}}) => css`
        position: relative;
        height: 100%;
        display: grid;
        grid-template-rows: minmax(0, 1fr) auto;
        overflow-y: hidden;
        background: ${palette.neutral[50]};
    `
)

export const StyledPhotosetStepBodyContainer = styled(Container)<{$disabled?: boolean}>(
    ({$disabled}) => css`
        overflow-y: scroll;
        transition: opacity 500ms ease-in-out;

        ${$disabled &&
        css`
            overflow: hidden;
            pointer-events: none;
            opacity: 0.4;
        `}
    `
)

export const StyledPhotosetStepGridContainer = styled(Container)`
    display: grid;
    grid-template-columns: minmax(0, 1fr) auto auto;
    grid-template-rows: minmax(0, 1fr);
`

export const StyledPhotosetStepCardsContainer = styled(Container)(
    ({theme: {spacing}}) => css`
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        align-items: center;
        gap: ${spacing * 6}px;
    `
)
