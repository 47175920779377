import {Expand01Icon} from '@/components/ui/icon/Icon'
import {StyledExpandImageButton} from '@/components/commons/expand-image-button/style'
import {FC} from 'react'

interface ExpandImageButtonProps {
    onClick: () => void
}

export const ExpandImageButton: FC<ExpandImageButtonProps> = ({onClick}) => (
    <StyledExpandImageButton
        tabIndex={-1}
        className="show-on-hover"
        variant="secondary"
        iconOnly
        size="sm"
        onClick={onClick}
    >
        <Expand01Icon />
    </StyledExpandImageButton>
)
