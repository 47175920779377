import {SelectItem, SelectPopover} from '@ariakit/react'
import styled, {css} from 'styled-components'

export const StyledSelectPopover = styled(SelectPopover)(
    ({theme: {palette, shadows, spacing}}) => css`
        background-color: ${palette.neutral.white};
        border: 1px solid ${palette.neutral[200]};
        border-radius: 8px;
        box-shadow: ${shadows.lg};
        padding: ${spacing}px ${spacing * 1.5}px;
    `
)

export const StyledSelectItem = styled(SelectItem)(
    ({theme: {palette, spacing, typography}}) => css`
        ${typography.textSm};
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: ${spacing * 2.5}px ${spacing * 2}px;
        cursor: pointer;
        border-radius: 6px;
        font-weight: 500;
        margin: 1px 0; /* used to space each list item */
        color: ${palette.neutral[900]};
        min-width: 100%;

        & span {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        & svg {
            fill: ${palette.primary[600]};
        }

        &[aria-selected='true'] {
            background-color: ${palette.neutral[100]};
        }

        &:not([aria-selected='true']):hover {
            background-color: ${palette.neutral[50]};
        }
    `
)
