import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const StyledImageCard = styled(Flexbox)(
    ({theme: {palette, spacing}}) => css`
        position: relative;
        padding: ${spacing * 6}px;
        &:focus-within {
            background-color: ${palette.neutral['100']};
            box-shadow: 0 0 0 1px ${palette.neutral['200']} inset;
        }

        &:not(:hover) {
            & .show-on-hover {
                display: none;
            }
        }
    `
)

export const StyledHdrChip = styled.div(
    ({theme: {palette, spacing}}) => css`
        position: absolute;
        top: 36px;
        background: ${palette.blue['500']};
        color: ${palette.neutral.white};
        padding: ${spacing}px ${spacing * 1.5}px;
        font-size: 10px;
        border-radius: 0 12px 12px 0;
        font-weight: 500;
    `
)

export const StyledImageCardImage = styled.img<{$isSelected?: boolean; $isLoading?: boolean; $isClickable?: boolean}>(
    ({theme: {palette, shadows}, $isSelected, $isLoading, $isClickable}) => css`
        display: block;
        background: ${palette.neutral.white};
        cursor: ${$isClickable ? 'pointer' : 'default'};
        aspect-ratio: 3/2;
        width: 100%;
        object-fit: contain;
        box-shadow: ${shadows.sm};
        border-radius: 12px;
        transition: opacity 250ms ease-in-out;
        ${$isSelected &&
        css`
            outline: ${palette.primary['500']} solid 4px;
            outline-offset: 4px;
        `}
        ${$isLoading &&
        css`
            opacity: 0.6;
        `}
    `
)

export const StyledImageCardPlaceholder = styled(Flexbox)<{$isSelected?: boolean}>(
    ({theme: {palette, shadows, typography}, $isSelected}) => css`
        ${typography.textSm};
        background: ${palette.neutral.white};
        cursor: pointer;
        border-radius: 12px;
        aspect-ratio: 3/2;
        width: 100%;
        font-weight: 500;
        box-shadow: ${shadows.sm};

        ${$isSelected &&
        css`
            outline: ${palette.primary['500']} solid 4px;
            outline-offset: 4px;
        `}
    `
)

export const StyledImageCardInfoName = styled(Flexbox)(
    ({theme: {typography, palette}}) => css`
        overflow: hidden;

        & h4 {
            flex-grow: 1;
            ${typography.textSm};
            font-weight: 500;
            color: ${palette.neutral['900']};
            max-width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 100%;
        }
    `
)

export const StyledImageCardLoadingText = styled(Flexbox)(
    ({theme: {typography}}) => css`
        ${typography.textXs};
        font-weight: 500;
        white-space: nowrap;
    `
)
