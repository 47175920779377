import {ReactNode} from 'react'
import {StyledPageHeaderContainer, StyledPageHeaderText} from '@components/commons/page-hero/style.ts'
import {Container} from '@components/ui/container/Container.tsx'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'

interface PageHeaderProps {
    title: string
    subtitle?: string
    endSlot?: ReactNode
}

export const PageHero = ({title, subtitle, endSlot}: PageHeaderProps) => {
    return (
        <StyledPageHeaderContainer>
            <Container fullWidth>
                <Flexbox justify="space-between" align="center">
                    <StyledPageHeaderText gap={2}>
                        <h1>{title}</h1>
                        {subtitle && <p>{subtitle}</p>}
                    </StyledPageHeaderText>
                    {endSlot && <div>{endSlot}</div>}
                </Flexbox>
            </Container>
        </StyledPageHeaderContainer>
    )
}

PageHero.displayName = 'PageHero'
