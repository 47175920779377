import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const StyledWelcomeWrapper = styled.div`
    height: 100%;
    display: grid;
    grid-template-rows: auto 1fr;
    overflow: hidden;
`

export const StyledWelcomeContainer = styled.div(
    ({theme: {palette}}) => css`
        height: 100%;
        display: grid;
        grid-template-rows: 1fr;
        overflow: hidden;
        align-items: center;
        justify-content: center;
        background: ${palette.neutral[50]};
    `
)

export const StyledWelcomeContent = styled(Flexbox)`
    ${({theme: {spacing, typography, palette}}) => css`
        width: 392px;
        text-align: center;

        & > section {
            padding: ${spacing * 6}px 0;

            & h1 {
                ${typography.displayXs};
                font-weight: 600;
                color: ${palette.neutral['900']};
            }

            & p {
                ${typography.textXl};
                color: ${palette.neutral['600']};
            }
        }
    `}
`
