import {
    DropdownMenuContentProps,
    DropdownMenuItemProps,
    DropdownMenuLabelProps,
    DropdownMenuSeparatorProps
} from '@radix-ui/react-dropdown-menu'
import {
    StyledDropdownContent,
    StyledDropdownItem,
    StyledDropdownLabel,
    StyledDropdownSeparator
} from '@components/ui/dropdown-atoms/style.ts'
import {forwardRef} from 'react'

export const DropdownContent = forwardRef<HTMLDivElement, DropdownMenuContentProps>((props, ref) => (
    <StyledDropdownContent ref={ref} {...props} />
))
DropdownContent.displayName = 'DropdownContent'

export const DropdownLabel = (props: DropdownMenuLabelProps) => <StyledDropdownLabel {...props} />
DropdownLabel.displayName = 'DropdownLabel'

export const DropdownSeparator = (props: DropdownMenuSeparatorProps) => <StyledDropdownSeparator {...props} />
DropdownSeparator.displayName = 'DropdownSeparator'

export const DropdownItem = (props: DropdownMenuItemProps) => <StyledDropdownItem {...props} />
DropdownItem.displayName = 'DropdownItem'
