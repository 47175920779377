import {SpaceCard} from '@/features/photoset/components/space-card/SpaceCard.tsx'
import {Dispatch, FC, SetStateAction, useMemo} from 'react'
import {StyledSpacesList, StyledSpacesSidebar} from '@/features/photoset/components/spaces-sidebar/style.ts'
import {useTranslation} from 'react-i18next'
import {ScrollArea} from '@components/commons/scroll-area/ScrollArea.tsx'
import {Scrollbar} from '@components/ui/scrollbar/Scrollbar.tsx'
import {UseQueryResult} from '@tanstack/react-query'
import {ErrorBox} from '@components/commons/error-box/ErrorBox.tsx'
import {AlertCircleIcon} from '@components/ui/icon/Icon.tsx'
import {usePhotosetStore} from '@/features/photoset/store/store.ts'
import {spaceNameToShortcut, spaceNameToLabel} from '@/features/photoset/utils.ts'
import {ListHeader} from '@components/ui/list-header/ListHeader.tsx'
import {SpaceDetailModal} from '@/features/photoset/components/space-detail-modal/SpaceDetailModal.tsx'
import {ObjectValues} from '@/utilities/helpers'

interface SpacesSidebarProps {
    queryStatuses: UseQueryResult['status'][]
    currentSpaceIndex: number | null
    setCurrentSpaceIndex: Dispatch<SetStateAction<number | null>>
}

export const SpacesSidebar: FC<SpacesSidebarProps> = ({
    queryStatuses = [],
    currentSpaceIndex,
    setCurrentSpaceIndex
}) => {
    const {t} = useTranslation()
    const photosetSpacesDict = usePhotosetStore(state => state.photosetSpacesDict)
    const images = usePhotosetStore(state => state.images)
    const unselectAll = usePhotosetStore(state => state.unselectAll)
    const photosetSpaces = useMemo(() => ObjectValues(photosetSpacesDict), [photosetSpacesDict])

    return (
        <StyledSpacesSidebar>
            <ListHeader size="sm" title={t('photoset:allocation:sidebar_title')} />
            <ScrollArea style={{overflow: 'hidden'}} scrollbar={<Scrollbar />}>
                <StyledSpacesList direction="column" gap={8} align="stretch">
                    {queryStatuses.includes('pending') ? (
                        Array.from({length: 6}).map((_, index) => <SpaceCard asSkeleton key={index} />)
                    ) : queryStatuses.includes('error') ? (
                        <ErrorBox icon={<AlertCircleIcon />} title={t('errors:default')} />
                    ) : (
                        photosetSpaces.map((photosetSpace, index) => (
                            <SpaceCard
                                id={photosetSpace.id}
                                spaceImages={[...images.values()].filter(
                                    image => image.space?.key_name == photosetSpace.space.key_name
                                )}
                                openSpaceDetailModal={() => {
                                    unselectAll()
                                    setCurrentSpaceIndex(index)
                                }}
                                shortcut={spaceNameToShortcut[photosetSpace.space.key_name]}
                                title={spaceNameToLabel[photosetSpace.space.key_name]}
                                photosetSpaceId={photosetSpace.id}
                                key={photosetSpace.id}
                            />
                        ))
                    )}
                </StyledSpacesList>
            </ScrollArea>
            {currentSpaceIndex != null && (
                <SpaceDetailModal
                    photosetSpaces={photosetSpaces}
                    currentSpaceIndex={currentSpaceIndex}
                    setCurrentSpaceIndex={setCurrentSpaceIndex}
                    onClose={() => {
                        setCurrentSpaceIndex(null)
                        unselectAll()
                    }}
                />
            )}
        </StyledSpacesSidebar>
    )
}

SpacesSidebar.displayName = 'SpacesSidebar'
