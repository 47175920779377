import {ConfirmActionModal} from '@/components/commons/confirm-action-modal/ConfirmActionModal'
import {routes} from '@/utilities/constants/routes'
import {errorHandler} from '@/utilities/helpers'
import {useTranslation} from 'react-i18next'
import {generatePath, useNavigate} from 'react-router-dom'
import {Photoset} from '@/features/photoset/types'
import {useUpdateStepToRetouchingQaCompleted} from '@/features/photoset/services/useUpdateStepToRetouchingQaCompleted'
import {QUERY_KEYS, queryClient} from '@/queryClient'

export const RetouchingQaConfirmationModal = ({
    photosetId,
    onClose
}: {
    photosetId: Photoset['id']
    onClose: () => void
}) => {
    const {t} = useTranslation()
    const navigate = useNavigate()

    const updateStepToRetouchingQaCompletedMutation = useUpdateStepToRetouchingQaCompleted({
        onError: errorHandler,
        onSuccess: () => {
            void queryClient.invalidateQueries({queryKey: [QUERY_KEYS.PHOTOSET]})

            navigate(
                generatePath(routes.PHOTOSET_RETOUCHING_QA_COMPLETED.path, {
                    id: photosetId
                })
            )
        }
    })

    return (
        <ConfirmActionModal
            isLoading={updateStepToRetouchingQaCompletedMutation.isPending}
            onClose={onClose}
            onConfirm={() => updateStepToRetouchingQaCompletedMutation.mutate(photosetId)}
            headerTitle=""
            title={t('photoset:vetting_qa:confirmation_modal_title')}
            paragraph={t('photoset:vetting_qa:confirmation_modal_paragraph')}
        />
    )
}
