import {z} from 'zod'

export const Space = z.object({
    id: z.number().positive().int(),
    key_name: z.enum([
        'bathroom',
        'bedroom_1',
        'bedroom_2',
        'bedroom_3',
        'bedroom_4',
        'bedroom_5',
        'bedroom_6',
        'bedroom_7',
        'bedroom_8',
        'bedroom_9',
        'bedroom_10',
        'common_spaces',
        'dining_room',
        'exterior',
        'kitchen',
        'living_room',
        'office_study',
        'other',
        'outdoor_space',
        'pool_host_tub'
    ])
})
export type Space = z.infer<typeof Space>

export const Flag = z.object({
    id: z.number(),
    type: z.enum(['metadata', 'visual', 'visual_metadata', 'visual_retouching']),
    key_name: z.enum([
        'metering_issue',
        'duplicated',
        'file_format',
        'camera_height_issue',
        'tilted_tripod',
        'wide_tight_distortion',
        'no_lens_hood',
        'dirty_lens_sensor',
        'focus_issues',
        'bracket_merging_issue',
        'no_straight_ons',
        'missing_space',
        'low_image_count',
        'messy_chords',
        'closed_shower_curtains',
        'curtains_closed',
        'artificial_lighting',
        'flash',
        'aperture_issue',
        'shutter_speed_issue',
        'high_iso',
        'exposure_compensation_issue',
        'lens_is_too_tight',
        'lens_is_too_wide',
        'no_tripod_cell_phone',
        'underexposed',
        'overexposed',
        'desaturated',
        'missed_removal',
        'cropping_issues',
        'linear_barrel_distortion',
        'too_warm',
        'too_cool',
        'low_contrast'
    ])
})
export type Flag = z.infer<typeof Flag>

export type FlagWithLabel = Flag & {label: string}

export const DeclineReason = z.object({
    id: z.number(),
    key_name: z.string()
})
export type DeclineReason = z.infer<typeof DeclineReason>

export enum StepKey {
    'rejected' = 1,
    'initial-qa' = 2,
    'allocation' = 3,
    'curation' = 4,
    'editing-qa-selection' = 5,
    'editing-qa-flags' = 6,
    'review-completed' = 7,
    'ai-vetting' = 8,
    'ai-manual-retouching' = 9,
    'ai-vetting-completed' = 10,
    'retouching-qa' = 11,
    'retouching-qa-completed' = 12,
    'needs-review' = 13,
    'vetting-qa' = 14,
    'ai-manual-retouching-upload' = 15,
    'retouching-revision-upload' = 16,
    'retouching-revision' = 17,
    'retouching-revision-approved' = 18
}

export const Photoset = z.object({
    id: z.number(),
    airtable_url: z.string().nullable(),
    allocation_completed_at: z.coerce.date().nullable(),
    completed_at: z.coerce.date().nullable(),
    curation_completed_at: z.coerce.date().nullable(),
    initial_qa_completed_at: z.coerce.date().nullable(),
    name: z.string(),
    pdp_url: z.string().nullable(),
    reason_decline_id: z.number().nullable(),
    retouched_zip_at: z.coerce.date().nullable(),
    retouched_zip_url: z.string().nullable(),
    need_retouching_zip_url: z.string().nullable(),
    step_id: z.nativeEnum(StepKey),
    qa_step_id: z.nativeEnum(StepKey).nullable(),
    ai_retouched_thumbnail_generated_at: z.coerce.date().nullable(),
    thumbnail_generated_at: z.coerce.date().nullable(),
    retouched_thumbnail_generated_at: z.string().nullable(),
    revised_thumbnail_generated_at: z.string().nullable(),
    zip_url: z.string().nullable(),
    revised_zip_url: z.string().nullable(),
    comment: z.string().nullable(),
    need_retouching_zip_images_count: z.number(),
    assignment: z.object({
        photographer_notes: z.string().nullable(),
        task: z.object({
            entity: z.object({
                bedrooms: z.number().nullable()
            })
        })
    })
})
export type Photoset = z.infer<typeof Photoset>

export const Metadata = z.object({
    value: z.string(),
    count: z.number(),
    type: z.enum([
        'file_type',
        'flash',
        'aperture',
        'lens',
        'focal_length',
        'image_height',
        'image_width',
        'is_merged_hdr',
        'camera',
        'exposure_mode',
        'shutter_speed',
        'exposure_program',
        'metering_mode',
        'image_height',
        'image_length',
        'iso',
        'shoot_at',
        'scene'
    ])
})
export type Metadata = z.infer<typeof Metadata>

export const ImageMetadata = z.object({
    id: z.number(),
    type: Metadata.shape['type'],
    value: z.string()
})
export type ImageMetadata = z.infer<typeof ImageMetadata>

const ImageFlag = z.object({
    flag_id: Flag.shape['id'],
    image_id: z.number().min(1),
    is_qa_added: z.boolean(),
    is_flag_approved: z.boolean()
})

export const ImageThumbnails = z
    .object({
        l: z.string().url(),
        m: z.string().url(),
        s: z.string().url(),
        xs: z.string().url()
    })
    .nullable()
export type ImageThumbnails = z.infer<typeof ImageThumbnails>

export const Image = z.object({
    id: z.number().min(1),
    curation_approved: z.boolean().nullish(),
    vetting_qa_approved: z.boolean(),
    needs_to_review: z.boolean(),
    need_manual_retouching: z.boolean(),
    is_manually_retouched: z.boolean(),
    is_revised: z.boolean(),
    photoset_id: z.number(),
    ai_retouched_thumbnails: ImageThumbnails,
    retouched_thumbnails: ImageThumbnails,
    revised_thumbnails: ImageThumbnails.optional(), // remove optional after be updates
    thumbnails: ImageThumbnails,
    name: z.string().min(1),
    flags: Flag.array(),
    image_flags: ImageFlag.array(),
    is_final_select: z.boolean(),
    is_hdr: z.boolean().nullish(),
    photoset_space_id: z.number().nullable(),
    shoot_at: z.coerce.date().nullable(),
    space: Space.nullable(),
    metadata: z.array(ImageMetadata).optional() // Todo: remove optional after api updates
})
export type Image = z.infer<typeof Image>

export const PhotosetImagesSearchParams = z.object({
    filter_by: Metadata.shape['type'].nullish(),
    filter_value: z.string().nullish(),
    order_by: z.enum(['shoot_at', 'flags_count']).default('shoot_at'),
    order_direction: z.enum(['asc', 'desc']).default('asc')
})
export type PhotosetImagesSearchParams = z.infer<typeof PhotosetImagesSearchParams>

export const PhotosetURLParams = z.object({
    id: z.coerce.number().int()
})
export type PhotosetURLParams = z.infer<typeof PhotosetURLParams>

export const FlagsSearchParams = z.object({
    types: Flag.shape['type'].array().nullish()
})
export type FlagsSearchParams = z.infer<typeof FlagsSearchParams>

export const SpaceConfiguration = z.object({
    id: z.number().positive().int(),
    configuration_id: z.number().positive().int(),
    space_id: z.number().positive().int(),
    min_bedrooms: z.number().int(),
    max_bedrooms: z.number().int().nullable(),
    raws_minimum_images: z.number().int(),
    editor_minimum_images: z.number().int()
})
export type SpaceConfiguration = z.infer<typeof SpaceConfiguration>

export const PhotosetSpaceFlag = z
    .enum(['no_assets_delivered', 'no_elements_applicable', 'cannot_reach_minimum', 'sufficient_coverage'])
    .nullable()
export type PhotosetSpaceFlag = z.infer<typeof PhotosetSpaceFlag>

export const PhotosetSpace = z.object({
    id: z.number().positive().int(),
    configuration_space_id: z.number().positive().int(),
    configuration_space: SpaceConfiguration,
    photoset_id: z.number().positive().int(),
    flag_key_name: PhotosetSpaceFlag,
    comment: z.string().nullable(),
    space: z.object({...Space.shape}),
    images: z.array(Image.omit({space: true}))
})
export type PhotosetSpace = z.infer<typeof PhotosetSpace>

export const PhotosetSpacesChecks = z.object({
    selectedCounter: z.number().positive().int(),
    minimumSelection: z.number().positive().int(),
    minimumRaws: z.number().positive().int(),
    missingFlags: z.boolean(),
    spaceFlagRequired: z.boolean(),
    unusableSpace: z.boolean(),
    isSufficientCoverage: z.boolean()
})
export type PhotosetSpacesChecks = z.infer<typeof PhotosetSpacesChecks>

export type OnAllocateAction = {
    photosetSpaceId: PhotosetSpace['id']
    imagesIds: Image['id'][]
    isShortcut?: boolean
    keepSelected?: boolean
    trackInHistory?: boolean
}

export const PhotoPresignedData = z.object({
    presigned_url: z.string().url(),
    media: z.object({
        id: z.number().positive().int(),
        name: z.string()
    })
})
export type PhotoPresignedData = z.infer<typeof PhotoPresignedData>
