import {FC, useMemo} from 'react'
import {Flag, Image, Photoset, PhotosetSpace} from '@/features/photoset/types'
import {StyledImageCardFlags} from '@/features/photoset/components/image-card-flags/style'
import {Chip} from '@/components/commons/chip/Chip'
import {useTranslation} from 'react-i18next'
import {PlusIcon, XCloseIcon} from '@/components/ui/icon/Icon'
import {FlagsPopover} from '@/features/photoset/components/flags-popover/FlagsPopover'
import {RemoveImagesFlagData, useRemoveImagesFlag} from '@/features/photoset/services/useRemoveImagesFlag'
import toast from 'react-hot-toast'

interface ImageCardFlagsProps {
    image: Omit<Image, 'space'>
    stepId: Photoset['step_id']
    photosetSpaceIds?: PhotosetSpace['id'][]
    types?: Flag['type'][]
    hideFlagsDescriptions?: boolean
    onRemoveFlagCb?: (data: RemoveImagesFlagData) => void
    isReadOnly?: boolean
}

export const ImageCardFlags: FC<ImageCardFlagsProps> = ({
    image,
    stepId,
    types,
    photosetSpaceIds,
    hideFlagsDescriptions = false,
    isReadOnly = false,
    onRemoveFlagCb
}) => {
    const {t} = useTranslation()
    const removeImagesFlag = useRemoveImagesFlag({
        stepId,
        photosetSpaceIds,
        options: {
            onError: () => toast.error(t('errors:default')),
            onSuccess: onRemoveFlagCb
        }
    })

    const flags = useMemo(() => {
        if (!image.flags) return []
        if (!types) return image.flags

        return image.flags.filter(flag => types.includes(flag.type))
    }, [image.flags, types])

    return (
        <StyledImageCardFlags gap={1.5}>
            {flags.map(flag => (
                <Chip
                    key={flag.id}
                    label={t(`photoset:flags:${flag.key_name}:label`)}
                    isActive
                    variant="info"
                    endSlot={
                        !isReadOnly && (
                            <button
                                tabIndex={-1}
                                onClick={() => {
                                    removeImagesFlag.mutate({
                                        flag,
                                        imageIds: [image.id]
                                    })
                                }}
                            >
                                <XCloseIcon />
                            </button>
                        )
                    }
                />
            ))}

            {!isReadOnly && (
                <FlagsPopover
                    selectedImagesIds={[image.id]}
                    stepId={stepId}
                    types={types}
                    photosetSpaceIds={photosetSpaceIds}
                    disableScrollBelow
                    hideDescriptions={hideFlagsDescriptions}
                    onRemoveFlagCb={onRemoveFlagCb}
                    trigger={
                        <Chip
                            label={t(`commons:flag`, {count: 1})}
                            isActive={false}
                            variant="info"
                            endSlot={<PlusIcon />}
                        />
                    }
                />
            )}
        </StyledImageCardFlags>
    )
}
