import {StyledCurationSleElementHeader} from '@/features/photoset/components/curation-photoset-space/styel.ts'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {Divider} from '@components/ui/divider/Divider.tsx'
import {FC, useMemo} from 'react'
import {Skeleton} from '@components/ui/skeleton/Skeleton.tsx'
import {Image, PhotosetSpace, PhotosetSpacesChecks, Space} from '@/features/photoset/types.ts'
import {ImageCard} from '@components/commons/image-card/ImageCard.tsx'
import {spaceNameToLabel} from '@/features/photoset/utils.ts'
import {CurationPhotosetSpaceImages} from '@/features/photoset/components/curation-photoset-space-images/CurationPhotosetSpaceImages'
import {CurationPhotosetSpaceCounter} from '@/features/photoset/components/curation-photoset-space-counter/CurationPhotosetSpaceCounter'

interface CurationPhotosetSpaceProps {
    asSkeleton?: undefined
    spaceId: number
    spaceName: Space['key_name']
    images: Omit<Image, 'space'>[]
    spaceIndex: number
    spaceChecks: PhotosetSpacesChecks
    curationTouched: boolean
    hideAllUnselected: boolean
    currentDetailImageIndex: number | null
    spaceFlagValue: PhotosetSpace['flag_key_name']
    cbSetHideAllUnselected: () => void
    onExpandImage: (spaceIndex: number, imageIndex: number) => void
}

interface CurationPhotosetSpaceSkeletonProps {
    asSkeleton: true
    spaceId?: number
    spaceFlagValue?: undefined
    spaceName?: undefined
    images?: undefined
    spaceIndex?: undefined
    spaceChecks?: undefined
    curationTouched?: undefined
    cbSetHideAllUnselected?: undefined
    hideAllUnselected?: undefined
    onExpandImage?: undefined
}

export const CurationPhotosetSpace: FC<CurationPhotosetSpaceProps | CurationPhotosetSpaceSkeletonProps> = ({
    asSkeleton,
    spaceId,
    curationTouched,
    hideAllUnselected,
    images,
    spaceName,
    spaceIndex,
    spaceChecks,
    cbSetHideAllUnselected,
    onExpandImage,
    spaceFlagValue
}) => {
    const visibleImages = useMemo((): Omit<Image, 'space'>[] => {
        if (images) {
            if (hideAllUnselected) {
                return images?.filter(image => image.is_final_select)
            } else {
                return images
            }
        } else {
            return []
        }
    }, [images, hideAllUnselected])

    if (asSkeleton) {
        return (
            <Flexbox direction={'column'}>
                <StyledCurationSleElementHeader gap={1} direction={'column'}>
                    <Skeleton height={28} width={100} />
                    <Skeleton height={20} width={200} />
                </StyledCurationSleElementHeader>
                <Divider direction={'horizontal'} endSpacing={4} />
                <Flexbox gap={4}>
                    <ImageCard asSkeleton />
                    <ImageCard asSkeleton />
                    <ImageCard asSkeleton />
                    <ImageCard asSkeleton />
                </Flexbox>
            </Flexbox>
        )
    }

    return (
        <Flexbox direction={'column'}>
            <StyledCurationSleElementHeader gap={1} direction={'column'}>
                <h4>{spaceNameToLabel[spaceName]}</h4>
                <CurationPhotosetSpaceCounter
                    spaceChecks={spaceChecks}
                    curationTouched={curationTouched}
                    spaceIndex={spaceIndex}
                    spaceFlagValue={spaceFlagValue}
                    photosetSpaceId={spaceId}
                />
            </StyledCurationSleElementHeader>

            <Divider direction={'horizontal'} />

            <CurationPhotosetSpaceImages
                images={visibleImages}
                spaceId={spaceId}
                spaceIndex={spaceIndex}
                onExpandImage={onExpandImage}
                cbSetHideAllUnselected={cbSetHideAllUnselected}
            />
        </Flexbox>
    )
}

CurationPhotosetSpace.displayName = 'CurationPhotosetSpace'
