import {routes} from '@/utilities/constants/routes'
import {errorHandler} from '@/utilities/helpers'
import {useTranslation} from 'react-i18next'
import {generatePath, useNavigate} from 'react-router-dom'
import {Photoset} from '@/features/photoset/types'
import {Modal} from '@/components/ui/modal/Modal'
import {QUERY_KEYS, queryClient} from '@/queryClient'
import {ModalBody, ModalFooter, ModalHeader} from '@/components/ui/modal-atoms/ModalAtoms'
import {Button} from '@/components/ui/button/Button'
import {XCloseIcon} from '@/components/ui/icon/Icon'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {TextArea} from '@/components/commons/textarea/TextArea'
import {Controller, useForm, useWatch} from 'react-hook-form'
import {z} from 'zod'
import {zodResolver} from '@hookform/resolvers/zod'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {Divider} from '@/components/ui/divider/Divider'
import {useAddPhotosetComment} from '@/features/photoset/services/useAddPhotosetComment'
import {Checkbox} from '@/components/commons/checkbox/ChekBox'
import {useMemo} from 'react'
import {useUpdateStepToRetouchingQaCompleted} from '@/features/photoset/services/useUpdateStepToRetouchingQaCompleted'
import {StyledRetouchingQaCommentConfirmModalTitle} from './style'

export const RetouchingQaCommentConfirmModal = ({
    photosetId,
    onClose
}: {
    photosetId: Photoset['id']
    onClose: () => void
}) => {
    const {t} = useTranslation()
    const navigate = useNavigate()

    const retouchingQaCommentConfirmModalSchema = z
        .object({
            comment: z.string(),
            skipComment: z.boolean()
        })
        .superRefine((val, ctx) => {
            if (!val.comment && !val.skipComment) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    path: ['skipComment'],
                    message: t('photoset:retouching_qa:comment_confirm_error')
                })
            }
        })

    const form = useForm({
        mode: 'all',
        resolver: zodResolver(retouchingQaCommentConfirmModalSchema),
        defaultValues: {
            comment: '',
            skipComment: false
        }
    })

    // mutations
    const {mutate: updateStepToNeedsReview, isPending: isPendingUpdateStepToNeedsReview} =
        useUpdateStepToRetouchingQaCompleted({
            onError: errorHandler,
            onSuccess: () => {
                void queryClient.invalidateQueries({queryKey: [QUERY_KEYS.PHOTOSET]})
                void queryClient.invalidateQueries({queryKey: [QUERY_KEYS.PHOTOSET_SPACES]})
                navigate(
                    generatePath(routes.PHOTOSET_RETOUCHING_QA_COMPLETED.path, {
                        id: photosetId
                    })
                )
            }
        })

    const {mutate: addPhotosetSpaceComment, isPending: isPendingAddPhotosetComment} = useAddPhotosetComment(
        {photosetId},
        {
            onSuccess: () => {
                void queryClient.invalidateQueries({queryKey: [QUERY_KEYS.PHOTOSET]})
                navigate(
                    generatePath(routes.PHOTOSET_RETOUCHING_QA_COMPLETED.path, {
                        id: photosetId
                    })
                )
            }
        }
    )

    const handleSubmit = form.handleSubmit(({comment, skipComment}) => {
        if (!skipComment) {
            addPhotosetSpaceComment({comment})
        }

        updateStepToNeedsReview(photosetId)
    })

    const isLoading = useMemo(
        () => isPendingUpdateStepToNeedsReview || isPendingAddPhotosetComment,
        [isPendingUpdateStepToNeedsReview, isPendingAddPhotosetComment]
    )
    const skipComment = useWatch({control: form.control, name: 'skipComment'})

    return (
        <Modal width={480} closeIconOffset="sm" onOverlayClick={onClose}>
            <ModalHeader>
                <Button iconOnly size="sm" variant="tertiary" onClick={onClose}>
                    <XCloseIcon size={20} />
                </Button>
                <h3>{t('commons:comment')}</h3>
            </ModalHeader>

            <ModalBody>
                <Flexbox direction="column" gap={6}>
                    <StyledRetouchingQaCommentConfirmModalTitle>
                        {t('photoset:retouching_qa:comment_confirm_modal_title')}
                    </StyledRetouchingQaCommentConfirmModalTitle>
                    <Flexbox
                        gap={6}
                        direction="column"
                        id="confirm-comment-form"
                        render="form"
                        fullWidth
                        onSubmit={handleSubmit}
                    >
                        <TextArea
                            rows={6}
                            maxLength={250}
                            disabled={skipComment}
                            placeholder={t('photoset:retouching_qa:comment_confirm_modal_placeholder')}
                            errorMessage={
                                form.formState.errors?.comment?.message && t(form.formState.errors.comment.message)
                            }
                            {...form.register('comment')}
                        />

                        <Controller
                            control={form.control}
                            name="skipComment"
                            render={({field}) => (
                                <Checkbox
                                    label={t('photoset:retouching_qa:skip_comment_label')}
                                    errorMessage={form.formState.errors?.skipComment?.message}
                                    {...form.register('skipComment')}
                                    onChange={() => {
                                        form.setValue('skipComment', !field.value)
                                        form.setValue('comment', '')
                                    }}
                                />
                            )}
                        />
                    </Flexbox>
                </Flexbox>
            </ModalBody>

            <Divider direction="horizontal" />

            <ModalFooter>
                <Button variant="tertiary" onClick={onClose} disabled={isLoading}>
                    {t('commons:cancel')}
                </Button>
                <Button variant="primary" type="submit" form="confirm-comment-form" disabled={isLoading}>
                    {t('commons:confirm')}
                    {isLoading && <Spinner size={16} />}
                </Button>
            </ModalFooter>
        </Modal>
    )
}
