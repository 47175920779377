import {useTranslation} from 'react-i18next'
import {PageHero} from '@components/commons/page-hero/PageHero.tsx'
import {PageFooter} from '@components/commons/page-footer/PageFooter.tsx'
import {useOutletContext} from 'react-router-dom'
import {PhotosetURLParams} from '@/features/photoset/types.ts'
import {Fragment, useEffect, useMemo, useState} from 'react'
import {usePhotosetStore} from '@/features/photoset/store/store.ts'
import {Button} from '@components/ui/button/Button.tsx'
import {UnusableCurationPhotosetSpace} from '@/features/photoset/components/unusable-curation-photoset-space/UnusableCurationPhotosetSpace.tsx'
import {CurationPhotosetSpace} from '@/features/photoset/components/curation-photoset-space/CurationPhotosetSpace.tsx'
import {ArrowLeftIcon} from '@components/ui/icon/Icon.tsx'
import {ObjectKeys, ObjectValues, errorHandler} from '@utilities/helpers.ts'
import {useUpdateStepToAllocation} from '@/features/photoset/services/useUpdateStepToAllocation.ts'
import {useGetPhotosetSpaces} from '@/features/photoset/services/useGetPhotosetSpaces.ts'
import {useUpdateStepToCompleted} from '@/features/photoset/services/useUpdateStepToCompleted.ts'
import {QUERY_KEYS, queryClient} from '@/queryClient.ts'
import {SubHeaderBanner} from '@/features/photoset/components/subheader-banner/SubHeaderBanner.tsx'
import {useGetFlags} from '@/features/photoset/services/useGetFlags'
import {GalleryCurationModal} from '@/features/photoset/components/gallery-photoset-modal/gallery-curation-modal/GalleryCurationModal'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {Switch} from '@components/ui/switch/Switch.tsx'
import {
    PhotosetStepBodyContainer,
    PhotosetStepWrapper
} from '@/features/photoset/components/photoset-step-atoms/PhotosetStepAtoms'
import {useExpandPhotosetImage} from '@/features/photoset/hooks/useExpandPhotosetImage'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {StepHeroControls} from '@/features/photoset/components/step-hero-controls/StepHeroControls'

export const PhotosetCurationStep = () => {
    const {t} = useTranslation()
    const {urlParams} = useOutletContext<{urlParams: PhotosetURLParams}>()
    const [hideAllUnselected, setHideAllUnselected] = useState(false)

    const {
        data: spaces,
        isFetching,
        isError
    } = useGetPhotosetSpaces({
        urlParams: {
            photosetId: urlParams.id
        },
        params: {response_type: 'extended'}
    })

    useGetFlags(
        {
            params: {
                types: ['metadata', 'visual_metadata', 'visual'],
                response_type: 'extended'
            }
        },
        {staleTime: Infinity}
    )

    //photoset store
    const initPhotosetSpaces = usePhotosetStore(state => state.initPhotosetSpaces)
    const photosetSpaces = usePhotosetStore(state => state.photosetSpaces)
    const photosetSpacesChecks = usePhotosetStore(state => state.photosetSpacesChecks)
    const resetPhotosetSpaces = usePhotosetStore(state => state.resetPhotosetSpaces)

    //checks
    const errors = useMemo(() => {
        const flagsRequired = Object.values(photosetSpacesChecks).some(
            checks => checks.missingFlags && checks.selectedCounter < checks.minimumSelection
        )

        const mimimumSelectionErrors = Object.values(photosetSpacesChecks).some(
            checks => checks.selectedCounter < checks.minimumSelection
        )

        const spaceFlagRequired = Object.values(photosetSpacesChecks).some(checks => checks.spaceFlagRequired)

        return {
            spaceFlagRequired: spaceFlagRequired ? t('photoset:curation:space_error_subtitle') : null,
            mimimumSelectionErrors:
                mimimumSelectionErrors && flagsRequired
                    ? t('photoset:curation:selection_error_subtitle', {
                          intro: spaceFlagRequired ? t('commons:also') : t('commons:please')
                      })
                    : null,
            flagsRequired: flagsRequired
                ? t('photoset:curation:flags_error_subtitle', {
                      intro: spaceFlagRequired || mimimumSelectionErrors ? t('commons:also') : t('commons:please')
                  })
                : null
        }
    }, [photosetSpacesChecks, photosetSpaces])

    const showErrorBanner = useMemo(() => Boolean(ObjectValues(errors).some(err => err != null)), [errors])

    //state
    const [curationTouched, setCurationTouched] = useState(false)
    //mutations
    const updateStepToAllocationMutation = useUpdateStepToAllocation({
        onError: errorHandler,
        onSuccess: () => {
            resetPhotosetSpaces()
            void queryClient.invalidateQueries({queryKey: [QUERY_KEYS.PHOTOSET]})
            void queryClient.invalidateQueries({queryKey: [QUERY_KEYS.IMAGES]})
            void queryClient.invalidateQueries({queryKey: [QUERY_KEYS.PHOTOSET_SPACES]})
        }
    })
    const updateStepToCompleted = useUpdateStepToCompleted({
        onError: errorHandler,
        onSuccess: () => {
            void queryClient.invalidateQueries({queryKey: [QUERY_KEYS.PHOTOSET]})
            void queryClient.invalidateQueries({queryKey: [QUERY_KEYS.IMAGES]})
        }
    })

    const onCurationCompleted = () => {
        setCurationTouched(true)
        const finalSelectedImages: number[] = []
        if (!showErrorBanner) {
            photosetSpaces.map(spaces =>
                spaces.images.map(image => {
                    if (image.is_final_select) {
                        finalSelectedImages.push(image.id)
                    }
                    return image
                })
            )
            updateStepToCompleted.mutate({
                photosetId: urlParams.id,
                imagesIds: finalSelectedImages
            })
        }
    }

    const onHideAllUnselectedCb = () => {
        setHideAllUnselected(!hideAllUnselected)
    }

    //init Photoset Spaces
    useEffect(() => {
        if (spaces) {
            initPhotosetSpaces(spaces)
        }
    }, [spaces])

    // Handle gallery modal view
    const {currentDetailImageIndex, setCurrentDetailImageIndex, currentDetailSpace, onExpandImage, onReduceImage} =
        useExpandPhotosetImage()

    return (
        <>
            <PageHero
                title={t('photoset:curation:title')}
                subtitle={t('photoset:curation:subtitle')}
                endSlot={
                    <Flexbox gap={4} align="center">
                        <Switch
                            label={t('photoset:curation:hide_unselected')}
                            onChange={onHideAllUnselectedCb}
                            checked={hideAllUnselected}
                        />
                        <StepHeroControls hideSortControls />
                    </Flexbox>
                }
            />
            {showErrorBanner && curationTouched && (
                <SubHeaderBanner
                    type={'danger'}
                    title={t('photoset:curation:selection_error_title')}
                    subtitle={ObjectKeys(errors)
                        .filter(error => !!errors[error])
                        .map(error => errors[error])
                        .join(' ')}
                />
            )}

            <PhotosetStepWrapper>
                <PhotosetStepBodyContainer fullWidth $disabled={updateStepToCompleted.isPending}>
                    {isFetching && [0, 1, 2].map(e => <CurationPhotosetSpace asSkeleton key={`${e}-id`} />)}
                    {!isFetching &&
                        !isError &&
                        photosetSpaces?.map((photosetSpace, spaceIndex) => (
                            <Fragment key={photosetSpace.id}>
                                {photosetSpacesChecks[spaceIndex].unusableSpace ? (
                                    <UnusableCurationPhotosetSpace
                                        type={photosetSpace.images.length == 0 ? 'noImages' : 'minimumNotMet'}
                                        spaceName={photosetSpace.space.key_name}
                                        totalImages={photosetSpace.images.length}
                                        images={photosetSpace.images}
                                        spaceConfigs={photosetSpace.configuration_space}
                                        spaceChecks={photosetSpacesChecks[spaceIndex]}
                                        spaceFlagValue={photosetSpace.flag_key_name}
                                        curationTouched={curationTouched}
                                        spaceId={photosetSpace.id}
                                        spaceIndex={spaceIndex}
                                        cbSetHideAllUnselected={onHideAllUnselectedCb}
                                        onExpandImage={onExpandImage}
                                    />
                                ) : (
                                    <CurationPhotosetSpace
                                        images={photosetSpace.images}
                                        spaceId={photosetSpace.id}
                                        spaceIndex={spaceIndex}
                                        spaceName={photosetSpace.space.key_name}
                                        spaceChecks={photosetSpacesChecks[spaceIndex]}
                                        curationTouched={curationTouched}
                                        currentDetailImageIndex={currentDetailImageIndex}
                                        onExpandImage={onExpandImage}
                                        cbSetHideAllUnselected={onHideAllUnselectedCb}
                                        hideAllUnselected={hideAllUnselected}
                                        spaceFlagValue={photosetSpace.flag_key_name}
                                    />
                                )}
                            </Fragment>
                        ))}
                </PhotosetStepBodyContainer>
                <PageFooter
                    startSlot={
                        <Button
                            onClick={() => updateStepToAllocationMutation.mutate(urlParams.id)}
                            variant="tertiary"
                            disabled={updateStepToAllocationMutation.isPending || updateStepToCompleted.isPending}
                        >
                            <ArrowLeftIcon />
                            {t('commons:back')}
                        </Button>
                    }
                    centerSlot={<p>{t('photoset:curation:footer_step_label')}</p>}
                    endSlot={
                        <Button
                            onClick={() => onCurationCompleted()}
                            disabled={updateStepToCompleted.isPending || updateStepToAllocationMutation.isPending}
                            variant="primarySuccess"
                        >
                            {updateStepToCompleted.isPending && <Spinner size={20} />}
                            {t('commons:done')}
                        </Button>
                    }
                />
            </PhotosetStepWrapper>

            {currentDetailImageIndex != null && currentDetailSpace && (
                <GalleryCurationModal
                    onClose={onReduceImage}
                    currentDetailImageIndex={currentDetailImageIndex}
                    setCurrentDetailImageIndex={setCurrentDetailImageIndex}
                    selectableImages={currentDetailSpace.images}
                    currentSpaceId={currentDetailSpace.id}
                />
            )}
        </>
    )
}

PhotosetCurationStep.displayName = 'PhotosetCurationStep'
