import {FC, ReactNode} from 'react'
import {StyledShootListHeader} from '@components/ui/list-header/style.ts'
import {Flexbox} from '@components/ui/flexbox/FlexBox'

interface ListHeaderProps {
    className?: string
    title: ReactNode
    startSlot?: ReactNode
    endSlot?: ReactNode
    size?: 'sm' | 'md'
}

export const ListHeader: FC<ListHeaderProps> = ({className, title, size, startSlot, endSlot}) => {
    return (
        <StyledShootListHeader
            $size={size ?? 'md'}
            className={className}
            justify="space-between"
            align="center"
            gap={4}
        >
            <Flexbox gap={2} align="center">
                <h2>{title}</h2>
                {startSlot}
            </Flexbox>

            {endSlot}
        </StyledShootListHeader>
    )
}

ListHeader.displayName = 'ListHeader'
