import {StyledDropzone} from '@/features/photoset/components/space-card/style.ts'
import {FC} from 'react'
import {useTranslation} from 'react-i18next'
import {Skeleton} from '@components/ui/skeleton/Skeleton.tsx'
import {Image, PhotosetSpace} from '@/features/photoset/types.ts'
import {
    SpaceCardEmptyPreviewSlot,
    SpaceCardHeader,
    SpaceCardImagePreviewSlot,
    SpaceCardImagesPreviewGrid,
    SpaceCardWrapper
} from '@/features/photoset/components/space-card-atoms/SpaceCardAtoms.tsx'
import {DeallocateImageButton} from '@/features/photoset/components/deallocate-image-button/DeallocateImageButton'
import {Droppable} from '@components/ui/dnd-atoms/DndAtoms.tsx'

interface SpaceCardProps {
    asSkeleton?: undefined
    id: PhotosetSpace['id']
    spaceImages: Image[]
    shortcut: string
    title: string
    openSpaceDetailModal: () => void
    photosetSpaceId: number
}

interface SpaceCardSkeletonProps {
    asSkeleton: true
    id?: undefined
    spaceImages?: undefined
    shortcut?: undefined
    title?: undefined
    openSpaceDetailModal?: undefined
    photosetSpaceId?: number
}

export const SpaceCard: FC<SpaceCardProps | SpaceCardSkeletonProps> = ({
    photosetSpaceId,
    spaceImages,
    id,
    shortcut,
    title,
    openSpaceDetailModal,
    asSkeleton
}) => {
    const {t} = useTranslation()
    const imagesInPreview = spaceImages?.slice(-5).reverse() ?? []

    if (asSkeleton) {
        return <Skeleton width="100%" height="128px" />
    }

    return (
        <SpaceCardWrapper hasImages={spaceImages.length >= 1}>
            <SpaceCardHeader
                headerTitle={title}
                images={spaceImages}
                openSpaceDetailModal={openSpaceDetailModal}
                shortcut={shortcut}
            />
            <Droppable id={id.toString()}>
                {droppable =>
                    spaceImages.length >= 1 ? (
                        <SpaceCardImagesPreviewGrid ref={droppable.setNodeRef} $isDraggingOver={droppable.isOver}>
                            {imagesInPreview.map(image => {
                                const otherImagesCount = spaceImages.length - imagesInPreview.length
                                return (
                                    <SpaceCardImagePreviewSlot
                                        key={image.id}
                                        image={image}
                                        otherImagesCount={otherImagesCount}
                                        overlayComponent={
                                            <DeallocateImageButton
                                                image={image}
                                                photosetSpaceId={photosetSpaceId}
                                                keepSelected={false}
                                                trackInHistory={true}
                                                showToast={false}
                                            />
                                        }
                                    />
                                )
                            })}

                            {Array.from({length: 5 - imagesInPreview.length}).map((_, index) => (
                                <SpaceCardEmptyPreviewSlot key={index} />
                            ))}
                        </SpaceCardImagesPreviewGrid>
                    ) : (
                        <StyledDropzone ref={droppable.setNodeRef} $isDraggingOver={droppable.isOver}>
                            <h3>{t('photoset:allocation:space_dropzone_description')}</h3>
                        </StyledDropzone>
                    )
                }
            </Droppable>
        </SpaceCardWrapper>
    )
}

SpaceCard.displayName = 'SpaceCard'
