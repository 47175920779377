import {HeaderWrapper, DropdownUserInfosItems, StyledHeaderContent} from '@components/commons/header/style.ts'
import Logo from '@assets/images/logo_dark.svg'
import {Container} from '@components/ui/container/Container.tsx'
import {Avatar} from '@components/ui/avatar/Avatar.tsx'
import {DropdownMenu} from '@components/ui/dropdown-menu/DropdownMenu.tsx'
import {useTranslation} from 'react-i18next'
import {LogoutIcon} from '@components/ui/icon/Icon.tsx'
import {ReactNode, useState} from 'react'
import {LangSelectionModal} from '@components/commons/lang-selection-modal/LangSelectionModal.tsx'
import {useAuthStore} from '@/features/auth/store/store.ts'
import {logout} from '@/features/auth/store/actions.ts'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'

interface HeaderProps {
    endSlot?: ReactNode
}

export const Header = ({endSlot}: HeaderProps) => {
    const {t} = useTranslation()
    const user = useAuthStore(state => state.user)
    const [showLangModal, setShowLangModal] = useState(false)

    return (
        <HeaderWrapper>
            <Container fullWidth>
                <StyledHeaderContent justify="space-between" align="center">
                    <img src={Logo} alt={'logo'} height={28} />

                    <Flexbox gap={4} justify="flex-end">
                        {endSlot}

                        {user && (
                            <DropdownMenu
                                sideOffset={8}
                                trigger={<Avatar name={user?.first_name ?? user.email} imageUrl={''} />}
                                actions={[
                                    {
                                        component: (
                                            <DropdownUserInfosItems>
                                                <span>{user?.first_name ?? user.email}</span>
                                                <small>Luminate operator</small>
                                            </DropdownUserInfosItems>
                                        ),
                                        withSeparator: true
                                    },
                                    /*...(isMobile
                                        ? [
                                            {
                                                component: <ChangeLangModalTrigger />,
                                                onClickCb: () => setShowLangModal(!showLangModal)
                                            }
                                        ]
                                        : []),*/
                                    {
                                        component: t('commons:logout'),
                                        icon: <LogoutIcon size={16} />,
                                        onClickCb: logout
                                    }
                                ]}
                            />
                        )}
                    </Flexbox>
                </StyledHeaderContent>
            </Container>
            {showLangModal && <LangSelectionModal onCloseCb={() => setShowLangModal(false)} />}
        </HeaderWrapper>
    )
}
