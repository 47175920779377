import styled, {css} from 'styled-components'

export const StyledDropzone = styled.div<{$isDraggingOver: boolean}>(
    ({theme: {spacing, palette, typography}, $isDraggingOver}) => css`
        display: flex;
        justify-content: center;
        width: 100%;
        border-radius: 8px;
        border: 1px solid ${palette.neutral['100']};
        background: ${palette.neutral['50']};
        padding: ${spacing * 5.5}px;
        transition: ease-in-out 150ms;
        ${$isDraggingOver &&
        css`
            box-shadow: 0 0 0 4px ${palette.blue['100']};
        `};
        & h3 {
            ${typography.textSm};
            font-weight: 500;
            color: ${palette.neutral['600']};
        }
    `
)
