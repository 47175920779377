import {Image, Photoset, PhotosetSpace, StepKey} from '@/features/photoset/types'
import {
    StyledGalleryRetouchingQaFlags,
    StyledGalleryRetouchingQaModalSidebarContent,
    StyledGalleryRetouchingQaModalTrigger
} from './style'
import {FlagsPopover} from '../../flags-popover/FlagsPopover'
import {useTranslation} from 'react-i18next'
import {ChevronDownIcon, XCloseIcon} from '@/components/ui/icon/Icon'
import {Chip} from '@/components/commons/chip/Chip'
import {useRemoveImagesFlag} from '@/features/photoset/services/useRemoveImagesFlag'
import toast from 'react-hot-toast'
import {useRemoveRetouchingQaFlag} from '@/features/photoset/hooks/useRemoveRetouchingQaFlag'

export const GalleryRetouchingQaSidebar = ({
    currentImage,
    photosetId,
    photosetSpaceId
}: {
    photosetId: Photoset['id']
    currentImage: Omit<Image, 'space'>
    photosetSpaceId: PhotosetSpace['id']
}) => {
    const {t} = useTranslation()

    const {onRemoveFlagCb} = useRemoveRetouchingQaFlag({
        photosetId,
        photosetSpaceId
    })

    const removeImagesFlag = useRemoveImagesFlag({
        stepId: StepKey['retouching-qa'],
        photosetSpaceIds: [photosetSpaceId],
        options: {
            onError: () => toast.error(t('errors:default')),
            onSuccess: onRemoveFlagCb
        }
    })

    return (
        <StyledGalleryRetouchingQaModalSidebarContent direction="column" gap={4}>
            <FlagsPopover
                stepId={StepKey['retouching-qa']}
                types={['visual_retouching']}
                selectedImagesIds={[currentImage.id]}
                photosetSpaceIds={[photosetSpaceId]}
                width={'308px'}
                triggerWidth={'100%'}
                trigger={
                    <StyledGalleryRetouchingQaModalTrigger variant="secondary" fullWidth>
                        <span>{t('photoset:retouching_qa:add_flag_trigger')}</span>
                        <ChevronDownIcon />
                    </StyledGalleryRetouchingQaModalTrigger>
                }
            />

            <StyledGalleryRetouchingQaFlags gap={1.5}>
                {currentImage.flags
                    .filter(flag => flag.type == 'visual_retouching')
                    .map(flag => (
                        <Chip
                            key={flag.id}
                            label={t(`photoset:flags:${flag.key_name}:label`)}
                            isActive
                            variant="info"
                            endSlot={
                                <button
                                    tabIndex={-1}
                                    onClick={() => {
                                        removeImagesFlag.mutate({
                                            flag,
                                            imageIds: [currentImage.id]
                                        })
                                    }}
                                >
                                    <XCloseIcon />
                                </button>
                            }
                        />
                    ))}
            </StyledGalleryRetouchingQaFlags>
        </StyledGalleryRetouchingQaModalSidebarContent>
    )
}
