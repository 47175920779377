import {ModalBody} from '@/components/ui/modal-atoms/ModalAtoms'
import styled, {css} from 'styled-components'

export const StyledRejectModalBody = styled(ModalBody)(
    ({theme: {spacing, typography, palette}}) => css`
        padding: ${spacing * 6}px;
        color: ${palette.neutral[900]};
        flex-direction: column;
        gap: ${spacing * 6}px;

        & h1 {
            ${typography.textXl};
            font-weight: 500;
        }

        & h2 {
            ${typography.textSm};
        }
    `
)
