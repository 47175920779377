import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const StyledSpaceCardWrapper = styled(Flexbox)<{$hasImages?: boolean}>(
    ({theme: {spacing, palette}, $hasImages}) => css`
        border-radius: 14px;
        border: 1px solid ${palette.neutral['200']};
        background: ${palette.neutral.white};
        padding: ${spacing * 3}px ${spacing * 4}px;

        & .show-hover {
            display: none;
        }
        & .show-not-hover {
            display: flex;
        }

        ${$hasImages &&
        css`
            &:hover {
                & .show-hover {
                    display: flex;
                }
                & .show-not-hover {
                    display: none;
                }
            }
        `}
    `
)

export const StyledSpaceCardHeader = styled(Flexbox)(
    ({theme: {palette, typography}}) => css`
        width: 100%;
        & h3 {
            ${typography.textMd};
            font-weight: 500;
            color: ${palette.neutral['900']};
            word-break: break-word;
        }
        & h4 {
            ${typography.textSm};
            color: ${palette.neutral['600']};
        }
    `
)

export const StyledSpaceCardImagesPreviewGrid = styled.div<{$isDraggingOver?: boolean}>(
    ({theme: {spacing, palette}, $isDraggingOver}) => css`
        display: grid;
        width: 100%;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: 1fr 1fr;
        gap: ${spacing * 2}px;
        border-radius: 10px;
        transition: ease-in-out 150ms;

        ${$isDraggingOver &&
        css`
            background-color: ${palette.blue['100']};
            box-shadow: 0 0 0 4px ${palette.blue['100']};
        `};
    `
)

export const StyledImagePreviewSlot = styled.div<{$hasOtherImagesCount?: boolean}>(
    ({$hasOtherImagesCount, theme: {palette, shadows, typography}}) => css`
        background: ${palette.neutral['100']};
        border: 1px solid ${palette.neutral['50']};
        overflow: hidden;
        aspect-ratio: 1;
        position: relative;

        &:first-child {
            border-radius: 10px;
            grid-column-end: span 2;
            grid-row-end: span 2;
        }
        &:not(:first-child) {
            border-radius: 6px;
        }

        ${$hasOtherImagesCount &&
        css`
            &:last-child {
                position: relative;

                img {
                    filter: blur(3px);
                }

                & span {
                    position: absolute;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    inset: 0;
                    ${typography.textSm};
                    font-weight: 500;
                    color: ${palette.neutral.white};
                    box-shadow: ${shadows.lg};
                }
            }
        `}

        & img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    `
)

export const StyledImagePreviewSlotOverlay = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
`

export const StyledImagePreviewSlowPlaceholder = styled(Flexbox)`
    height: 100%;
`

export const StyledShortcutLabel = styled(Flexbox)(
    ({theme: {palette, typography}}) => css`
        height: 22px;
        width: 22px;
        border-radius: 4px;
        border: 1px solid ${palette.neutral['200']};
        background-color: ${palette.neutral['50']};
        color: ${palette.neutral['500']};
        ${typography.textSm};
    `
)
