import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const StyledUnusableCurationSpaceBody = styled(Flexbox)<{$isEmpty?: boolean}>(
    ({theme: {spacing, typography, palette}, $isEmpty = false}) => css`
        padding: ${$isEmpty ? `${spacing * 12}px 0` : '0'};
        background: ${$isEmpty ? palette.neutral['100'] : 'transparent'};
        width: 100%;
        & > h3 {
            ${typography.textLg};
            color: ${palette.neutral.black};
            font-weight: 500;
        }

        & > p {
            ${typography.textSm};
            color: ${palette.neutral['600']};
            max-width: 360px;
            text-align: center;
        }
    `
)

export const StyledUnusableCurationSpaceAlert = styled(Flexbox)<{$isError: boolean}>(
    ({$isError: isError, theme: {spacing, typography, palette}}) => css`
        background: ${isError ? palette.danger['100'] : palette.neutral['100']};
        border: 1px solid ${isError ? palette.danger['200'] : palette.neutral['200']};
        border-radius: 4px;
        box-sizing: border-box;
        padding: ${spacing}px ${spacing * 2}px;
        & > p {
            ${typography.textSm};
            color: ${isError ? palette.danger['600'] : palette.neutral['600']};
        }
        & > svg {
            fill: ${isError ? palette.danger['600'] : palette.neutral['600']};
        }
    `
)
