import {Modal} from '@/components/ui/modal/Modal'
import {ModalBody, ModalHeader} from '@/components/ui/modal-atoms/ModalAtoms'
import {Button} from '@/components/ui/button/Button'
import {FC} from 'react'
import {XCloseIcon} from '@/components/ui/icon/Icon'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {useTranslation} from 'react-i18next'
import {
    StyledCurationSpaceRules,
    StyledCurationSpaceRulesModalFooter,
    StyledCurationSpaceRulesModalParagraph,
    StyledCurationSpaceRulesModalTitle
} from '@/features/photoset/components/curation-photoset-space-rules-modal/style'
import {PhotosetSpace} from '@/features/photoset/types'
import {useAddPhotosetSpaceFlag} from '@/features/photoset/services/useAddPhotosetSpaceFlag'
import {errorHandler} from '@/utilities/helpers'

interface CurationPhotosetSpaceRulesModalProps {
    photosetSpaceId: PhotosetSpace['id']
    spaceFlagValue: PhotosetSpace['flag_key_name']
    hideModal: () => void
}

export const CurationPhotosetSpaceRulesModal: FC<CurationPhotosetSpaceRulesModalProps> = ({
    photosetSpaceId,
    spaceFlagValue,
    hideModal
}) => {
    const {t} = useTranslation()
    const {mutate: addPhotosetSpaceFlag} = useAddPhotosetSpaceFlag({onError: errorHandler})

    return (
        <Modal width={480} onOverlayClick={() => hideModal()}>
            <ModalHeader>
                <Button iconOnly size="sm" variant="tertiary" onClick={() => hideModal()}>
                    <XCloseIcon />
                </Button>
                <h3>{t('photoset:curation:rules_modal:title')}</h3>
            </ModalHeader>

            <ModalBody>
                <Flexbox gap={2} direction="column">
                    <StyledCurationSpaceRulesModalTitle>
                        {t('photoset:curation:rules_modal:title')}
                    </StyledCurationSpaceRulesModalTitle>
                    <StyledCurationSpaceRulesModalParagraph>
                        {t('photoset:curation:rules_modal:paragraph')}
                    </StyledCurationSpaceRulesModalParagraph>
                </Flexbox>

                <Flexbox gap={4} direction="column">
                    <StyledCurationSpaceRules
                        $isSelected={spaceFlagValue != 'sufficient_coverage'}
                        onClick={() => addPhotosetSpaceFlag({photosetSpaceId, flag: null})}
                    >
                        <h4>{t('photoset:curation:rules_modal:quality_title')}</h4>
                        <p>{t('photoset:curation:rules_modal:quality_description')}</p>
                    </StyledCurationSpaceRules>
                    <StyledCurationSpaceRules
                        $isSelected={spaceFlagValue == 'sufficient_coverage'}
                        onClick={() => addPhotosetSpaceFlag({photosetSpaceId, flag: 'sufficient_coverage'})}
                    >
                        <h4>{t('photoset:curation:rules_modal:coverage_title')}</h4>
                        <p>{t('photoset:curation:rules_modal:coverage_description')}</p>
                    </StyledCurationSpaceRules>
                </Flexbox>
            </ModalBody>

            <StyledCurationSpaceRulesModalFooter>
                <Button variant="tertiary" onClick={() => hideModal()}>
                    {t('commons:close')}
                </Button>
            </StyledCurationSpaceRulesModalFooter>
        </Modal>
    )
}
