import {Container} from '@/components/ui/container/Container'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import styled, {css} from 'styled-components'

export const StyledPhotosetFinalContainer = styled(Container)(
    ({theme: {palette}}) => css`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: ${palette.neutral[50]};
    `
)

export const StyledPhotosetFinalCopyWrapper = styled(Flexbox)(
    ({theme: {spacing}}) => css`
        margin: ${spacing * 8}px auto ${spacing * 6}px auto;
        text-align: center;
        width: 392px;
    `
)

export const StyledPhotosetFinalTitle = styled.h1(
    ({theme: {typography, palette}}) => css`
        ${typography.displayXs};
        font-weight: 700;
        color: ${palette.neutral[900]};
    `
)

export const StyledPhotosetFinalParapraph = styled.p(
    ({theme: {typography, palette}}) => css`
        ${typography.textXl};
        color: ${palette.neutral[900]};
    `
)
