import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const StyledCurationSleElementHeader = styled(Flexbox)(
    ({theme: {spacing, typography, palette}}) => css`
        position: relative;
        width: 100%;
        padding: ${spacing * 4}px ${spacing * 6}px;
        z-index: 1;
        & h4 {
            ${typography.textLg};
            color: ${palette.neutral.black};
            font-weight: 500;
        }
        & p {
            ${typography.textSm};
            color: ${palette.neutral['600']};
        }
    `
)
