import {FC, ReactNode} from 'react'
import {StyledChip} from '@/components/commons/chip/style'
import {DefaultNamespace} from 'i18next'

export type ChipProps = {
    label: string | DefaultNamespace
    isActive?: boolean
    onClick?: () => void
    startSlot?: ReactNode
    className?: string
    endSlot?: ReactNode
    variant?: 'success' | 'info' | 'neutral' | 'danger'
    size?: 'sm' | 'md'
}

export const Chip: FC<ChipProps> = ({
    label,
    className,
    startSlot,
    endSlot,
    onClick,
    isActive = false,
    variant = 'info',
    size = 'sm'
}) => (
    <StyledChip
        className={className}
        align="center"
        title={label}
        onClick={onClick}
        $variant={variant}
        $size={size}
        $isActive={isActive}
        $isClickable={!!onClick}
    >
        {startSlot}

        <span>{label}</span>

        {endSlot}
    </StyledChip>
)
