import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import styled, {css} from 'styled-components'

export const StyledPhotosetSpaceElementHeader = styled(Flexbox)(
    ({theme: {spacing, typography, palette}}) => css`
        position: relative;
        width: 100%;
        padding: ${spacing * 4}px ${spacing * 6}px;
        z-index: 1;
        & > h3 {
            ${typography.textLg};
            color: ${palette.neutral.black};
            font-weight: 500;
        }
        & > h4 {
            ${typography.textSm};
            color: ${palette.neutral[600]};
        }
        & p {
            ${typography.textSm};
            color: ${palette.neutral['600']};
        }
    `
)
