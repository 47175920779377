import {FC} from 'react'
import {StyledSwitchRoot, StyledSwitchThumb, StyledSwitchWrapper} from '@/components/ui/switch/style'

interface SwitchProps {
    className?: string
    label?: string
    labelPosition?: 'left' | 'right'
    disabled?: boolean
    checked?: boolean
    onChange?: (checked: boolean) => void
}

export const Switch: FC<SwitchProps> = ({
    label,
    className,
    labelPosition = 'right',
    disabled,
    checked = false,
    onChange
}) => {
    return (
        <StyledSwitchWrapper
            className={className}
            align="center"
            gap={2}
            direction={labelPosition === 'right' ? 'row' : 'row-reverse'}
        >
            <StyledSwitchRoot
                disabled={disabled}
                checked={checked}
                onCheckedChange={value => onChange && onChange(value)}
            >
                <StyledSwitchThumb />
            </StyledSwitchRoot>
            {label && <label>{label}</label>}
        </StyledSwitchWrapper>
    )
}

Switch.displayName = 'Switch'
