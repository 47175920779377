import {Flag01Icon} from '@/components/ui/icon/Icon'
import {useTranslation} from 'react-i18next'
import {StyledMultipleFlagsPopoverTrigger} from './style'

export const MultipleFlagsPopoverTrigger = () => {
    const {t} = useTranslation()

    return (
        <StyledMultipleFlagsPopoverTrigger size="sm" variant="secondary">
            <Flag01Icon />
            {t('commons:multiple_flags_btn')}
        </StyledMultipleFlagsPopoverTrigger>
    )
}
