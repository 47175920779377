import {ReactElement} from 'react'
import {StyledPageFooter, StyledPageFooterBody} from '@components/commons/page-footer/style.ts'

interface PageFooterProps {
    startSlot?: ReactElement
    centerSlot?: ReactElement
    endSlot?: ReactElement
}

export const PageFooter = ({startSlot, centerSlot, endSlot}: PageFooterProps) => {
    return (
        <StyledPageFooter>
            <StyledPageFooterBody fullWidth>
                {startSlot ?? <div />}
                {centerSlot ?? <div />}
                {endSlot ?? <div />}
            </StyledPageFooterBody>
        </StyledPageFooter>
    )
}

PageFooter.displayName = 'PageFooter'
