import {useTranslation} from 'react-i18next'
import {Helmet} from 'react-helmet'
import {Header} from '@/components/commons/header/Header'
import {useAuthStore} from '@/features/auth/store/store.ts'
import {StyledWelcomeContent, StyledWelcomeContainer, StyledWelcomeWrapper} from '@pages/welcome/style.ts'
import {User01Icon} from '@components/ui/icon/Icon.tsx'

export const Welcome = () => {
    const {t} = useTranslation()
    const user = useAuthStore(state => state.user)

    return (
        <>
            <Helmet title={t('welcome:page_title_meta')} />
            <StyledWelcomeWrapper>
                <Header />
                <StyledWelcomeContainer>
                    <StyledWelcomeContent direction={'column'} align={'center'}>
                        <User01Icon size={32} />
                        <section>
                            <h1>{t('welcome:title', {username: user?.first_name ?? user?.email})}</h1>
                            <p>{t('welcome:subtitle')}</p>
                        </section>
                        {/*<ButtonLink
                            to={
                                'https://airtable.com/appYRzbV4B1UTHsKr/tblyGqCFVTMgjzja4/viwtFjyEZDyORzyOV?blocks=hide'
                            }
                            target={'_blank'}
                        >
                            <AirtableIcon />
                            {t('welcome:cta')}
                        </ButtonLink>*/}
                    </StyledWelcomeContent>
                </StyledWelcomeContainer>
            </StyledWelcomeWrapper>
        </>
    )
}

Welcome.displayName = 'Welcome'
