import styled, {css} from 'styled-components'

export const StyledDeallocateImageButton = styled.button<{$isActive: boolean}>(
    ({theme: {palette, typography}, $isActive: isActive}) => css`
        ${typography.textMd};
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        left: 0;
        top: 0;
        cursor: pointer;
        background-color: ${palette.neutral.black}4D;
        color: ${palette.neutral.white};
        width: 100%;
        height: 100%;
        transition: opacity ease-in-out 200ms;

        ${!isActive &&
        css`
            &:not(:hover) {
                opacity: 0;
            }
        `}
    `
)
