import {Container} from '@/components/ui/container/Container'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import styled, {css} from 'styled-components'
import bg from '@assets/images/dot-pattern-bg.png'

export const StyledPhotosetRejectedContainer = styled(Container)(
    ({theme: {palette}}) => css`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &:before {
            content: '';
            position: fixed;
            width: 100vw;
            height: 100vh;
            left: 0;
            top: 0;
            background-color: ${palette.neutral[50]};
            background-image: url(${bg});
            z-index: -1;
        }
    `
)

export const StyledPhotosetRejectedCopyWrapper = styled(Flexbox)`
    text-align: center;
    width: 392px;
`

export const StyledPhotosetRejectedTitle = styled.h1(
    ({theme: {typography, palette}}) => css`
        ${typography.displayXs};
        font-weight: 700;
        color: ${palette.neutral[900]};
    `
)

export const StyledPhotosetRejectedParapraph = styled.p(
    ({theme: {typography, palette}}) => css`
        ${typography.textXl};
        color: ${palette.neutral[900]};
    `
)
