import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import styled, {css} from 'styled-components'

export const StyledGalleryNeedsReviewModalSidebarContent = styled(Flexbox)(
    ({theme: {spacing, typography, palette}}) => css`
        padding: ${spacing * 4}px;

        & > h4 {
            ${typography.textSm};
            font-weight: 500;
            color: ${palette.neutral[900]};
        }
    `
)

export const StyledGalleryNeedsReviewFlags = styled(Flexbox)(
    ({theme: {typography}}) => css`
        flex-wrap: wrap;

        & > p {
            ${typography.textSm};
        }
    `
)
