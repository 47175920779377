import {ConfirmActionModal} from '@/components/commons/confirm-action-modal/ConfirmActionModal'
import {useTranslation} from 'react-i18next'
import {errorHandler} from '@/utilities/helpers'
import {QUERY_KEYS, queryClient} from '@/queryClient'
import {useUpdateStepToAiVettingCompleted} from '@/features/photoset/services/useUpdateStepToAiVettingCompleted'

export const AiVettingConfirmModal = ({photosetId, onClose}: {photosetId: number; onClose: () => void}) => {
    const {t} = useTranslation()

    const updateStepToAiVettingCompleted = useUpdateStepToAiVettingCompleted({
        onError: errorHandler,
        onSuccess: () => {
            void queryClient.invalidateQueries({queryKey: [QUERY_KEYS.PHOTOSET]})
            void queryClient.invalidateQueries({queryKey: [QUERY_KEYS.PHOTOSET_SPACES]})
        }
    })

    return (
        <ConfirmActionModal
            isLoading={updateStepToAiVettingCompleted.isPending}
            onClose={onClose}
            onConfirm={() => updateStepToAiVettingCompleted.mutate(photosetId)}
            headerTitle=""
            title={t('photoset:ai_vetting:modal:no_selection_title')}
            paragraph={t('photoset:ai_vetting:modal:no_selection_subtitle')}
        />
    )
}
