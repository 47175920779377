import {useRedirectQaPhotoset} from '@/features/photoset/hooks/useRedirectQaPhotoset'
import {useGetPhotoset} from '@/features/photoset/services/useGetPhotoset'
import {PhotosetURLParams} from '@/features/photoset/types'
import {Outlet, useOutletContext} from 'react-router-dom'

export const PhotosetQaRoute = () => {
    const outletContext = useOutletContext<{urlParams: PhotosetURLParams}>()
    const {data: photoset} = useGetPhotoset({id: outletContext.urlParams.id}, {staleTime: Infinity})

    // Redirect the user to the current QA Step
    useRedirectQaPhotoset({
        photosetId: photoset?.id,
        qaStepId: photoset?.qa_step_id
    })

    return <Outlet context={outletContext} />
}
