import {useTranslation} from 'react-i18next'
import {useGetFlags} from '@/features/photoset/services/useGetFlags'
import {usePhotosetStore} from '@/features/photoset/store/store'
import {FC, ReactNode, useMemo, useState} from 'react'
import {raise} from '@/utilities/helpers'
import {Flag, FlagWithLabel, Image, Photoset, PhotosetSpace} from '@/features/photoset/types'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {AlertTriangleIcon, FileX02Icon, SearchLgIcon} from '@/components/ui/icon/Icon'
import {
    StyledFlagsPopoverGroup,
    StyledFlagsPopoverGroupLabel,
    StyledPopoverFlagsList,
    StyledFlagsPopoverContent,
    StyledInputSearchFlag,
    StyledSearchFlag,
    StyledFlagsPopoverTrigger
} from '@/features/photoset/components/flags-popover/style'
import {Divider} from '@/components/ui/divider/Divider'
import * as Popover from '@radix-ui/react-popover'
import {ScrollArea} from '@/components/commons/scroll-area/ScrollArea'
import {Scrollbar} from '@/components/ui/scrollbar/Scrollbar'
import {FlagsPopoverItem} from '@/features/photoset/components/flags-popover/FlagPopoverItem'
import {EmptyFlagsResult} from '@/features/photoset/components/flags-popover/EmptyFlagsResult'
import {CSSProperties, useTheme} from 'styled-components'
import {RemoveImagesFlagData} from '@/features/photoset/services/useRemoveImagesFlag'

interface FlagsPopoverProps {
    trigger: ReactNode
    selectedImagesIds: Image['id'][]
    disableScrollBelow?: boolean
    stepId: Photoset['step_id']
    types?: Flag['type'][]
    photosetSpaceIds?: PhotosetSpace['id'][]
    align?: Popover.PopoverContentProps['align']
    isUpdateDisabled?: boolean
    hideDescriptions?: boolean
    onRemoveFlagCb?: (data: RemoveImagesFlagData) => void
    width?: CSSProperties['width']
    triggerWidth?: CSSProperties['width']
}

export const FlagsPopover: FC<FlagsPopoverProps> = ({
    trigger,
    selectedImagesIds,
    stepId,
    disableScrollBelow = false,
    types = ['metadata', 'visual_metadata', 'visual'],
    align = 'start',
    photosetSpaceIds,
    isUpdateDisabled = false,
    hideDescriptions = false,
    onRemoveFlagCb,
    width = '320px',
    triggerWidth
}) => {
    const [search, setSearch] = useState('')
    const {t} = useTranslation()
    const {images} = usePhotosetStore()

    const theme = useTheme()

    const flagsQuery = useGetFlags({
        params: {
            types,
            response_type: 'extended'
        }
    })

    const flagsList = useMemo(() => {
        const appliedAllFlags: FlagWithLabel[] = []
        const appliedSomeFlags: FlagWithLabel[] = []
        const otherFlags: FlagWithLabel[] = []
        const selectedImages = (selectedImagesIds ?? raise('No image selected')).map(imageId => images.get(imageId))

        const hasFlag = (image: Image | null | undefined, flag: Flag): boolean => {
            if (!image) return false

            const hasImageFlag = image.flags?.some(imageFlag => imageFlag.id == flag.id)
            const isFlagApproved = image.image_flags?.some(
                imageFlag => imageFlag.flag_id == flag.id && imageFlag.is_flag_approved
            )

            return !!(hasImageFlag && isFlagApproved)
        }

        const searchFlagByLabel = (flags: FlagWithLabel[], inputSearch: string) =>
            flags.filter(flag => flag.label.toLowerCase().includes(inputSearch.toLowerCase()))

        flagsQuery.data &&
            flagsQuery.data.forEach(flag => {
                if (selectedImages.every(image => hasFlag(image, flag))) {
                    appliedAllFlags.push({...flag, label: t(`photoset:flags:${flag.key_name}:label`)})
                } else if (selectedImages.some(image => hasFlag(image, flag))) {
                    appliedSomeFlags.push({...flag, label: t(`photoset:flags:${flag.key_name}:label`)})
                } else {
                    otherFlags.push({...flag, label: t(`photoset:flags:${flag.key_name}:label`)})
                }
            })

        return {
            appliedAllFlags: searchFlagByLabel(appliedAllFlags, search),
            appliedSomeFlags: searchFlagByLabel(appliedSomeFlags, search),
            otherFlags: searchFlagByLabel(otherFlags, search)
        }
    }, [images, flagsQuery, selectedImagesIds])

    return (
        <Popover.Root onOpenChange={visibility => visibility && setSearch('')} modal={disableScrollBelow}>
            <Popover.Trigger asChild>
                <StyledFlagsPopoverTrigger $width={triggerWidth}>{trigger}</StyledFlagsPopoverTrigger>
            </Popover.Trigger>

            <Popover.Portal>
                <StyledFlagsPopoverContent align={align} sideOffset={12} $width={width}>
                    {flagsQuery.isError ? (
                        <EmptyFlagsResult title={t('errors:default')} icon={<AlertTriangleIcon />} hasRetry />
                    ) : flagsList.appliedAllFlags.length == 0 &&
                      flagsList.appliedSomeFlags.length &&
                      flagsList.otherFlags.length == 0 ? (
                        <EmptyFlagsResult title={t('commons:no_results')} icon={<FileX02Icon />} hasRetry />
                    ) : flagsQuery.isFetching ? (
                        <EmptyFlagsResult
                            title={t('commons:loading')}
                            icon={<Spinner size={16} color={theme.palette.neutral['900']} />}
                            hasRetry={false}
                        />
                    ) : (
                        <>
                            <StyledSearchFlag align="center" gap={2}>
                                <SearchLgIcon size={20} />
                                <StyledInputSearchFlag
                                    type="text"
                                    placeholder={t('photoset:search_flags')}
                                    value={search}
                                    onChange={e => setSearch(e.currentTarget.value)}
                                />
                            </StyledSearchFlag>

                            <Divider direction="horizontal" />

                            <ScrollArea scrollbar={<Scrollbar />}>
                                <StyledPopoverFlagsList>
                                    {(flagsList.appliedAllFlags.length > 0 ||
                                        flagsList.appliedSomeFlags.length > 0) && (
                                        <>
                                            <StyledFlagsPopoverGroup>
                                                <StyledFlagsPopoverGroupLabel>
                                                    {t('commons:applied')}
                                                </StyledFlagsPopoverGroupLabel>

                                                {flagsList.appliedAllFlags.map(flag => (
                                                    <FlagsPopoverItem
                                                        key={flag.id}
                                                        flag={flag}
                                                        stepId={stepId}
                                                        selectedImagesIds={selectedImagesIds}
                                                        photosetSpaceIds={photosetSpaceIds}
                                                        defaultChecked
                                                        isUpdateDisabled={isUpdateDisabled}
                                                        hideDescription={hideDescriptions}
                                                        onRemoveFlagCb={onRemoveFlagCb}
                                                    />
                                                ))}

                                                {flagsList.appliedSomeFlags.map(flag => (
                                                    <FlagsPopoverItem
                                                        key={flag.id}
                                                        flag={flag}
                                                        stepId={stepId}
                                                        selectedImagesIds={selectedImagesIds}
                                                        photosetSpaceIds={photosetSpaceIds}
                                                        isIndeterminate
                                                        isUpdateDisabled={isUpdateDisabled}
                                                        hideDescription={hideDescriptions}
                                                        onRemoveFlagCb={onRemoveFlagCb}
                                                    />
                                                ))}
                                            </StyledFlagsPopoverGroup>

                                            <Divider direction="horizontal" />
                                        </>
                                    )}

                                    <StyledFlagsPopoverGroup>
                                        {flagsList.otherFlags.map(flag => (
                                            <FlagsPopoverItem
                                                key={flag.id}
                                                flag={flag}
                                                stepId={stepId}
                                                selectedImagesIds={selectedImagesIds}
                                                photosetSpaceIds={photosetSpaceIds}
                                                isUpdateDisabled={isUpdateDisabled}
                                                hideDescription={hideDescriptions}
                                                onRemoveFlagCb={onRemoveFlagCb}
                                            />
                                        ))}
                                    </StyledFlagsPopoverGroup>
                                </StyledPopoverFlagsList>
                            </ScrollArea>
                        </>
                    )}
                </StyledFlagsPopoverContent>
            </Popover.Portal>
        </Popover.Root>
    )
}
