import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {CheckCircleIcon, DownloadCloud02Icon} from '@/components/ui/icon/Icon'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {PhotosetURLParams} from '@/features/photoset/types'
import {
    StyledImagenAiStepsCopyWrapper,
    StyledImagenAiStepsContainer,
    StyledImagenAiStepsParapraph,
    StyledImagenAiStepsTitle
} from '@/pages/photoset/photoset-imagen-ai-steps/style'
import {useTranslation} from 'react-i18next'
import {useOutletContext} from 'react-router-dom'
import {useTheme} from 'styled-components'
import {useGetPhotosetImagesList} from '@/features/photoset/services/useGetPhotosetImagesList.ts'
import {useGetPhotoset} from '@/features/photoset/services/useGetPhotoset.ts'
import {ButtonLink} from '@components/ui/button-link/ButtonLink.tsx'
import {
    StyledCompletedStepsParapraph,
    StyledCompletedStepsTitle
} from '@pages/photoset/photoset-completed-steps/style.ts'
import {useAsync} from '@hooks/useAsync.ts'
import {httpLogRetouchedZipDownload} from '@/features/photoset/services/photoset.http.ts'

export const PhotosetImagenAiStep = () => {
    const {t} = useTranslation()
    const {palette} = useTheme()
    const {urlParams} = useOutletContext<{urlParams: PhotosetURLParams}>()
    const {run} = useAsync()
    //queries
    const {data: photoset, isFetching: isPhotosetFetching} = useGetPhotoset({id: urlParams.id}, {enabled: false})
    const {data: images, isFetching: isImagesFetching} = useGetPhotosetImagesList({
        urlParams: {photosetId: urlParams.id}
    })

    const onDownloadRetouchedLog = () => {
        run(httpLogRetouchedZipDownload(urlParams.id))
    }

    if (!isPhotosetFetching && !isImagesFetching && !photoset?.retouched_zip_url) {
        return (
            <StyledImagenAiStepsContainer>
                <StyledImagenAiStepsCopyWrapper direction="column" gap={3} align="center">
                    <Spinner size={32} color={palette.neutral.black} />
                    <StyledCompletedStepsTitle>{t('photoset:imagen_ai:in_progress:title')}</StyledCompletedStepsTitle>
                    <StyledCompletedStepsParapraph>
                        {t('photoset:imagen_ai:in_progress:paragraph')}
                    </StyledCompletedStepsParapraph>
                </StyledImagenAiStepsCopyWrapper>
            </StyledImagenAiStepsContainer>
        )
    }

    return (
        <StyledImagenAiStepsContainer>
            {isPhotosetFetching || isImagesFetching ? (
                <Spinner size={48} color={palette.neutral['500']} />
            ) : (
                <>
                    <CheckCircleIcon fill={palette.success['600']} width={48} height={48} />

                    <StyledImagenAiStepsCopyWrapper direction="column" gap={2} align="center">
                        <StyledImagenAiStepsTitle>{t('photoset:imagen_ai:completed:title')}</StyledImagenAiStepsTitle>
                        <StyledImagenAiStepsParapraph>
                            {t('photoset:imagen_ai:completed:paragraph')}
                        </StyledImagenAiStepsParapraph>
                    </StyledImagenAiStepsCopyWrapper>

                    <Flexbox gap={3} direction="column" align="center">
                        <ButtonLink
                            size="lg"
                            variant="primary"
                            to={photoset?.retouched_zip_url ?? ''}
                            onClick={() => onDownloadRetouchedLog()}
                            disabled={!photoset?.retouched_zip_url}
                        >
                            <DownloadCloud02Icon />
                            {t('photoset:imagen_ai:completed:download_retouched', {
                                count: images?.filter(image => image.is_final_select).length
                            })}
                        </ButtonLink>
                        <ButtonLink
                            size="sm"
                            variant="tertiary"
                            to={photoset?.zip_url ?? ''}
                            disabled={isPhotosetFetching && isImagesFetching}
                        >
                            {t('photoset:imagen_ai:completed:download_all_set')}
                        </ButtonLink>
                    </Flexbox>
                </>
            )}
        </StyledImagenAiStepsContainer>
    )
}

PhotosetImagenAiStep.displayName = 'PhotosetImagenAiSteps'
