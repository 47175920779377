import {FC, useEffect, useRef} from 'react'
import {ScrollArea} from '@/components/commons/scroll-area/ScrollArea'
import {Scrollbar} from '@/components/ui/scrollbar/Scrollbar'
import {
    StyledGalleryPlaceholderThumb,
    StyledGalleryModalThumbnail,
    StyledGalleryModalThumbnails
} from '@/components/commons/gallery-modal-thumbnails/style'
import {Image} from '@/features/photoset/types'
import {ImageXIcon} from '@/components/ui/icon/Icon'
import {useTranslation} from 'react-i18next'

interface GalleryModalThumbnailsProps {
    currentImage: Image | Omit<Image, 'space' | 'thumbnails'>
    thumbnails: 'thumbnails' | 'ai_retouched_thumbnails' | 'retouched_thumbnails'
    thumbnailsFallback?: 'thumbnails' | 'ai_retouched_thumbnails' | 'retouched_thumbnails'
    images: Image[] | Omit<Image, 'space'>[]
    onSelect: (image: Image | Omit<Image, 'space'>, imageIndex: number) => void
}

export const GalleryModalThumbnails: FC<GalleryModalThumbnailsProps> = ({
    currentImage,
    thumbnails,
    thumbnailsFallback,
    images,
    onSelect
}) => {
    const {t} = useTranslation()
    const previewScrollViewportRef = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        if (previewScrollViewportRef.current && currentImage.id) {
            const selectedImageBox = document.getElementById(`${currentImage.id}`)?.getBoundingClientRect()
            const scrollbarPosition = previewScrollViewportRef.current.scrollLeft

            if (selectedImageBox) {
                previewScrollViewportRef.current.scrollTo({
                    left: selectedImageBox.x - selectedImageBox.width + scrollbarPosition,
                    behavior: 'smooth'
                })
            }
        }
    }, [previewScrollViewportRef.current, currentImage.id])

    return (
        <ScrollArea ref={previewScrollViewportRef} scrollbar={<Scrollbar orientation="horizontal" />}>
            <StyledGalleryModalThumbnails gap={4}>
                {images.map((image, index) => {
                    const thumbnail = image[thumbnails]?.xs ?? (thumbnailsFallback && image[thumbnailsFallback]?.xs)
                    return thumbnail ? (
                        <StyledGalleryModalThumbnail
                            id={`${image.id}`}
                            $isSelected={currentImage.id == image.id}
                            onClick={() => onSelect(image, index)}
                            src={thumbnail}
                            alt={image.name}
                            key={image.id}
                            loading="lazy"
                        />
                    ) : (
                        <StyledGalleryPlaceholderThumb
                            id={`${image.id}`}
                            gap={2}
                            direction="column"
                            align="center"
                            justify="center"
                            $isSelected={currentImage.id == image.id}
                            onClick={() => onSelect(image, index)}
                        >
                            <ImageXIcon size={18} />
                            <p>{t('errors:unavailable', {subject: 'preview'})}</p>
                        </StyledGalleryPlaceholderThumb>
                    )
                })}
            </StyledGalleryModalThumbnails>
        </ScrollArea>
    )
}
