import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {PhotosetIndex} from '@/features/photoset/components/photoset-index/PhotosetIndex'
import {qaStepIdToStepSlug} from '@/features/photoset/hooks/useRedirectQaPhotoset'
import {useGetPhotoset} from '@/features/photoset/services/useGetPhotoset'
import {PhotosetURLParams} from '@/features/photoset/types'
import {qaStepsFlow} from '@/features/photoset/utils'
import {useTranslation} from 'react-i18next'
import {useOutletContext} from 'react-router-dom'
import {Styled2LAButtonLink} from './style'

export const Photoset2LaIndex = () => {
    const {t} = useTranslation()
    const {urlParams} = useOutletContext<{urlParams: PhotosetURLParams}>()
    const {data: photoset, isLoading} = useGetPhotoset({id: urlParams.id}, {enabled: false})

    const _2LaSteps = [
        {
            slug: 'editing-qa-selection',
            label: t('photoset:editingQaSelection:title')
        },
        {
            slug: 'editing-qa-flags',
            label: t('photoset:editingQaFlags:title')
        },
        {
            slug: 'vetting-qa',
            label: t('photoset:vetting_qa:title')
        },
        {
            slug: 'retouching-qa',
            label: t('photoset:retouching_qa:title')
        }
    ]

    const is2LaStepLinkEnabled = (qaStepId: string) => {
        if (!photoset?.qa_step_id) return false

        const qaStepKey = photoset.qa_step_id
            ? qaStepIdToStepSlug?.[photoset.qa_step_id as keyof typeof qaStepIdToStepSlug]
            : null
        const selectedStepFlowIndex = qaStepsFlow.findIndex(step => step == qaStepId)
        const qaStepFlowIndex = qaStepsFlow.findIndex(step => step == qaStepKey)

        return qaStepFlowIndex > selectedStepFlowIndex
    }

    return (
        <PhotosetIndex
            title={t('photoset:2la_index:title')}
            isLoading={isLoading}
            endSlot={
                <Flexbox direction="column" align="center" gap={4}>
                    <ul>
                        {_2LaSteps.map(step => (
                            <li key={step.slug}>
                                <Styled2LAButtonLink
                                    to={`/photoset/${urlParams.id}/2la/${step.slug}`}
                                    variant="ghost"
                                    disabled={!is2LaStepLinkEnabled(step.slug)}
                                >
                                    {step.label}
                                </Styled2LAButtonLink>
                            </li>
                        ))}
                    </ul>
                </Flexbox>
            }
        />
    )
}
