import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import styled, {css} from 'styled-components'

export const StyledRetouchingQaImageInfo = styled(Flexbox)`
    & > .tooltip-trigger {
        flex-shrink: 0;
    }
`

export const StyledRetouchingQaImageInfoName = styled(Flexbox)`
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`

export const StyledSendBackCheckboxContainer = styled(Flexbox)<{$isDisabled?: boolean}>(
    ({theme: {palette, typography}, $isDisabled}) => css`
        & input[type='checkbox'] {
            width: 16px;
            height: 16px;
        }
        & > span {
            ${typography.textXs};
            font-weight: 500;
            color: ${$isDisabled ? palette.neutral['300'] : palette.neutral.black};
        }
    `
)
