import {errorHandler} from '@/utilities/helpers'
import {useImageSelectionApproval} from '../services/useImageSelectionApproval'
import {Photoset, PhotosetSpace} from '../types'
import {RemoveImagesFlagData} from '../services/useRemoveImagesFlag'
import {usePhotosetStore} from '../store/store'

export const useRemoveRetouchingQaFlag = ({
    photosetId,
    photosetSpaceId
}: {
    photosetId: Photoset['id']
    photosetSpaceId: PhotosetSpace['id']
}) => {
    const images = usePhotosetStore(state => state.images)

    const {mutate: sendBackImage} = useImageSelectionApproval({
        photosetId,
        photosetSpaceId,
        options: {
            onError: errorHandler
        }
    })

    const onRemoveFlagCb = (variables: RemoveImagesFlagData) => {
        variables.imageIds.forEach(imageId => {
            const image = images.get(imageId)
            const newFlagsList = image?.flags.filter(
                flag => flag.key_name != variables.flag.key_name && flag.type == 'visual_retouching'
            )

            if (newFlagsList?.length == 0 && image) {
                sendBackImage({
                    imageId: image.id,
                    needs_to_review: false
                })
            }
        })
    }

    return {
        onRemoveFlagCb,
        sendBackImage
    }
}
