import axios from '../../../axiosInstance.ts'
import {LoginValidationSchema} from '@pages/login/LoginFormModel.ts'
import z from 'zod'
import {User} from '@/features/auth/types.ts'

/**
 * Simple Login function
 */
export const httpLogin = (params: LoginValidationSchema) => {
    return axios.post('/luminate/login', params)
}

/**
 * Simple Logout function
 */
export const httpLogout = () => {
    return axios.get('/luminate/logout')
}

/**
 * @httpUserMe
 * Simple Me function
 */
export const HttpUserMeResponse = User // Directly use already created User schema
type HttpUserMeResponse = z.infer<typeof HttpUserMeResponse>
export const httpUserMe = () => {
    return axios.get(`/luminate/users/me`)
}

/**
 * @httpResetPassword
 * Ask for an email that allow the user to reset a password
 */
export const HttpResetUserPassword = z.object({password: z.string()}) // Directly use already created User schema
type HttpResetUserPassword = z.infer<typeof HttpResetUserPassword>
export const httpResetPassword = (data: {email: string}) => {
    return axios.post(`/luminate/reset-password`, {email: data.email})
}

/**
 * @httpSetPassword
 * Set password for new user
 */
export const HttpSetUserPassword = z.object({email: z.string().email()}) // Directly use already created User schema
type HttpSetUserPassword = z.infer<typeof HttpSetUserPassword>
export const httpSetPassword = (data: {token: string; password: string}) => {
    return axios.post(`/luminate/set-password?token=${data.token}`, {password: data.password})
}
