import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import styled, {css} from 'styled-components'

export const StyledManualRetouchingCard = styled(Flexbox)(
    ({theme: {palette, spacing}}) => css`
        height: 470px;
        border: 1px solid ${palette.neutral[300]};
        border-radius: 16px;
        padding: ${spacing * 6}px;
        color: ${palette.neutral[900]};
        text-align: center;

        & > svg {
            color: ${palette.neutral[900]};
        }
    `
)

export const StyledManualRetouchingCardTitle = styled.h3(
    ({theme: {typography}}) => css`
        ${typography.textLg};
        font-weight: 500;
    `
)
