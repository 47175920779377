import {GalleryModal} from '@/components/commons/gallery-modal/GalleryModal'
import {Button} from '@/components/ui/button/Button'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import styled, {css} from 'styled-components'

export const StyledSpacesList = styled(Flexbox)(
    ({theme: {spacing}}) => css`
        height: 100%;
        overflow-y: auto;
        padding: ${spacing * 6}px ${spacing * 2}px ${spacing * 6}px ${spacing * 6}px;
    `
)

export const StyledSpaceCardButton = styled(Button)(
    ({theme: {spacing}}) => css`
        padding: ${spacing * 5.5}px;
    `
)

export const StyledGalleryAllocation = styled(GalleryModal)(
    ({theme: {palette}}) => css`
        background-color: ${palette.neutral.white}cc;
        backdrop-filter: blur(8px);
    `
)
