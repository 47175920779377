import {useTranslation} from 'react-i18next'
import {ScrollArea} from '@components/commons/scroll-area/ScrollArea.tsx'
import {Scrollbar} from '@components/ui/scrollbar/Scrollbar.tsx'
import {
    StyledCounterBadge,
    StyledDragIcon,
    StyledImageCard,
    StyledImagesPool,
    StyledImagesSelectedLabel
} from '@/features/photoset/components/allocation-images-pool/style.ts'
import {ImageCard} from '@components/commons/image-card/ImageCard.tsx'
import {usePhotosetStore} from '@/features/photoset/store/store.ts'
import {AlertCircleIcon, MoveIcon} from '@components/ui/icon/Icon.tsx'
import {ErrorBox} from '@components/commons/error-box/ErrorBox.tsx'
import {Skeleton} from '@components/ui/skeleton/Skeleton.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {ListHeader} from '@components/ui/list-header/ListHeader.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {AllocateDropdown} from '@/features/photoset/components/allocate-dropdown/AllocateDropdown.tsx'
import {useGetPhotosetImagesList} from '@/features/photoset/services/useGetPhotosetImagesList.ts'
import {ImagesList} from '@/features/photoset/components/images-list/ImagesList'
import {useOutletContext} from 'react-router-dom'
import {Image, PhotosetSpace, PhotosetURLParams} from '@/features/photoset/types.ts'
import {CompletedAllocationBox} from '@/features/photoset/components/completed-allocation-box/CompletedAllocationBox.tsx'
import {Dispatch, SetStateAction, useMemo, useState} from 'react'
import toast from 'react-hot-toast'
import {errorHandler} from '@utilities/helpers.ts'
import {useDeallocateImages} from '@/features/photoset/services/useDeallocateImages.ts'
import {useAllocateImages} from '@/features/photoset/services/useAllocateImages'
import {generateToastAllocationId} from '@/features/photoset/utils'
import {handleImagesRangeSelect} from '@/features/photoset/utils.ts'
import {ExpandImageButton} from '@/components/commons/expand-image-button/ExpandImageButton'
import {GalleryAllocationModal} from '@/features/photoset/components/gallery-photoset-modal/gallery-allocation-modal/GalleryAllocationModal'
import {Draggable} from '@components/ui/dnd-atoms/DndAtoms.tsx'
import {useMutationState} from '@tanstack/react-query'
import {MUTATION_KEYS} from '@/queryClient'
import {HttpAllocateImagesOptions} from '@/features/photoset/services/photoset.http'

type AllocationImagesPoolProps = {
    currentDetailImageIndex: number | null
    setCurrentDetailImageIndex: Dispatch<SetStateAction<number | null>>
}
export const AllocationImagesPool = ({
    currentDetailImageIndex,
    setCurrentDetailImageIndex
}: AllocationImagesPoolProps) => {
    const {t} = useTranslation()
    const {urlParams} = useOutletContext<{urlParams: PhotosetURLParams}>()
    const images = usePhotosetStore(state => state.images)
    const selectImages = usePhotosetStore(state => state.selectImages)
    const unselectAll = usePhotosetStore(state => state.unselectAll)
    const unselectImages = usePhotosetStore(state => state.unselectImages)
    const selectedImages = usePhotosetStore(state => state.selectedImages)
    const deallocateImages = usePhotosetStore(state => state.deallocateImages)
    const imagesQuery = useGetPhotosetImagesList({urlParams: {photosetId: urlParams.id}}, {enabled: false})
    const unallocatedImages = [...images.values()].filter(image => !image.space)
    const deallocateImagesMutation = useDeallocateImages()
    const [lastSelectedImageId, setLastSelectedImageId] = useState<number | null>(null)

    const allocateImagesMutation = useAllocateImages({
        onUndo: async (imagesIds: Image['id'][], photosetSpaceId: PhotosetSpace['id']) => {
            try {
                await deallocateImagesMutation.mutateAsync({
                    urlParams: {
                        photosetId: urlParams.id,
                        photosetSpaceId: photosetSpaceId
                    },
                    payload: selectedImages
                })

                deallocateImages({
                    imagesIds,
                    photosetSpaceId,
                    keepSelected: true,
                    trackInHistory: false
                })

                const toastId = generateToastAllocationId('allocation', photosetSpaceId, imagesIds)
                toast.dismiss(toastId)
            } catch (error) {
                errorHandler(error)
            }
        },
        options: {
            onError: errorHandler
        }
    })

    // Get all the mutation status targetized to the allocate images key
    const allocateMutationState = useMutationState({
        filters: {mutationKey: [MUTATION_KEYS.ALLOCATE_IMAGES], status: 'pending'},
        select: mutation => ({
            variables: mutation.state.variables as HttpAllocateImagesOptions
        })
    })

    // Retrieve all the image ids that are in pending allocation
    const pendingAllocationImageIds = useMemo(
        () =>
            allocateMutationState.map(allocateState => ({
                imageIds: allocateState.variables.payload
            })),
        [allocateMutationState]
    )

    return (
        <StyledImagesPool>
            <ListHeader
                size="sm"
                title={
                    imagesQuery.isPending ? (
                        <Skeleton height={30} width={96} />
                    ) : (
                        t('commons:x_images', {count: unallocatedImages.length})
                    )
                }
                endSlot={
                    selectedImages.length >= 1 && (
                        <Flexbox align="center" gap={4}>
                            <Flexbox align="center" gap={2}>
                                <StyledImagesSelectedLabel>
                                    {t('commons:x_images_selected', {count: selectedImages.length})}
                                </StyledImagesSelectedLabel>
                                <Button
                                    size="sm"
                                    variant="tertiary"
                                    onClick={() => {
                                        unselectAll()
                                        setLastSelectedImageId(null)
                                    }}
                                >
                                    {t('commons:unselect_all')}
                                </Button>
                            </Flexbox>
                            <AllocateDropdown
                                onAllocate={({photosetSpaceId, imagesIds}) =>
                                    allocateImagesMutation.mutate({
                                        urlParams: {
                                            photosetId: urlParams.id,
                                            photosetSpaceId: photosetSpaceId
                                        },
                                        payload: imagesIds
                                    })
                                }
                            />
                        </Flexbox>
                    )
                }
            />
            {imagesQuery.isError ? (
                <ErrorBox icon={<AlertCircleIcon />} title={t('errors:default')} />
            ) : imagesQuery.isSuccess && unallocatedImages.length == 0 ? (
                <CompletedAllocationBox imagesCount={imagesQuery.data.length} />
            ) : (
                <ScrollArea scrollbar={<Scrollbar />}>
                    <ImagesList>
                        {imagesQuery.isPending
                            ? Array.from({length: 20}).map((_, index) => <ImageCard asSkeleton key={index} />)
                            : unallocatedImages.map((image, index, array) => {
                                  const isSelected = selectedImages.some(selectedImageId => selectedImageId == image.id)
                                  return (
                                      <Draggable
                                          id={image.id.toString()}
                                          key={image.id}
                                          draggingElement={
                                              <StyledImageCard
                                                  $isDragging
                                                  image={image}
                                                  isSelected={isSelected}
                                                  thumbnail={image.thumbnails?.xs}
                                                  endSlot={
                                                      selectedImages.length >= 2 && (
                                                          <StyledCounterBadge>
                                                              {selectedImages.length}
                                                          </StyledCounterBadge>
                                                      )
                                                  }
                                              />
                                          }
                                      >
                                          {(draggable, onPointerDown) => (
                                              <StyledImageCard
                                                  image={image}
                                                  isSelected={isSelected}
                                                  thumbnail={image.thumbnails?.xs}
                                                  isLoading={pendingAllocationImageIds.some(pendingStatus =>
                                                      pendingStatus.imageIds.includes(image.id)
                                                  )}
                                                  onSelect={isShiftPressed => {
                                                      handleImagesRangeSelect({
                                                          array,
                                                          image,
                                                          index,
                                                          isSelected,
                                                          isShiftPressed,
                                                          lastSelectedImageId,
                                                          selectImages: selectImages,
                                                          setLastSelectedImageId,
                                                          unselectImages: unselectImages
                                                      })
                                                  }}
                                                  endSlot={
                                                      <>
                                                          <StyledDragIcon
                                                              className="show-on-hover"
                                                              {...draggable.listeners}
                                                              onPointerDown={event => {
                                                                  if (!isSelected) {
                                                                      selectImages([image.id])
                                                                  }
                                                                  onPointerDown(event)
                                                              }}
                                                              ref={draggable.setActivatorNodeRef}
                                                          >
                                                              <MoveIcon size={16} />
                                                          </StyledDragIcon>
                                                          <ExpandImageButton
                                                              onClick={() => {
                                                                  setCurrentDetailImageIndex(index)
                                                                  unselectAll()
                                                                  selectImages([image.id])
                                                              }}
                                                          />
                                                      </>
                                                  }
                                              />
                                          )}
                                      </Draggable>
                                  )
                              })}
                    </ImagesList>
                </ScrollArea>
            )}

            {currentDetailImageIndex != null && (
                <GalleryAllocationModal
                    currentDetailImageIndex={currentDetailImageIndex}
                    setCurrentDetailImageIndex={setCurrentDetailImageIndex}
                    selectableImages={unallocatedImages}
                    onClose={() => {
                        setCurrentDetailImageIndex(null)
                        unselectAll()
                    }}
                />
            )}
        </StyledImagesPool>
    )
}

AllocationImagesPool.displayName = 'AllocationImagesPool'
