import styled from 'styled-components'

export const StyledPhotosetLayout = styled.div`
    height: 100%;
    display: grid;
    grid-template-rows: auto 1fr;
    overflow: hidden;
`

export const StyledPhotosetLayoutMain = styled.div`
    overflow-y: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
`
