import {Button} from '@/components/ui/button/Button'
import {Divider} from '@/components/ui/divider/Divider'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {AlertTriangleIcon, XCloseIcon} from '@/components/ui/icon/Icon'
import {ModalHeader, ModalParagraph, ModalTitle} from '@/components/ui/modal-atoms/ModalAtoms'
import {Modal} from '@/components/ui/modal/Modal'
import {useTranslation} from 'react-i18next'
import {StyledManualRetouchingErrorModalBody, StyledManualRetouchingErrorModalFooter} from './style'

export const ManualRetouchingErrorModal = ({onClose}: {onClose: () => void}) => {
    const {t} = useTranslation()
    return (
        <Modal width={480} closeIconOffset="sm" onOverlayClick={onClose}>
            <ModalHeader>
                <Button iconOnly size="sm" variant="tertiary" onClick={onClose}>
                    <XCloseIcon size={20} />
                </Button>
                <h3></h3>
            </ModalHeader>

            <Divider direction="horizontal" />

            <StyledManualRetouchingErrorModalBody>
                <Flexbox direction="column" gap={2} align="center">
                    <AlertTriangleIcon size={40} />
                    <ModalTitle>{t('photoset:ai_manual_retouching:error_modal_title')}</ModalTitle>
                    <ModalParagraph>{t('photoset:ai_manual_retouching:error_modal_paragraph')}</ModalParagraph>
                </Flexbox>
            </StyledManualRetouchingErrorModalBody>

            <Divider direction="horizontal" />

            <StyledManualRetouchingErrorModalFooter>
                <Button variant="tertiary" onClick={onClose}>
                    {t('commons:close')}
                </Button>
            </StyledManualRetouchingErrorModalFooter>
        </Modal>
    )
}
