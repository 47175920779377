import {Button} from '@/components/ui/button/Button'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import styled, {css} from 'styled-components'

export const StyledRetouchingQaCommentButton = styled(Button)(
    ({theme: {palette}}) => css`
        padding: 0;
        color: ${palette.neutral[600]};
    `
)

export const StyledRetouchingQaCommentPreview = styled(Flexbox)`
    & > p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 600px;
    }

    & svg {
        width: 14px;
    }
`
