import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const StyledCompletedAllocationBox = styled(Flexbox)(
    ({theme: {palette, typography, spacing}}) => css`
        padding: ${spacing * 6}px;
        & > svg {
            fill: ${palette.success['600']};
        }
        & h3 {
            ${typography.displayXs};
            font-weight: 700;
            color: ${palette.neutral['900']};
        }
        & h4 {
            ${typography.textXl};
            color: ${palette.neutral['900']};
        }
        & p {
            ${typography.textMd};
            color: ${palette.success['600']};
        }
    `
)
