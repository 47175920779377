import {Outlet} from 'react-router-dom'
import {StyledMain, StyledPrivateLayout} from './styles'

export const PrivateLayout = () => (
    <StyledPrivateLayout>
        <StyledMain>
            <Outlet />
        </StyledMain>
    </StyledPrivateLayout>
)
