import {Dropzone} from '@/components/ui/dropzone/Dropzone'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import styled, {css} from 'styled-components'

export const StyledManualRetouchingDropzone = styled(Dropzone)<{$isDisabled: boolean}>(
    ({theme: {palette}, $isDisabled}) => css`
        width: 100%;
        height: 100%;
        border-radius: 16px;
        border: 2px dashed ${palette.neutral[300]};
        ${$isDisabled &&
        css`
            background-color: ${palette.neutral[100]};
            cursor: not-allowed;
        `}
    `
)

export const StyledManualRetouchingTrigger = styled(Flexbox)(
    ({theme: {palette, typography}}) => css`
        height: 100%;

        & > h3 {
            font-weight: 500;
            color: ${palette.neutral[900]};
        }

        & > p {
            ${typography.textSm};
            text-decoration: underline;
            color: ${palette.neutral[400]};
        }
    `
)
