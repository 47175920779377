import {ModalFooter} from '@/components/ui/modal-atoms/ModalAtoms'
import styled, {css} from 'styled-components'

export const StyledCurationSpaceRulesModalFooter = styled(ModalFooter)`
    justify-content: center;
`

export const StyledCurationSpaceRulesModalTitle = styled.h2(
    ({theme: {typography, palette}}) => css`
        ${typography.textXl};
        color: ${palette.neutral[900]};
        font-weight: 700;
    `
)

export const StyledCurationSpaceRulesModalParagraph = styled.p(
    ({theme: {typography, palette}}) => css`
        ${typography.textMd};
        color: ${palette.neutral[900]};
    `
)

export const StyledCurationSpaceRules = styled.div<{$isSelected: boolean}>(
    ({theme: {palette, typography, spacing}, $isSelected}) => css`
        padding: ${spacing * 3}px ${spacing * 4}px;
        border-radius: 12px;
        cursor: pointer;
        outline: 1px solid;
        width: 100%;

        & > h4 {
            ${typography.textSm};
            color: ${palette.neutral['900']};
            font-weight: 500;
        }

        & > p {
            ${typography.textSm};
            color: ${palette.neutral['700']};
            font-weight: 400;
        }

        ${!$isSelected
            ? css`
                  background-color: ${palette.neutral.white};
                  outline-color: ${palette.neutral['300']};
              `
            : `
                background-color: ${palette.neutral['50']};
                outline-color: ${palette.primary['600']};
            `}
    `
)
