import {ReactNode, forwardRef} from 'react'
import {Image, ImageThumbnails} from '@/features/photoset/types.ts'
import {createPortal} from 'react-dom'
import {
    StyledContent,
    StyledContentMainBody,
    StyledCurrentImage,
    StyledCurrentImagePlaceholder,
    StyledGalleryModal
} from '@/components/commons/gallery-modal/style'
import {Divider} from '@components/ui/divider/Divider.tsx'
import {ImageXIcon} from '@components/ui/icon/Icon.tsx'
import {useTranslation} from 'react-i18next'

interface SpaceDetailModalProps {
    currentImage: Omit<Image, 'space'>
    thumbnails: ImageThumbnails
    onClose: () => void
    className?: string
    headerSlot?: ReactNode
    thumbnailsSlot?: ReactNode
    sidebarSlot?: ReactNode
}

export const GalleryModal = forwardRef<HTMLDivElement, SpaceDetailModalProps>(
    ({currentImage, thumbnails, headerSlot, thumbnailsSlot, sidebarSlot, className}, ref) => {
        const {t} = useTranslation()

        return createPortal(
            <>
                <StyledGalleryModal fullWidth fullHeight className={className} ref={ref}>
                    <StyledContent>
                        <StyledContentMainBody>
                            {headerSlot}

                            {thumbnails?.l ? (
                                <StyledCurrentImage src={thumbnails?.l} alt={currentImage.name} key={currentImage.id} />
                            ) : (
                                <StyledCurrentImagePlaceholder
                                    gap={2}
                                    direction="column"
                                    align="center"
                                    justify="center"
                                >
                                    <ImageXIcon size={32} />
                                    <p>{t('errors:unavailable', {subject: 'image'})}</p>
                                </StyledCurrentImagePlaceholder>
                            )}
                        </StyledContentMainBody>

                        {thumbnailsSlot}
                    </StyledContent>

                    <Divider direction="vertical" />

                    {sidebarSlot}
                </StyledGalleryModal>
            </>,
            document.body
        )
    }
)

GalleryModal.displayName = 'GalleryModal'
