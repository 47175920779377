import styled, {css} from 'styled-components'

export const StyledGalleryModalSidebar = styled.div(
    ({theme: {spacing, palette, typography}}) => css`
        display: grid;
        grid-template-rows: auto auto minmax(0, 1fr);
        grid-template-columns: minmax(0, 1fr);
        width: 336px;

        & > h3 {
            ${typography.textLg};
            font-weight: 500;
            color: ${palette.neutral['900']};
            padding: ${spacing * 17}px ${spacing * 6}px ${spacing * 5}px;
        }
    `
)
