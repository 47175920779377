import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import styled from 'styled-components'

export const StyledEditingQaListHeaderFlags = styled(Flexbox)`
    flex-grow: 1;
`

export const StyledEditingQaFlagsSpaces = styled.div`
    display: grid;
    grid-template-rows: auto minmax(0, 1fr);
    grid-template-columns: minmax(0, 1fr);
`
