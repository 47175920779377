import {useTranslation} from 'react-i18next'
import {PageHero} from '@components/commons/page-hero/PageHero.tsx'
import {PageFooter} from '@components/commons/page-footer/PageFooter.tsx'
import {useOutletContext} from 'react-router-dom'
import {PhotosetURLParams} from '@/features/photoset/types.ts'
import {useEffect, useState} from 'react'
import {usePhotosetStore} from '@/features/photoset/store/store.ts'
import {Button} from '@components/ui/button/Button.tsx'
import {ArrowRightIcon} from '@components/ui/icon/Icon.tsx'
import {errorHandler} from '@utilities/helpers.ts'
import {useGetPhotosetSpaces} from '@/features/photoset/services/useGetPhotosetSpaces.ts'
import {QUERY_KEYS, queryClient} from '@/queryClient.ts'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {ConfirmActionModal} from '@components/commons/confirm-action-modal/ConfirmActionModal.tsx'
import {useUpdateStepToQaReviewFlags} from '@/features/photoset/services/useUpdateStepToQaReviewFlags.ts'
import {EditingQaSelectionSpaceImages} from '@/features/photoset/components/editing-qa-photoset-space-images/EditingQaSelectionSpaceImages'
import {PhotosetSpaceElement} from '@/features/photoset/components/photoset-space-element/PhotosetSpaceElement'
import {
    PhotosetStepBodyContainer,
    PhotosetStepWrapper
} from '@/features/photoset/components/photoset-step-atoms/PhotosetStepAtoms'
import {useCurrentPhotosetSubRoute} from '@/features/photoset/hooks/useCurrentPhotosetSubRoute'
import {StepHeroControls} from '@/features/photoset/components/step-hero-controls/StepHeroControls'

export const PhotosetEditingQaSelectionStep = () => {
    const {t} = useTranslation()
    const {urlParams} = useOutletContext<{urlParams: PhotosetURLParams}>()
    const currentPhotosetSubRoute = useCurrentPhotosetSubRoute()

    const {
        data: spaces,
        isFetching,
        isError
    } = useGetPhotosetSpaces({
        urlParams: {
            photosetId: urlParams.id
        },
        params: {response_type: 'extended'}
    })

    //photoset store
    const photosetSpaces = usePhotosetStore(state => state.photosetSpaces)
    const initPhotosetSpaces = usePhotosetStore(state => state.initPhotosetSpaces)

    //mutations
    const {mutate: onUpdateStepToQaReviewFlags, isPending: isUpdateStepPending} = useUpdateStepToQaReviewFlags({
        onError: errorHandler,
        onSuccess: () => {
            void queryClient.invalidateQueries({queryKey: [QUERY_KEYS.PHOTOSET]})
            void queryClient.invalidateQueries({queryKey: [QUERY_KEYS.IMAGES]})
        }
    })

    //statuses
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)

    useEffect(() => {
        if (spaces) {
            initPhotosetSpaces(spaces)
        }
    }, [spaces])

    return (
        <>
            {showConfirmationModal && (
                <ConfirmActionModal
                    isLoading={isUpdateStepPending}
                    onClose={() => setShowConfirmationModal(!showConfirmationModal)}
                    onConfirm={() => onUpdateStepToQaReviewFlags({photosetId: urlParams.id})}
                    headerTitle={t('photoset:editingQaSelection:modal:header_title')}
                    title={t('photoset:editingQaSelection:modal:title')}
                    paragraph={t('photoset:editingQaSelection:modal:subtitle')}
                />
            )}
            <PageHero
                title={t('photoset:editingQaSelection:title')}
                subtitle={t('photoset:editingQaSelection:subtitle')}
                endSlot={<StepHeroControls hideSortControls />}
            />

            <PhotosetStepWrapper>
                <PhotosetStepBodyContainer fullWidth $disabled={isUpdateStepPending}>
                    {isFetching && [0, 1, 2].map(e => <PhotosetSpaceElement asSkeleton key={`${e}-id`} />)}
                    {!isFetching &&
                        !isError &&
                        photosetSpaces?.map(
                            photosetSpace =>
                                photosetSpace.images.length > 0 && (
                                    <PhotosetSpaceElement
                                        key={photosetSpace.id}
                                        spaceName={photosetSpace.space.key_name}
                                        endSlot={
                                            <EditingQaSelectionSpaceImages
                                                photosetId={urlParams.id}
                                                images={photosetSpace.images}
                                                spaceId={photosetSpace.id}
                                                isQaView={currentPhotosetSubRoute == 'qa'}
                                            />
                                        }
                                    />
                                )
                        )}
                </PhotosetStepBodyContainer>

                {currentPhotosetSubRoute == 'qa' && (
                    <PageFooter
                        centerSlot={<p>{t('photoset:editingQaSelection:footer_step_label')}</p>}
                        endSlot={
                            <Button
                                onClick={() => setShowConfirmationModal(!showConfirmationModal)}
                                disabled={isUpdateStepPending}
                                variant="primary"
                            >
                                {isUpdateStepPending && <Spinner size={20} />}
                                {t('photoset:editingQaSelection:cta')}
                                {!isUpdateStepPending && <ArrowRightIcon />}
                            </Button>
                        }
                    />
                )}
            </PhotosetStepWrapper>
        </>
    )
}

PhotosetEditingQaSelectionStep.displayName = 'PhotosetEditingQaSelectionStep'
