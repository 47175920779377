import styled, {css, CSSProperties} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {palette} from '@/theme/palette'

type StyledSubHeaderBannerProps = {
    type?: 'warning' | 'danger' | 'success' | 'blue'
}

const bannerTypeToColors = {
    danger: {
        background: palette.light.danger[100],
        color: palette.light.danger[700]
    },
    warning: {
        background: palette.light.warning[100],
        color: palette.light.warning[700]
    },
    success: {
        background: palette.light.success[100],
        color: palette.light.success[700]
    },
    blue: {
        background: palette.light.blue[50],
        color: palette.light.neutral[700]
    }
} as const satisfies Record<
    NonNullable<StyledSubHeaderBannerProps['type']>,
    {background: CSSProperties['background']; color: CSSProperties['color']}
>

export const StyledSubHeaderBannerWrapper = styled.div<StyledSubHeaderBannerProps>(
    ({type = 'success'}) => css`
        background: ${bannerTypeToColors[type].background};
        height: auto;
    `
)

export const StyledSubHeaderBannerBody = styled(Flexbox)<StyledSubHeaderBannerProps>(
    ({theme: {spacing, palette}}) => css`
        padding: ${spacing * 3}px 0;
        flex-shrink: inherit;
        min-height: 64px;
        & svg {
            fill: ${palette.neutral.white};
        }
    `
)

export const StyledSubHeaderBannerText = styled(Flexbox)<StyledSubHeaderBannerProps>(
    ({theme: {typography, palette}, type = 'success'}) => css`
        ${typography.textSm};
        & > h3 {
            color: ${palette.neutral[900]};
            font-weight: 500;
        }

        & p {
            color: ${bannerTypeToColors[type].color};
            ${typography.textSm};
            font-weight: 400;
        }
    `
)
