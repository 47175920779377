import {useTranslation} from 'react-i18next'
import {PageHero} from '@components/commons/page-hero/PageHero.tsx'
import {PageFooter} from '@components/commons/page-footer/PageFooter.tsx'
import {useOutletContext} from 'react-router-dom'
import {PhotosetURLParams} from '@/features/photoset/types.ts'
import {useEffect, useState} from 'react'
import {usePhotosetStore} from '@/features/photoset/store/store.ts'
import {Button} from '@components/ui/button/Button.tsx'
import {ArrowLeftIcon, CheckIcon} from '@components/ui/icon/Icon.tsx'
import {errorHandler} from '@utilities/helpers.ts'
import {QUERY_KEYS, queryClient} from '@/queryClient.ts'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {ConfirmActionModal} from '@components/commons/confirm-action-modal/ConfirmActionModal.tsx'
import {useUpdateStepToQaReviewCompleted} from '@/features/photoset/services/useUpdateStepToQaReviewCompleted.ts'
import {useUpdateStepToCompleted} from '@/features/photoset/services/useUpdateStepToCompleted.ts'
import {Divider} from '@/components/ui/divider/Divider'
import {ShootListMetadataSidebar} from '@/features/photoset/components/shoot-list-metadata-sidebar/ShootListMetadataSidebar'
import {EditingQaFlagsSpaces} from '@/features/photoset/components/editing-qa-flags-spaces/EditingQaFlagsSpaces'
import {useGetPhotosetSpacesList} from '@/features/photoset/services/useGetPhotosetSpacesList'
import {
    PhotosetStepGridContainer,
    PhotosetStepWrapper
} from '@/features/photoset/components/photoset-step-atoms/PhotosetStepAtoms'
import {useCurrentPhotosetSubRoute} from '@/features/photoset/hooks/useCurrentPhotosetSubRoute'
import {StepHeroControls} from '@/features/photoset/components/step-hero-controls/StepHeroControls'

export const PhotosetEditingQaFlagsStep = () => {
    const {t} = useTranslation()
    const [hasSidebar, setHasSidebar] = useState(true)
    const {urlParams} = useOutletContext<{urlParams: PhotosetURLParams}>()
    const currentPhotosetSubRoute = useCurrentPhotosetSubRoute()

    const {data: spaces} = useGetPhotosetSpacesList({
        urlParams: {
            photosetId: urlParams.id
        }
    })

    //photoset store
    const initPhotosetSpaces = usePhotosetStore(state => state.initPhotosetSpaces)

    //mutations
    const {mutate: onUpdateStepToEditingQaCompleted, isPending: isUpdateStepToEditingQaCompleted} =
        useUpdateStepToQaReviewCompleted({
            onError: errorHandler,
            onSuccess: () => {
                void queryClient.invalidateQueries({queryKey: [QUERY_KEYS.PHOTOSET]})
                void queryClient.invalidateQueries({queryKey: [QUERY_KEYS.IMAGES]})
            }
        })
    const {mutate: onUpdateStepToEditingQaSelection, isPending: isUpdateStepToEditingQaSelection} =
        useUpdateStepToCompleted({
            onError: errorHandler,
            onSuccess: () => {
                void queryClient.invalidateQueries({queryKey: [QUERY_KEYS.PHOTOSET]})
                void queryClient.invalidateQueries({queryKey: [QUERY_KEYS.IMAGES]})
            }
        })

    //statuses
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)

    useEffect(() => {
        if (spaces) {
            initPhotosetSpaces(spaces)
        }
    }, [spaces])

    return (
        <>
            {showConfirmationModal && (
                <ConfirmActionModal
                    isLoading={isUpdateStepToEditingQaCompleted}
                    onClose={() => setShowConfirmationModal(!showConfirmationModal)}
                    onConfirm={() => onUpdateStepToEditingQaCompleted({photosetId: urlParams.id})}
                    headerTitle={t('photoset:editingQaFlags:modal:header_title')}
                    title={t('photoset:editingQaFlags:modal:title')}
                    paragraph={t('photoset:editingQaFlags:modal:subtitle')}
                />
            )}

            <PageHero
                title={t('photoset:editingQaFlags:title')}
                subtitle={t('photoset:editingQaFlags:subtitle')}
                endSlot={<StepHeroControls hideSortControls />}
            />

            <PhotosetStepWrapper>
                <PhotosetStepGridContainer fullWidth>
                    <EditingQaFlagsSpaces hasSidebar={hasSidebar} showSidebar={() => setHasSidebar(true)} />
                    {hasSidebar && (
                        <>
                            <Divider direction="vertical" />
                            <ShootListMetadataSidebar hideSidebar={() => setHasSidebar(false)} />
                        </>
                    )}
                </PhotosetStepGridContainer>

                {currentPhotosetSubRoute == 'qa' && (
                    <PageFooter
                        startSlot={
                            <Button
                                onClick={() =>
                                    onUpdateStepToEditingQaSelection({
                                        photosetId: urlParams.id,
                                        imagesIds: []
                                    })
                                }
                                disabled={isUpdateStepToEditingQaSelection}
                                variant="tertiary"
                            >
                                {isUpdateStepToEditingQaSelection && <Spinner size={20} />}
                                {!isUpdateStepToEditingQaSelection && <ArrowLeftIcon />}
                                {t('photoset:editingQaFlags:back_cta')}
                            </Button>
                        }
                        centerSlot={<p>{t('photoset:editingQaFlags:footer_step_label')}</p>}
                        endSlot={
                            <Button
                                onClick={() => setShowConfirmationModal(!showConfirmationModal)}
                                variant="primarySuccess"
                            >
                                {isUpdateStepToEditingQaCompleted && <Spinner size={20} />}
                                {t('commons:done')}
                                {!isUpdateStepToEditingQaCompleted && <CheckIcon />}
                            </Button>
                        }
                    />
                )}
            </PhotosetStepWrapper>
        </>
    )
}

PhotosetEditingQaFlagsStep.displayName = 'PhotosetEditingQaFlagsStep'
