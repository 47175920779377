import {Outlet} from 'react-router-dom'
import {useURLParsedParams} from '@/hooks/useURLParsedParams'
import {Photoset, PhotosetURLParams} from '@/features/photoset/types'
import {ErrorBox} from '@/components/commons/error-box/ErrorBox'
import {AlertTriangleIcon} from '@/components/ui/icon/Icon'
import {useTranslation} from 'react-i18next'
import {useGetPhotosetImagesList} from '@/features/photoset/services/useGetPhotosetImagesList'
import {ReactNode, useEffect} from 'react'
import {usePhotosetStore} from '@/features/photoset/store/store'
import {ErrorBoundary} from '@/ErrorBoundary'

export const PhotosetRoute = () => {
    const {t} = useTranslation()
    const urlParams = useURLParsedParams(PhotosetURLParams)

    return urlParams.success ? (
        <PhotosetRouteDataInitializer photosetId={urlParams.data.id}>
            <ErrorBoundary>
                <Outlet context={{urlParams: urlParams.data}} />
            </ErrorBoundary>
        </PhotosetRouteDataInitializer>
    ) : (
        <ErrorBox title={t('errors:invalid_url')} icon={<AlertTriangleIcon />} />
    )
}

const PhotosetRouteDataInitializer = ({photosetId, children}: {photosetId: Photoset['id']; children: ReactNode}) => {
    const imagesQuery = useGetPhotosetImagesList({urlParams: {photosetId}})
    const initImages = usePhotosetStore(state => state.initImages)

    useEffect(() => {
        initImages(imagesQuery.data ?? [])
    }, [imagesQuery.data])

    return children
}
