import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const StyledLoginWrapper = styled(Flexbox)`
    height: 100%;
`

export const StyledLoginBox = styled(Flexbox)`
    ${({theme: {typography, palette, spacing}}) => css`
        width: 360px;
        & > svg {
            fill: ${palette.primary['500']};
            margin: 0 auto ${spacing * 6}px;
        }
        & > h4 {
            ${typography.displaySm};
            font-weight: 700;
            color: ${palette.neutral['900']};
            text-align: center;
            margin-top: ${spacing * 6}px;
        }

        & > p {
            ${typography.textMd};
            margin: ${spacing * 2}px 0 ${spacing * 14}px 0;
            text-align: center;
        }
    `}
`

export const StyledLoginBoxForm = styled.form`
    ${({theme: {spacing}}) => css`
        display: flex;
        flex-direction: column;
        align-items: stretch;
        gap: ${spacing * 4}px;
    `}
`
