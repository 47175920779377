import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import {initReactI18next} from 'react-i18next'
//translations
import ar from './ar/ar.json'
import en from './en/en.json'
import it from './it/it.json'
import de from './de/de.json'
import fr from './fr/fr.json'
import es from './es/es.json'
import ja from './ja/ja.json'
import ko from './ko/ko.json'
import nl from './nl/nl.json'
import pt from './pt/pt.json'
import ru from './ru/ru.json'
import zh from './zh/zh.json'

import 'dayjs/locale/it'
import 'dayjs/locale/en'
import 'dayjs/locale/ar'
import 'dayjs/locale/es'
import 'dayjs/locale/fr'
import 'dayjs/locale/ja'
import 'dayjs/locale/ko'
import 'dayjs/locale/nl'
import 'dayjs/locale/pt'
import 'dayjs/locale/ru'
import 'dayjs/locale/zh'

export const APP_LANGUAGES = [
    {label: 'English (EN)', code: 'en', flagCode: 'gb', json: en},
    {label: 'Español (ES)', code: 'es-419', flagCode: 'es', json: es},
    {label: 'العربية (AR)', code: 'ar', flagCode: 'sa', json: ar},
    {label: 'Deutsch (DE)', code: 'de', flagCode: 'de', json: de},
    {label: 'Français (FR)', code: 'fr', flagCode: 'fr', json: fr},
    {label: 'Italiano (IT)', code: 'it', flagCode: 'it', json: it},
    {label: '日本語 (JP)', code: 'ja', flagCode: 'jp', json: ja},
    {label: '한국어 (KO)', code: 'ko', flagCode: 'kr', json: ko},
    {label: 'Nederlands (NL)', code: 'nl', flagCode: 'nl', json: nl},
    {label: 'Português (PT)', code: 'pt', flagCode: 'pt', json: pt},
    {label: 'Русский (RU)', code: 'ru', flagCode: 'ru', json: ru},
    {label: '中文 (ZH)', code: 'zh', flagCode: 'cn', json: zh}
] as const

i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: Object.fromEntries(APP_LANGUAGES.map(language => [language.code, language.json])),
        ns: [],
        defaultNS: '',
        keySeparator: false,
        fallbackLng: APP_LANGUAGES[0].code,
        debug: false,
        react: {
            useSuspense: false
        },
        supportedLngs: APP_LANGUAGES.map(language => language.code),
        interpolation: {
            escapeValue: false
        },
        detection: {
            order: ['localStorage', 'navigator'],
            lookupLocalStorage: 'i18nextLng'
        }
    })

export const changeAppLanguage = async (nextLanguage: (typeof APP_LANGUAGES)[number]['code']) => {
    await i18n.changeLanguage(nextLanguage)
}

export default i18n
