import React, {forwardRef, HTMLAttributes} from 'react'
import {StyledContainer} from '@components/ui/container/style.ts'

export type ContainerProps = HTMLAttributes<HTMLDivElement> & {
    className?: string
    children: React.ReactNode
    fullWidth?: boolean
    fullHeight?: boolean
}

export const Container = forwardRef<HTMLDivElement, ContainerProps>(
    ({fullWidth = false, fullHeight = true, ...rest}, ref) => (
        <StyledContainer ref={ref} $fullWidth={fullWidth} $fullHeight={fullHeight} {...rest} />
    )
)

Container.displayName = 'Container'
