import {Button} from '@/components/ui/button/Button'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import styled, {css} from 'styled-components'

export const StyledPhotosetSpaceElementDescription = styled(Flexbox)(
    ({theme: {typography, palette}}) => css`
        white-space: nowrap;

        & > p {
            ${typography.textSm};
            max-width: 570px;
            overflow: hidden;
            color: ${palette.neutral[900]};
            text-overflow: ellipsis;
        }

        & > svg {
            fill: ${palette.blue[600]};
        }
    `
)

export const StyledPhotosetSpaceElementDescriptionViewMore = styled(Button)`
    min-height: unset;
    padding: 0;
`
