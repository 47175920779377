import {PageHero} from '@components/commons/page-hero/PageHero.tsx'
import {useTranslation} from 'react-i18next'
import {Button} from '@components/ui/button/Button.tsx'
import {ArrowLeftIcon} from '@components/ui/icon/Icon.tsx'
import {SpacesSidebar} from '@/features/photoset/components/spaces-sidebar/SpacesSidebar.tsx'
import {Divider} from '@components/ui/divider/Divider.tsx'
import {AllocationImagesPool} from '@/features/photoset/components/allocation-images-pool/AllocationImagesPool.tsx'
import {usePhotosetStore} from '@/features/photoset/store/store.ts'
import {PageFooter} from '@components/commons/page-footer/PageFooter.tsx'
import {useEffect, useState} from 'react'
import {useAllocationShortcuts} from '@/features/photoset/hooks/useAllocationShortcuts.tsx'
import {errorHandler} from '@utilities/helpers.ts'
import {useOutletContext} from 'react-router-dom'
import {PhotosetURLParams} from '@/features/photoset/types.ts'
import {useUpdateStepToInitialQA} from '@/features/photoset/services/useUpdateStepToInitialQA.ts'
import {StepHeroControls} from '@/features/photoset/components/step-hero-controls/StepHeroControls'
import {useGetPhotosetImagesList} from '@/features/photoset/services/useGetPhotosetImagesList'
import {useGetPhotosetSpaces} from '@/features/photoset/services/useGetPhotosetSpaces'
import {DndContext, DragEndEvent} from '@dnd-kit/core'
import {useAllocateImages} from '@/features/photoset/services/useAllocateImages.tsx'
import {generateToastAllocationId} from '@/features/photoset/utils.ts'
import toast from 'react-hot-toast'
import {useDeallocateImages} from '@/features/photoset/services/useDeallocateImages.ts'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {useGetReasonsDecline} from '@/features/photoset/services/useGetReasonsDecline'
import {RejectPhotosetModal} from '@/features/photoset/components/reject-photoset-modal/RejectPhotosetModal'
import {useMutationState} from '@tanstack/react-query'
import {MUTATION_KEYS, QUERY_KEYS, queryClient} from '@/queryClient'
import {
    PhotosetStepGridContainer,
    PhotosetStepWrapper
} from '@/features/photoset/components/photoset-step-atoms/PhotosetStepAtoms'
import {useExpandPhotosetImage} from '@/features/photoset/hooks/useExpandPhotosetImage'

export const PhotosetAllocationStep = () => {
    const {t} = useTranslation()
    const {urlParams} = useOutletContext<{urlParams: PhotosetURLParams}>()
    const [rejectModal, showRejectModal] = useState(false)
    const initSpaces = usePhotosetStore(state => state.initSpaces)
    const selectedImages = usePhotosetStore(state => state.selectedImages)
    const deallocateImages = usePhotosetStore(state => state.deallocateImages)
    const unselectAll = usePhotosetStore(state => state.unselectAll)
    const [currentSpaceIndex, setCurrentSpaceIndex] = useState<number | null>(null)

    //queries
    const imagesQuery = useGetPhotosetImagesList({urlParams: {photosetId: urlParams.id}}, {enabled: false})
    const reasonsDeclineQuery = useGetReasonsDecline()

    //mutations
    const deallocateImagesMutation = useDeallocateImages()
    const allocateImagesMutation = useAllocateImages({
        options: {onError: errorHandler},
        onUndo: async (imagesIds, photosetSpaceId) => {
            try {
                await deallocateImagesMutation.mutateAsync({
                    urlParams: {photosetId: urlParams.id, photosetSpaceId},
                    payload: imagesIds
                })

                const toastId = generateToastAllocationId('allocation', photosetSpaceId, imagesIds)
                deallocateImages({imagesIds, photosetSpaceId, keepSelected: false, trackInHistory: false})
                toast.dismiss(toastId)
            } catch (error) {
                errorHandler(error)
            }
        }
    })

    const photosetSpacesQuery = useGetPhotosetSpaces({
        urlParams: {photosetId: urlParams.id},
        params: {response_type: 'extended'}
    })

    const updateStepToInitialQAMutation = useUpdateStepToInitialQA({
        onError: errorHandler,
        onSuccess: () => {
            unselectAll()
            void queryClient.invalidateQueries({queryKey: [QUERY_KEYS.PHOTOSET]})
        }
    })

    // Get the update step to curation status even if it is invoked outside
    const updateStepToCurationStatus = useMutationState({
        filters: {mutationKey: [MUTATION_KEYS.UPDATE_STEP_CURATION]},
        select: mutation => mutation.state.status
    })

    useEffect(() => {
        initSpaces(photosetSpacesQuery.data ? photosetSpacesQuery.data : [])
    }, [photosetSpacesQuery.data])

    const {currentDetailImageIndex, setCurrentDetailImageIndex} = useExpandPhotosetImage()

    useAllocationShortcuts({disabled: currentSpaceIndex != null || currentDetailImageIndex != null})

    const onDragEnd = (event: DragEndEvent) => {
        if (event.over?.id) {
            allocateImagesMutation.mutate({
                urlParams: {photosetId: urlParams.id, photosetSpaceId: Number(event.over.id)},
                payload: selectedImages
            })
        }
    }

    return (
        <>
            <PageHero
                title={t('photoset:allocation:title')}
                subtitle={t('photoset:allocation:subtitle')}
                endSlot={<StepHeroControls />}
            />
            <PhotosetStepWrapper>
                <PhotosetStepGridContainer fullWidth>
                    <DndContext onDragEnd={onDragEnd}>
                        <AllocationImagesPool
                            currentDetailImageIndex={currentDetailImageIndex}
                            setCurrentDetailImageIndex={setCurrentDetailImageIndex}
                        />
                        <Divider direction="vertical" />
                        <SpacesSidebar
                            currentSpaceIndex={currentSpaceIndex}
                            setCurrentSpaceIndex={setCurrentSpaceIndex}
                            queryStatuses={[imagesQuery.status, photosetSpacesQuery.status]}
                        />
                    </DndContext>
                </PhotosetStepGridContainer>
                <PageFooter
                    startSlot={
                        <Flexbox gap={4}>
                            <Button
                                onClick={() => updateStepToInitialQAMutation.mutate(urlParams.id)}
                                variant="tertiary"
                                disabled={updateStepToInitialQAMutation.isPending}
                            >
                                <ArrowLeftIcon />
                                {t('commons:back')}
                            </Button>
                            <Button
                                onClick={() => showRejectModal(true)}
                                variant="tertiary"
                                disabled={
                                    reasonsDeclineQuery.isFetching ||
                                    updateStepToInitialQAMutation.isPending ||
                                    updateStepToCurationStatus.includes('pending')
                                }
                            >
                                {t('commons:needs_reupload')}
                            </Button>
                        </Flexbox>
                    }
                    centerSlot={<p>{t('photoset:allocation:footer_step_label')}</p>}
                />

                {rejectModal && reasonsDeclineQuery.data && (
                    <RejectPhotosetModal reasons={reasonsDeclineQuery.data} close={() => showRejectModal(false)} />
                )}
            </PhotosetStepWrapper>
        </>
    )
}

PhotosetAllocationStep.displayName = 'PhotosetAllocationStep'
