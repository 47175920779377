import {ModalBody} from '@/components/ui/modal-atoms/ModalAtoms'
import styled, {css} from 'styled-components'

export const StyledManualRetouchingErrorModalBody = styled(ModalBody)(
    ({theme: {palette}}) => css`
        justify-content: center;
        align-items: center;
        color: ${palette.neutral[900]};
        text-align: center;

        & svg {
            fill: ${palette.danger[500]};
        }
    `
)

export const StyledManualRetouchingErrorModalFooter = styled(ModalBody)(
    ({theme: {spacing}}) => css`
        padding-top: ${spacing * 4.5}px;
        padding-bottom: ${spacing * 4.5}px;
    `
)
