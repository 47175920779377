import {UseQueryOptions} from '@tanstack/react-query'
import {
    HttpGetPhotosetSpacesOptions,
    HttpGetPhotosetSpacesResponse
} from '@/features/photoset/services/photoset.http.ts'
import {useParsedSearchParams} from '@/hooks/useParsedSearchParams'
import {PhotosetImagesSearchParams} from '@/features/photoset/types'
import {useGetPhotosetSpaces} from '@/features/photoset/services/useGetPhotosetSpaces'
import {GET_PHOTOSET_IMAGES_LIST_RESPONSE_TYPE} from '@/features/photoset/services/useGetPhotosetImagesList'

export const useGetPhotosetSpacesList = (
    promiseArgs: Omit<HttpGetPhotosetSpacesOptions, 'params'>,
    options?: Omit<UseQueryOptions<HttpGetPhotosetSpacesResponse>, 'queryFn' | 'queryKey'>
) => {
    const {searchParams} = useParsedSearchParams([], PhotosetImagesSearchParams)
    return useGetPhotosetSpaces(
        {...promiseArgs, params: {...searchParams, response_type: GET_PHOTOSET_IMAGES_LIST_RESPONSE_TYPE}},
        options
    )
}
